import React from 'react';
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Ages from './data/ages.js';
import Words from './data/words.js'
import Button from '@material-ui/core/Button';
import {Helmet} from "react-helmet";

function App() {

  const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center'
    },
    button: {
      margin: theme.spacing(1),
    },
    ul: {
      listStyle: 'none',
      marginLeft: '-40px'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      paddingTop: '8px'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  about: {
    fontFamily: 'Muli',
    marginTop: theme.spacing(8),
    margin: theme.spacing(8)
  }
  }));
  
  const classes = useStyles();
  const [values, setValues] = React.useState({
    letter: '',
    age: 0,
    position: 'start',
    words: [],
    submitted: false
  });

  const ages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]
  const positions = ['start', 'inter-medial', 'end']
  
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  function handleSelect(event) {
    setValues(oldvalues => ({
      ...oldvalues,
      [event.target.name] : event.target.value,
    }))
  }

  function handleSearch(event) {
    var foundWords = [];
    for (var i = 0; i < Ages.ages.length; i++) {
      var age = Math.floor(Ages.ages[i]);
      var word = Words.words[i];
      if (age == values.age) {
        switch (values.position) {
          case 'start':
            if (word.substring(0, values.letter.length) == values.letter.toLowerCase()) foundWords.push(word);
            break;
          case 'inter-medial':
            if (word.length != 1 && word.substring(1, word.length - 1).includes(values.letter.toLowerCase())) foundWords.push(word);
            break;
          case 'end':
            if (word.substring(word.length - values.letter.length, word.length) == values.letter.toLowerCase()) foundWords.push(word);
            break;
        }
      } 
    }
    event.preventDefault();
    if (foundWords.length > 0) {
      setValues(oldValues => ({...oldValues, words : foundWords}))
      setValues(oldvalues => ({...oldvalues, submitted: false}))
    } else {
      setValues(oldValues => ({ ...oldValues, words: ['Ohh shucks, no words found with that criteria'] }))
      setValues(oldvalues => ({...oldvalues, submitted: true}))
    }
  }

  return (
    <div className="App">
      <Helmet>
        <title>Age of Acquisition Finder</title>
        <meta name="description" content="Tool to help speech-language pathologists (SLPs) find late aquired words within articulation therapy."/>
      </Helmet>
      <div className={classes.about}>
        <h2>Age of Acquisition Finder</h2>
        <p>This tool was created to help speech-language pathologists target late aquired words within articulation therapy.</p>
        <p>Select a sound (k, t, g), age range, and a position to generate a list of words for the specified criteria.</p>
      </div>
        <form onSubmit={handleSearch} className={classes.container} noValidate autoComplete="off">
            <TextField
              id="standard-name"
              label="Sound"
              className={classes.textField}
              value={values.letter}
              onChange={handleChange('letter')}
              margin="normal"
              required
            />
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="age-simple">Age</InputLabel>
              <Select
                required
                value={values.age}
                onChange={handleSelect}
                inputProps={{
                  name: 'age',
                  id: 'age-simple',
                }}
              >
                {ages.map((value, index) => {
                  return <MenuItem value={value}>{value}</MenuItem>
                })}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="position">Position</InputLabel>
              <Select
                value={values.position}
                onChange={handleSelect}
                inputProps={{
                  name: 'position',
                  id: 'position',
                }}
              >
                {positions.map((value, index) => {
                  return <MenuItem value={value}>{value}</MenuItem>
                })}
              </Select>
              </FormControl>
              <Button type="submit" variant="contained" color="secondary" className={classes.button}>
                Give em to me
              </Button>
        </form>
        <ul className={classes.ul}>
          {values.words.map((value, index) => {
            return <li> {value} </li>
          })}
          {values.submitted &&
            <img src="http://i.imgur.com/qhMbkGi.jpg" height="400" width="300"></img>
          }
        </ul>

    </div>
  );
}

export default App;
