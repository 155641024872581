export default {
    ages: [
        2.89,
9.89,
8.69,
12.23,
8.32,
11.89,
10.27,
14.57,
15.13,
14.44,
15.12,
15.17,
14.50,
15.43,
13.06,
12.10,
9.95,
10.50,
9.11,
12.60,
14.94,
8.61,
10.24,
11.26,
11.94,
11.11,
13.40,
13.31,
12.69,
12.15,
15.55,
15.00,
13.76,
12.14,
9.50,
10.30,
8.84,
14.00,
17.12,
14.60,
13.29,
10.83,
7.79,
13.64,
10.05,
11.58,
8.66,
12.06,
10.42,
13.28,
11.78,
12.60,
8.94,
15.00,
11.17,
14.75,
12.56,
13.25,
12.21,
13.21,
15.00,
13.93,
11.65,
5.07,
4.55,
13.00,
8.05,
14.24,
6.89,
14.00,
11.95,
12.22,
12.06,
15.80,
13.06,
14.35,
12.56,
12.32,
10.15,
15.22,
10.95,
11.82,
12.39,
13.11,
13.36,
16.62,
7.70,
6.50,
11.00,
13.43,
10.84,
9.61,
16.29,
8.53,
7.08,
12.68,
11.71,
12.90,
13.60,
8.83,
10.32,
10.33,
10.94,
12.95,
15.19,
12.74,
11.47,
13.65,
14.72,
14.60,
10.50,
13.00,
13.21,
10.45,
12.84,
8.61,
9.94,
9.58,
10.47,
13.11,
15.33,
13.00,
15.25,
13.78,
11.00,
17.27,
10.75,
14.25,
13.27,
13.74,
9.74,
15.21,
15.00,
9.05,
15.17,
14.31,
14.50,
12.84,
10.74,
10.11,
9.84,
10.27,
8.60,
13.24,
6.74,
9.84,
8.56,
11.89,
9.10,
11.05,
9.94,
12.25,
11.39,
11.15,
5.30,
6.75,
12.11,
14.00,
14.24,
16.12,
14.00,
12.43,
9.90,
9.58,
15.18,
11.18,
12.78,
10.75,
9.78,
7.84,
9.00,
9.65,
11.75,
8.45,
8.61,
13.37,
8.26,
12.16,
11.70,
14.80,
11.67,
12.05,
13.87,
15.53,
13.89,
14.00,
15.00,
14.11,
12.30,
10.50,
12.50,
13.94,
9.71,
9.44,
12.71,
10.17,
13.33,
11.18,
9.10,
11.72,
12.00,
8.83,
15.67,
16.62,
15.75,
13.47,
12.85,
13.75,
14.05,
16.31,
16.30,
16.88,
17.71,
5.79,
9.89,
8.53,
8.80,
9.11,
10.11,
8.06,
9.60,
11.21,
12.78,
17.40,
16.85,
15.43,
9.11,
9.95,
9.54,
11.40,
13.77,
11.00,
5.95,
12.39,
12.90,
11.32,
10.48,
14.18,
14.25,
13.61,
14.18,
9.94,
11.79,
13.63,
14.19,
14.38,
13.22,
12.39,
9.80,
13.13,
14.07,
16.30,
14.75,
8.05,
9.38,
9.00,
11.56,
14.38,
15.40,
6.64,
14.82,
11.56,
6.42,
15.62,
0.00,
6.67,
12.50,
11.11,
9.78,
11.50,
6.53,
11.89,
11.80,
6.47,
7.17,
6.17,
6.94,
12.38,
13.84,
13.61,
7.33,
15.85,
17.27,
14.69,
13.71,
13.88,
14.44,
14.44,
11.79,
14.61,
11.60,
12.95,
12.57,
8.22,
16.21,
12.62,
14.29,
15.91,
9.00,
11.65,
9.37,
11.39,
11.79,
12.40,
11.33,
10.21,
5.10,
15.65,
12.13,
11.17,
12.11,
10.33,
11.61,
5.76,
9.00,
12.28,
13.08,
12.00,
5.15,
9.95,
13.00,
14.18,
15.33,
13.78,
16.29,
12.50,
14.37,
12.50,
10.35,
10.78,
12.22,
14.17,
13.50,
9.17,
18.50,
15.70,
15.86,
11.05,
8.60,
11.85,
12.59,
14.26,
15.20,
15.25,
16.33,
16.47,
13.06,
16.00,
13.10,
8.43,
12.05,
8.95,
11.28,
9.74,
13.29,
13.91,
14.25,
13.89,
9.42,
12.50,
11.11,
11.50,
12.22,
11.42,
8.72,
11.41,
8.95,
7.42,
8.78,
13.79,
13.10,
8.84,
7.56,
10.39,
14.10,
13.40,
13.86,
11.88,
12.22,
10.42,
11.05,
7.83,
9.22,
10.16,
7.79,
9.16,
11.76,
6.94,
8.32,
11.80,
7.95,
8.53,
11.65,
12.32,
14.24,
11.65,
11.63,
11.21,
12.75,
15.21,
12.72,
4.68,
12.86,
13.76,
11.63,
12.22,
11.65,
11.84,
9.95,
16.50,
8.35,
9.05,
10.89,
9.32,
12.62,
10.38,
5.67,
7.79,
10.38,
10.44,
8.68,
10.00,
11.81,
8.14,
13.14,
14.00,
12.67,
10.72,
11.79,
14.22,
9.63,
7.67,
11.00,
9.95,
8.61,
10.75,
8.89,
12.89,
12.50,
10.78,
11.24,
11.55,
14.06,
11.11,
17.00,
13.44,
12.35,
11.50,
14.08,
13.00,
11.40,
9.53,
10.24,
11.00,
13.40,
11.50,
12.92,
14.35,
12.35,
9.47,
12.06,
14.90,
15.74,
15.06,
8.21,
10.86,
14.07,
13.40,
10.94,
11.45,
9.60,
13.82,
8.53,
8.50,
11.74,
13.60,
14.00,
15.69,
12.00,
12.28,
12.22,
12.24,
12.56,
10.67,
11.94,
11.50,
11.32,
14.40,
13.88,
13.16,
7.47,
10.89,
8.15,
13.00,
12.77,
12.44,
9.11,
13.86,
11.87,
11.22,
9.33,
11.48,
14.58,
14.44,
13.72,
11.81,
4.42,
9.58,
13.88,
6.00,
14.06,
10.69,
11.62,
15.86,
12.06,
12.53,
13.67,
8.11,
14.20,
12.05,
4.65,
6.89,
10.42,
10.33,
10.00,
12.78,
7.71,
10.41,
5.78,
7.97,
13.00,
14.57,
4.38,
17.07,
15.79,
10.30,
10.58,
9.95,
9.55,
13.29,
15.67,
14.89,
14.83,
14.75,
8.32,
8.61,
9.11,
13.67,
14.56,
12.13,
9.30,
8.32,
11.06,
11.47,
12.38,
12.00,
11.94,
10.06,
7.35,
10.17,
10.12,
11.50,
11.84,
16.60,
14.71,
13.67,
10.06,
16.50,
13.80,
7.12,
14.73,
15.06,
11.58,
10.89,
9.22,
13.73,
14.44,
14.64,
14.83,
7.68,
8.41,
8.16,
18.07,
10.11,
12.78,
10.32,
15.20,
5.45,
7.61,
7.33,
7.83,
12.92,
8.89,
15.00,
14.08,
9.71,
14.71,
10.63,
10.37,
6.72,
9.85,
10.32,
3.94,
12.26,
10.35,
10.75,
9.85,
12.06,
7.61,
11.67,
10.15,
15.11,
9.18,
10.26,
8.60,
12.42,
14.64,
6.83,
10.17,
11.35,
10.00,
12.06,
8.74,
9.89,
11.61,
12.00,
10.58,
9.37,
12.69,
3.94,
12.93,
6.84,
10.80,
11.47,
10.17,
11.50,
10.90,
12.22,
12.78,
8.16,
9.84,
11.78,
11.35,
11.16,
13.31,
10.28,
5.95,
9.67,
10.94,
14.09,
12.67,
13.67,
15.25,
6.39,
5.80,
9.78,
13.12,
12.38,
9.78,
14.47,
11.64,
14.17,
9.79,
6.72,
15.45,
14.20,
11.00,
15.64,
12.00,
13.35,
9.00,
9.95,
11.60,
12.11,
14.08,
14.19,
10.16,
13.76,
13.70,
8.90,
9.44,
14.17,
8.83,
15.29,
13.33,
11.47,
11.11,
14.86,
14.56,
15.31,
9.47,
12.50,
7.50,
13.05,
13.47,
14.07,
8.58,
11.41,
9.85,
11.76,
6.12,
13.21,
14.47,
12.69,
5.11,
14.35,
13.68,
14.06,
14.18,
16.62,
4.24,
15.77,
11.84,
12.81,
12.05,
8.42,
15.18,
12.41,
14.16,
13.07,
14.50,
11.58,
7.72,
12.21,
8.11,
11.39,
7.26,
8.11,
11.28,
10.85,
4.78,
13.86,
15.25,
13.78,
12.42,
15.23,
15.20,
13.67,
13.28,
5.32,
9.56,
6.95,
14.53,
13.11,
11.83,
12.89,
13.05,
16.78,
9.61,
10.58,
17.22,
8.89,
8.47,
7.67,
13.00,
5.47,
9.91,
17.80,
8.95,
9.82,
10.15,
4.94,
9.06,
5.41,
11.60,
13.41,
17.92,
6.22,
11.77,
10.81,
12.22,
4.22,
6.26,
7.11,
13.00,
15.00,
11.28,
6.28,
6.64,
7.16,
11.21,
9.47,
10.17,
11.67,
8.83,
12.00,
12.32,
10.11,
11.32,
8.08,
14.13,
9.58,
10.19,
8.35,
15.79,
15.50,
13.80,
13.06,
11.78,
7.26,
14.00,
14.00,
15.00,
14.50,
6.26,
14.00,
15.25,
15.07,
16.92,
15.29,
15.50,
12.00,
15.73,
14.63,
15.36,
12.00,
14.75,
10.53,
12.57,
12.88,
7.50,
7.17,
9.22,
5.22,
11.00,
12.69,
13.45,
9.28,
17.50,
14.78,
11.84,
13.28,
12.80,
14.06,
13.67,
11.29,
11.05,
13.37,
12.12,
11.87,
13.82,
17.12,
6.16,
15.36,
12.59,
0.00,
9.30,
13.67,
16.25,
5.80,
14.14,
11.39,
10.06,
10.16,
12.44,
12.22,
13.25,
13.11,
12.95,
12.14,
15.40,
11.45,
10.44,
13.78,
11.34,
13.14,
16.83,
8.11,
11.00,
12.35,
9.67,
10.61,
12.64,
13.67,
12.95,
14.38,
13.86,
12.65,
13.68,
7.75,
9.01,
14.43,
11.70,
15.00,
13.71,
14.75,
17.33,
15.88,
6.63,
12.67,
12.11,
12.07,
13.20,
15.53,
8.33,
10.88,
11.84,
15.44,
11.63,
11.40,
11.35,
12.06,
12.44,
9.60,
11.89,
11.00,
11.18,
11.53,
9.00,
7.83,
8.79,
8.44,
15.67,
18.20,
15.53,
14.30,
15.79,
10.06,
13.12,
14.18,
15.33,
11.38,
13.17,
15.60,
15.38,
14.87,
13.62,
14.40,
12.16,
11.33,
11.32,
11.75,
12.61,
12.44,
11.27,
11.39,
11.37,
15.67,
16.27,
17.00,
14.15,
12.46,
12.63,
15.95,
13.94,
13.00,
12.53,
12.05,
14.22,
14.19,
11.94,
8.61,
9.01,
11.21,
10.11,
5.72,
12.00,
15.00,
8.06,
11.16,
9.32,
8.26,
13.78,
4.57,
12.50,
15.80,
16.50,
13.89,
12.47,
14.33,
12.00,
13.42,
13.07,
17.00,
11.80,
12.53,
13.00,
13.05,
13.28,
12.78,
15.22,
15.05,
10.58,
4.00,
7.42,
8.33,
11.37,
6.00,
15.86,
15.00,
15.67,
17.27,
8.05,
11.53,
10.23,
17.11,
15.12,
4.45,
13.12,
11.54,
12.06,
11.32,
16.78,
16.80,
2.89,
12.58,
8.53,
7.00,
8.53,
10.16,
13.94,
12.58,
15.08,
13.44,
14.08,
16.60,
14.80,
4.89,
7.35,
9.06,
14.27,
14.71,
12.53,
13.85,
11.32,
12.06,
7.33,
15.12,
15.44,
9.48,
7.75,
6.85,
7.22,
9.47,
7.72,
9.47,
6.52,
9.26,
15.88,
12.65,
14.15,
12.37,
12.00,
15.00,
14.00,
14.44,
15.10,
9.25,
12.56,
15.10,
12.44,
13.44,
10.26,
14.80,
13.11,
12.06,
14.67,
5.05,
14.83,
5.43,
9.35,
4.32,
12.89,
11.81,
11.17,
13.94,
12.28,
11.70,
8.50,
17.50,
15.29,
15.77,
14.89,
8.39,
17.50,
7.05,
14.12,
15.58,
9.44,
11.67,
7.00,
14.47,
14.10,
14.26,
14.33,
14.88,
12.56,
14.61,
9.37,
12.06,
11.65,
9.53,
9.55,
12.47,
11.65,
12.79,
10.60,
10.00,
13.94,
14.17,
14.88,
10.67,
14.53,
16.36,
13.53,
12.89,
13.61,
9.56,
13.69,
14.05,
14.69,
10.11,
14.85,
15.77,
12.62,
10.05,
12.00,
13.64,
12.35,
11.37,
13.57,
12.93,
11.08,
14.84,
12.75,
14.71,
12.11,
13.19,
14.52,
16.30,
13.25,
12.95,
11.26,
16.00,
13.71,
13.82,
13.57,
8.05,
11.05,
13.18,
9.95,
10.78,
13.80,
11.06,
11.47,
16.00,
13.80,
13.38,
14.90,
13.57,
14.88,
13.79,
15.11,
13.24,
16.11,
15.00,
12.42,
6.39,
9.06,
11.17,
10.79,
8.17,
10.21,
6.00,
5.11,
7.75,
5.36,
6.12,
5.89,
6.31,
13.06,
13.85,
13.12,
10.50,
6.64,
15.44,
7.80,
6.56,
9.83,
14.60,
14.21,
12.38,
5.89,
9.53,
15.22,
14.92,
12.58,
16.62,
14.71,
10.70,
16.60,
16.20,
13.32,
15.31,
12.56,
13.25,
14.73,
15.62,
12.78,
17.00,
17.19,
15.36,
9.80,
14.00,
12.36,
5.67,
5.56,
14.88,
15.50,
12.58,
9.50,
16.80,
8.39,
14.47,
15.50,
15.64,
18.33,
11.53,
12.21,
11.71,
15.83,
10.90,
10.80,
10.25,
11.94,
10.21,
6.67,
8.83,
11.78,
13.11,
13.07,
14.20,
13.50,
16.20,
13.29,
11.58,
11.93,
12.00,
10.56,
13.80,
8.83,
9.06,
10.74,
7.10,
6.20,
4.15,
8.75,
7.41,
4.32,
8.78,
14.63,
13.37,
11.78,
9.39,
10.74,
9.21,
10.29,
13.53,
7.53,
12.94,
14.52,
13.05,
10.17,
8.80,
8.50,
9.94,
11.78,
11.47,
14.16,
11.25,
11.45,
10.58,
14.40,
14.31,
8.50,
10.00,
15.23,
9.16,
10.44,
12.94,
7.95,
7.89,
10.70,
12.56,
6.15,
14.33,
6.75,
12.74,
12.50,
12.39,
14.79,
9.94,
13.54,
6.84,
9.44,
9.39,
14.80,
11.61,
11.75,
13.33,
13.00,
13.00,
11.88,
12.35,
14.71,
16.42,
17.10,
14.06,
14.74,
14.25,
14.37,
11.03,
14.42,
13.56,
13.50,
9.42,
7.79,
14.93,
8.26,
9.44,
9.68,
10.00,
14.00,
14.50,
15.42,
11.53,
10.45,
15.23,
11.94,
13.09,
11.59,
9.89,
11.28,
9.23,
9.06,
16.29,
13.87,
16.00,
18.33,
13.18,
10.12,
14.14,
12.25,
10.06,
21.00,
11.20,
12.95,
10.88,
13.79,
18.17,
10.06,
13.90,
7.79,
16.60,
13.27,
13.82,
13.92,
6.58,
14.20,
9.57,
13.31,
13.70,
13.31,
15.00,
15.19,
13.33,
15.80,
4.61,
7.55,
11.50,
13.75,
11.38,
12.87,
15.14,
6.89,
11.00,
11.88,
12.06,
7.16,
11.00,
7.17,
3.26,
13.53,
8.61,
11.83,
13.73,
14.30,
10.00,
6.56,
8.72,
9.61,
14.00,
8.68,
9.00,
12.37,
7.17,
9.50,
15.25,
11.00,
6.00,
6.56,
3.74,
7.15,
9.47,
15.09,
11.05,
5.94,
12.94,
12.63,
13.85,
12.38,
16.44,
8.28,
9.89,
12.44,
11.50,
10.79,
16.62,
7.47,
12.06,
10.94,
16.27,
13.81,
8.06,
6.26,
11.72,
9.95,
6.07,
12.00,
11.79,
11.83,
14.64,
14.17,
11.20,
11.45,
6.21,
14.50,
17.12,
16.60,
13.06,
9.56,
12.30,
11.67,
12.93,
15.14,
10.00,
8.61,
13.44,
13.00,
12.63,
10.33,
12.75,
10.16,
11.84,
10.68,
13.00,
12.95,
6.78,
12.29,
9.17,
10.58,
13.82,
5.97,
10.76,
7.32,
9.57,
16.75,
6.10,
13.95,
10.67,
16.00,
13.69,
10.84,
12.71,
10.74,
12.68,
15.60,
14.43,
12.57,
15.28,
14.07,
16.00,
6.63,
7.11,
10.67,
12.50,
7.06,
0.00,
9.07,
16.64,
5.95,
8.61,
7.89,
12.71,
16.43,
2.89,
7.94,
11.00,
4.47,
10.69,
6.79,
10.35,
10.04,
14.22,
14.40,
8.89,
14.07,
13.06,
15.12,
16.33,
18.00,
12.81,
14.41,
11.47,
11.83,
7.95,
11.00,
7.84,
12.24,
13.21,
12.44,
11.28,
10.11,
9.05,
11.05,
10.21,
14.94,
15.00,
18.25,
14.29,
8.72,
6.94,
12.89,
11.29,
10.83,
12.24,
10.44,
11.35,
11.37,
11.95,
14.79,
11.79,
9.52,
16.50,
13.21,
8.56,
12.93,
7.00,
12.95,
15.07,
8.75,
8.84,
8.61,
10.83,
10.76,
13.95,
9.32,
8.72,
15.18,
8.21,
10.20,
12.33,
12.06,
12.94,
13.00,
11.94,
8.60,
10.39,
8.72,
13.53,
12.21,
10.58,
9.11,
10.11,
12.41,
11.40,
12.62,
13.20,
10.30,
13.89,
16.38,
12.69,
13.51,
11.06,
10.00,
11.41,
9.53,
6.28,
12.07,
9.85,
12.47,
12.50,
9.00,
14.16,
13.06,
13.30,
14.69,
13.00,
13.10,
12.28,
12.11,
11.68,
13.73,
4.04,
16.15,
17.40,
15.00,
12.69,
12.18,
15.40,
14.33,
7.11,
8.55,
10.05,
8.35,
12.25,
7.90,
9.79,
10.11,
14.65,
9.68,
10.16,
13.24,
12.81,
12.82,
16.50,
12.12,
11.79,
17.50,
11.00,
12.00,
11.95,
13.39,
15.50,
15.78,
6.39,
14.31,
10.11,
6.58,
6.50,
10.84,
13.70,
9.45,
13.80,
17.00,
7.70,
8.70,
7.72,
7.11,
9.79,
11.42,
6.78,
9.37,
12.10,
16.12,
14.50,
14.00,
5.15,
11.22,
6.78,
14.75,
10.95,
8.78,
9.61,
8.58,
10.05,
10.87,
14.69,
12.67,
11.14,
13.94,
14.07,
11.44,
15.00,
12.94,
10.68,
10.67,
9.16,
14.33,
13.00,
11.05,
7.00,
9.32,
13.74,
16.00,
15.06,
9.94,
11.44,
13.50,
8.36,
14.74,
6.94,
12.79,
13.64,
14.00,
14.17,
15.27,
16.22,
5.65,
3.80,
4.74,
11.67,
15.41,
14.31,
15.64,
13.67,
17.00,
12.70,
16.22,
14.89,
13.93,
13.77,
16.79,
14.09,
12.83,
15.00,
8.42,
12.42,
12.56,
13.72,
12.11,
7.10,
13.19,
15.50,
12.95,
13.44,
11.72,
7.50,
11.11,
9.84,
10.59,
14.21,
14.35,
7.22,
9.95,
9.33,
9.83,
11.29,
15.64,
13.29,
13.67,
14.35,
15.69,
15.36,
14.29,
15.86,
6.89,
6.94,
16.70,
13.05,
14.70,
11.41,
10.75,
11.76,
11.11,
9.00,
12.06,
14.00,
14.40,
7.11,
7.89,
13.47,
12.59,
13.82,
10.39,
11.71,
16.73,
6.00,
13.06,
14.00,
12.11,
9.00,
8.50,
9.60,
13.55,
16.00,
15.42,
13.50,
13.46,
10.27,
10.68,
10.62,
11.32,
7.00,
12.00,
8.90,
13.74,
11.94,
10.71,
12.36,
12.00,
10.32,
8.95,
12.67,
11.11,
14.31,
9.29,
15.88,
8.50,
9.22,
11.68,
12.43,
10.33,
16.12,
13.67,
13.17,
15.88,
15.00,
9.00,
4.67,
7.17,
11.76,
10.89,
7.50,
6.75,
9.30,
11.40,
5.07,
9.06,
10.41,
13.36,
10.38,
7.33,
10.95,
10.28,
6.26,
9.67,
7.12,
8.58,
9.89,
10.75,
10.86,
11.06,
11.60,
6.32,
6.11,
11.62,
16.25,
12.50,
12.10,
10.85,
14.07,
13.83,
15.47,
9.69,
13.00,
15.67,
12.54,
7.17,
6.61,
9.11,
13.09,
3.84,
5.52,
7.85,
6.84,
5.89,
5.86,
15.15,
13.75,
17.18,
15.40,
12.00,
13.26,
13.37,
18.00,
13.33,
5.31,
7.89,
12.58,
15.00,
9.44,
11.50,
13.28,
8.63,
6.95,
11.55,
10.38,
9.29,
11.50,
6.11,
9.78,
13.10,
11.47,
10.47,
14.00,
10.00,
8.67,
11.07,
8.67,
10.00,
8.68,
10.44,
11.78,
13.17,
12.43,
5.38,
10.37,
11.00,
12.00,
8.35,
9.95,
15.58,
6.63,
5.11,
5.61,
10.90,
12.59,
11.26,
13.05,
11.87,
10.47,
10.44,
9.24,
9.17,
9.57,
11.11,
11.28,
8.65,
11.67,
7.89,
11.75,
11.11,
5.76,
7.47,
5.98,
9.26,
12.78,
10.26,
3.38,
5.00,
9.28,
10.55,
13.94,
12.62,
2.79,
12.14,
8.62,
9.00,
6.11,
8.74,
14.50,
6.58,
10.94,
8.25,
5.61,
10.28,
11.26,
4.28,
13.43,
8.90,
5.75,
7.52,
7.06,
6.83,
11.93,
9.33,
8.95,
13.75,
10.16,
12.11,
11.94,
14.33,
12.00,
7.11,
7.78,
5.50,
6.04,
7.16,
8.74,
15.69,
14.80,
16.25,
9.89,
8.08,
8.47,
8.10,
7.26,
17.50,
6.17,
8.39,
6.55,
8.50,
8.20,
14.80,
13.29,
11.50,
12.93,
2.90,
11.21,
13.91,
15.75,
15.24,
5.26,
7.21,
11.93,
5.56,
17.00,
15.58,
13.00,
4.37,
9.94,
8.83,
11.71,
5.24,
7.42,
8.38,
7.37,
10.59,
11.40,
10.28,
10.89,
12.00,
14.43,
14.94,
12.50,
5.50,
9.28,
14.38,
11.17,
10.11,
16.54,
11.86,
3.78,
6.16,
5.94,
4.60,
8.59,
11.45,
13.42,
8.15,
13.53,
16.50,
10.28,
11.95,
13.67,
9.00,
11.05,
14.94,
12.45,
9.60,
13.30,
5.74,
11.07,
8.65,
9.67,
9.89,
10.50,
10.00,
8.44,
6.44,
12.81,
11.47,
6.89,
9.00,
12.18,
11.06,
11.33,
11.11,
9.17,
8.44,
17.43,
12.15,
8.35,
10.06,
12.06,
14.22,
15.89,
11.25,
2.00,
10.75,
12.85,
15.33,
11.75,
6.58,
9.19,
6.67,
9.67,
6.90,
9.88,
9.76,
11.84,
11.61,
13.06,
12.71,
7.83,
10.00,
8.40,
11.17,
6.68,
6.89,
13.17,
13.12,
14.50,
7.21,
12.86,
5.78,
12.12,
10.89,
5.25,
5.37,
7.22,
7.78,
8.72,
11.79,
10.33,
9.28,
17.40,
14.00,
11.21,
14.33,
5.17,
14.37,
11.29,
9.80,
12.57,
12.47,
13.33,
12.75,
4.50,
11.60,
12.75,
12.33,
5.38,
11.89,
12.18,
11.59,
12.28,
11.47,
12.25,
13.82,
13.54,
14.89,
10.83,
9.89,
10.19,
17.17,
7.72,
11.39,
12.21,
15.60,
11.12,
11.72,
6.71,
11.18,
9.11,
15.00,
13.00,
8.05,
11.37,
8.90,
10.72,
9.00,
11.42,
13.20,
6.42,
4.83,
10.71,
13.00,
11.61,
8.75,
6.74,
12.29,
8.84,
11.94,
6.94,
9.00,
9.14,
6.47,
10.13,
16.00,
13.93,
14.09,
10.00,
11.05,
10.95,
5.67,
5.30,
8.59,
17.86,
8.63,
11.00,
9.18,
11.67,
16.80,
10.38,
10.45,
12.67,
11.00,
16.00,
15.35,
4.85,
8.58,
6.74,
9.27,
3.23,
5.00,
7.67,
11.67,
3.84,
5.83,
7.90,
3.00,
3.22,
4.83,
15.00,
17.00,
7.94,
11.07,
11.95,
12.92,
7.05,
10.06,
10.37,
5.58,
6.95,
10.69,
13.10,
8.25,
9.95,
8.42,
13.00,
12.47,
9.55,
7.58,
10.28,
9.69,
9.00,
10.94,
13.78,
13.00,
15.00,
14.38,
10.62,
8.67,
11.64,
11.00,
11.53,
13.62,
9.89,
10.53,
5.11,
4.80,
4.95,
11.56,
9.25,
12.45,
9.33,
7.74,
11.37,
5.63,
8.37,
8.28,
10.39,
7.39,
5.42,
11.25,
8.78,
13.26,
13.31,
3.42,
6.11,
14.29,
10.72,
7.00,
6.05,
3.58,
8.58,
4.84,
7.20,
8.00,
7.23,
8.67,
10.15,
11.59,
6.53,
8.89,
5.74,
11.94,
9.37,
6.15,
8.83,
7.95,
14.75,
11.71,
12.23,
10.58,
10.11,
11.20,
5.72,
9.68,
5.05,
5.21,
11.75,
12.00,
12.78,
4.44,
11.75,
11.11,
12.67,
5.85,
2.89,
6.78,
11.72,
9.00,
6.67,
11.94,
14.67,
12.83,
14.33,
11.00,
11.18,
6.62,
11.11,
10.67,
8.67,
3.90,
7.71,
11.50,
10.74,
7.72,
9.16,
11.92,
3.95,
5.00,
11.69,
6.58,
15.33,
10.26,
8.53,
7.47,
8.94,
9.16,
12.59,
5.32,
10.61,
6.11,
12.53,
8.50,
6.56,
5.32,
10.69,
10.59,
5.45,
12.79,
8.50,
11.50,
5.78,
14.20,
7.63,
11.67,
4.84,
6.70,
5.40,
11.33,
12.94,
12.88,
15.57,
13.62,
14.18,
9.20,
4.28,
7.05,
11.89,
15.17,
11.74,
12.22,
13.94,
4.90,
10.58,
12.86,
11.11,
14.71,
14.59,
7.74,
5.99,
13.82,
11.45,
13.00,
6.22,
10.50,
14.89,
13.75,
11.33,
11.62,
7.30,
10.85,
8.50,
5.78,
6.56,
13.45,
12.88,
3.89,
14.71,
7.95,
10.37,
11.21,
15.00,
12.47,
14.25,
12.89,
10.11,
11.11,
13.19,
4.05,
6.58,
13.31,
3.78,
5.67,
6.47,
9.17,
9.94,
5.84,
4.62,
10.64,
9.65,
11.88,
12.19,
10.80,
13.80,
14.71,
13.39,
4.21,
12.28,
13.55,
10.22,
5.56,
9.67,
7.69,
5.67,
14.11,
14.43,
11.25,
14.94,
15.06,
10.84,
15.06,
9.17,
14.76,
14.00,
11.83,
13.56,
10.88,
18.00,
12.78,
12.00,
14.16,
15.46,
13.71,
14.82,
14.00,
12.05,
14.47,
14.69,
10.33,
15.11,
19.67,
13.00,
5.21,
10.89,
12.83,
11.20,
15.20,
12.93,
13.61,
7.22,
13.44,
12.88,
14.13,
14.00,
13.57,
11.27,
4.09,
13.94,
15.28,
13.53,
15.50,
12.92,
10.00,
9.37,
7.00,
13.94,
13.09,
13.07,
11.29,
15.60,
9.39,
9.78,
9.17,
12.17,
13.73,
5.02,
11.58,
9.74,
11.55,
5.47,
8.71,
6.63,
13.50,
8.05,
10.29,
11.63,
12.35,
13.06,
10.61,
13.20,
10.00,
6.32,
15.83,
15.60,
16.50,
9.47,
10.71,
10.95,
13.33,
4.53,
4.95,
6.90,
10.95,
13.29,
17.29,
15.79,
11.22,
8.67,
9.42,
8.94,
14.67,
13.89,
12.50,
4.26,
9.84,
9.32,
9.58,
12.08,
13.56,
12.92,
13.64,
11.22,
11.74,
11.39,
10.72,
16.83,
16.00,
2.89,
14.38,
14.88,
13.24,
7.28,
7.33,
10.33,
9.37,
11.00,
14.11,
7.00,
8.32,
12.17,
12.75,
13.35,
8.95,
14.36,
4.79,
7.26,
8.26,
13.72,
14.00,
11.67,
12.53,
11.74,
14.33,
13.88,
6.42,
13.05,
15.06,
7.72,
13.20,
16.00,
9.38,
11.11,
10.63,
10.89,
8.37,
9.68,
8.89,
10.07,
10.38,
10.93,
13.82,
12.22,
16.40,
4.68,
13.39,
10.24,
10.42,
11.00,
14.78,
15.22,
6.95,
13.53,
14.00,
7.32,
6.79,
13.75,
9.75,
14.88,
11.94,
12.68,
13.94,
12.33,
14.47,
15.07,
14.06,
14.18,
15.25,
13.65,
16.74,
15.62,
10.37,
10.05,
9.47,
11.71,
11.75,
10.95,
10.90,
9.35,
15.93,
15.67,
16.43,
14.76,
13.15,
13.81,
15.29,
15.53,
14.05,
14.06,
16.79,
15.11,
14.62,
15.06,
13.50,
13.11,
15.56,
14.12,
14.17,
13.50,
16.20,
12.62,
12.44,
14.94,
15.05,
9.83,
10.84,
3.52,
5.95,
8.22,
5.63,
5.43,
6.56,
9.60,
6.70,
11.82,
9.58,
14.60,
15.00,
5.21,
2.85,
10.94,
6.85,
6.90,
10.61,
13.37,
9.22,
4.63,
11.72,
14.11,
12.78,
14.47,
10.50,
8.11,
14.00,
11.79,
6.47,
10.11,
13.94,
12.55,
11.44,
3.58,
6.00,
6.10,
8.81,
14.50,
9.74,
11.78,
7.44,
16.80,
13.00,
14.80,
9.93,
8.53,
7.95,
10.41,
7.50,
3.56,
10.87,
8.95,
6.11,
5.42,
13.19,
9.05,
9.83,
13.00,
11.27,
13.56,
10.83,
10.39,
11.76,
10.05,
11.84,
7.00,
9.68,
9.84,
9.31,
7.72,
10.78,
6.72,
9.41,
8.98,
13.62,
6.48,
10.12,
9.67,
10.94,
13.12,
13.50,
8.35,
13.31,
14.77,
11.84,
6.05,
3.61,
9.15,
10.22,
13.54,
12.65,
10.80,
13.59,
11.25,
10.72,
7.06,
8.89,
6.58,
13.20,
12.28,
12.80,
7.47,
9.60,
14.50,
8.00,
10.94,
12.68,
11.94,
12.92,
4.28,
9.76,
9.47,
10.95,
14.50,
10.17,
6.84,
5.33,
6.11,
10.18,
7.63,
7.06,
13.38,
11.73,
13.75,
6.63,
7.00,
8.11,
7.21,
7.21,
13.11,
10.50,
5.63,
8.89,
12.80,
11.31,
10.16,
10.05,
6.74,
9.83,
16.13,
11.87,
10.40,
15.60,
7.94,
11.56,
10.72,
11.00,
6.16,
7.79,
4.79,
10.55,
8.89,
11.70,
9.11,
8.80,
10.28,
10.58,
11.42,
16.55,
13.88,
6.72,
6.06,
10.39,
10.50,
10.41,
4.89,
11.20,
12.74,
11.32,
10.78,
10.44,
14.29,
13.00,
13.82,
11.44,
11.29,
7.00,
7.63,
9.05,
9.56,
9.61,
13.00,
5.83,
6.84,
10.61,
9.24,
7.17,
8.94,
6.61,
8.38,
9.28,
11.06,
11.50,
13.00,
6.65,
16.82,
4.00,
13.73,
8.32,
9.19,
10.89,
13.32,
9.32,
11.83,
9.42,
11.82,
9.94,
7.11,
14.14,
8.08,
7.84,
9.82,
13.50,
3.53,
9.73,
9.31,
6.22,
5.37,
12.38,
10.57,
13.29,
15.11,
8.76,
10.33,
11.56,
5.84,
9.15,
14.18,
12.10,
9.39,
9.81,
13.36,
12.08,
8.82,
12.06,
8.25,
10.61,
12.11,
10.75,
11.05,
11.72,
11.06,
8.95,
11.56,
9.33,
7.28,
8.76,
7.95,
9.44,
11.79,
9.39,
9.56,
9.83,
6.37,
9.35,
9.00,
10.95,
10.81,
8.94,
8.22,
11.12,
9.00,
10.55,
3.84,
7.06,
10.21,
8.45,
11.00,
8.72,
14.64,
8.63,
6.16,
9.88,
10.06,
7.06,
17.50,
9.38,
7.58,
8.69,
9.59,
11.40,
12.82,
14.00,
10.93,
13.67,
14.50,
11.19,
8.17,
10.75,
4.28,
10.75,
10.56,
10.00,
9.33,
12.26,
9.37,
10.73,
6.21,
12.00,
11.00,
9.94,
5.57,
15.37,
6.60,
8.89,
13.00,
13.57,
11.62,
9.21,
11.74,
10.90,
9.80,
13.92,
16.75,
13.30,
14.94,
12.67,
14.40,
12.50,
7.28,
15.60,
8.00,
11.95,
13.14,
11.37,
12.31,
15.59,
19.00,
8.84,
7.74,
10.65,
11.22,
11.75,
14.67,
9.79,
9.28,
10.21,
15.06,
11.12,
11.44,
13.67,
13.53,
13.70,
13.83,
5.53,
9.44,
7.89,
11.89,
12.77,
8.79,
8.80,
14.16,
8.40,
15.25,
5.56,
8.28,
6.83,
10.14,
11.22,
8.17,
9.53,
3.78,
8.16,
7.33,
13.12,
4.63,
4.26,
9.72,
5.11,
4.29,
3.68,
5.63,
8.88,
13.65,
10.89,
9.15,
11.00,
10.13,
7.32,
9.95,
8.26,
6.75,
10.29,
7.37,
7.68,
6.89,
6.45,
8.35,
5.78,
5.56,
12.12,
9.79,
13.22,
12.94,
14.33,
12.14,
13.19,
16.40,
7.89,
8.95,
3.89,
12.50,
7.16,
6.89,
10.18,
13.11,
13.00,
8.06,
14.06,
4.86,
9.94,
9.68,
10.42,
12.06,
13.25,
6.31,
9.86,
14.23,
13.00,
16.21,
6.85,
15.20,
14.94,
10.24,
8.91,
13.24,
6.25,
7.68,
13.91,
13.50,
15.08,
5.26,
9.11,
4.68,
11.35,
6.95,
8.75,
15.23,
12.00,
11.87,
0.00,
12.50,
13.00,
6.16,
7.06,
11.82,
10.78,
10.63,
12.68,
10.53,
4.77,
6.50,
15.25,
10.67,
3.56,
11.81,
10.47,
10.29,
9.58,
5.28,
11.92,
8.33,
13.56,
13.00,
13.71,
16.88,
11.79,
15.80,
13.41,
7.92,
9.19,
15.50,
6.17,
12.67,
7.22,
4.61,
9.00,
8.35,
17.14,
10.20,
10.21,
12.05,
13.62,
10.47,
9.35,
8.72,
11.05,
16.36,
14.64,
14.43,
11.83,
9.78,
13.00,
12.41,
4.95,
9.72,
9.78,
12.77,
12.42,
4.26,
9.59,
10.58,
7.06,
11.35,
7.00,
7.94,
4.29,
4.30,
8.50,
7.95,
8.44,
7.16,
7.83,
10.21,
3.67,
12.79,
6.89,
8.61,
10.17,
9.39,
6.21,
8.21,
7.89,
5.68,
11.82,
8.56,
14.87,
9.61,
14.83,
8.56,
15.23,
11.89,
7.79,
15.11,
12.57,
7.00,
7.22,
9.00,
5.76,
13.76,
11.00,
8.45,
10.31,
8.95,
14.28,
9.61,
11.53,
13.05,
10.25,
10.65,
7.86,
13.61,
13.83,
5.95,
12.20,
10.06,
9.95,
5.11,
11.47,
7.72,
14.69,
12.39,
12.10,
11.47,
11.00,
9.05,
13.75,
11.00,
11.82,
6.21,
16.00,
6.32,
9.68,
12.88,
6.00,
8.26,
8.32,
12.12,
9.20,
11.78,
10.06,
12.32,
10.62,
12.19,
13.21,
11.72,
3.58,
8.61,
9.67,
10.75,
6.80,
9.11,
13.29,
12.54,
11.38,
11.50,
5.30,
8.21,
11.18,
11.79,
10.11,
9.84,
3.47,
13.21,
9.00,
11.53,
9.20,
12.27,
6.60,
10.75,
10.74,
10.28,
12.39,
8.03,
9.95,
5.81,
11.17,
14.85,
4.11,
8.11,
9.56,
9.50,
11.00,
12.79,
11.79,
12.10,
9.00,
11.53,
9.30,
6.22,
11.24,
6.39,
10.00,
15.00,
13.47,
9.06,
11.01,
10.72,
10.44,
9.62,
13.00,
9.74,
10.65,
6.43,
11.71,
9.50,
9.28,
11.18,
10.40,
8.47,
5.10,
11.33,
8.47,
5.58,
13.30,
10.82,
10.33,
10.35,
12.69,
8.44,
6.37,
12.59,
11.64,
12.82,
11.58,
15.00,
13.36,
13.50,
13.12,
6.58,
7.06,
7.24,
10.50,
11.82,
7.95,
13.79,
10.68,
11.19,
12.73,
14.25,
4.42,
9.38,
10.47,
14.07,
12.18,
13.57,
15.45,
13.73,
10.17,
12.11,
10.58,
12.13,
9.78,
9.17,
10.72,
10.50,
13.83,
11.44,
9.30,
9.16,
9.53,
12.73,
9.56,
13.67,
13.11,
11.88,
13.21,
14.42,
5.20,
12.77,
9.58,
10.44,
8.65,
9.63,
10.53,
10.94,
15.80,
16.29,
14.00,
13.00,
12.00,
14.33,
13.25,
9.00,
13.08,
8.17,
10.00,
10.00,
13.50,
10.67,
10.71,
10.47,
11.71,
13.44,
12.67,
11.53,
8.42,
5.50,
6.11,
9.00,
14.94,
3.63,
9.44,
7.78,
11.73,
9.82,
13.44,
4.20,
4.80,
7.71,
12.25,
11.74,
14.85,
12.73,
9.89,
14.00,
5.45,
9.44,
15.80,
13.20,
8.68,
8.61,
12.52,
3.78,
9.56,
10.50,
9.26,
12.80,
11.17,
14.77,
13.09,
10.17,
12.00,
11.82,
10.85,
13.11,
3.79,
5.83,
8.17,
8.56,
13.50,
18.50,
10.63,
8.76,
14.47,
7.68,
9.06,
12.58,
5.61,
7.05,
12.47,
14.00,
6.42,
7.79,
16.50,
13.57,
9.60,
10.06,
9.82,
8.71,
8.69,
12.00,
7.01,
10.44,
10.47,
12.70,
5.37,
9.25,
8.98,
10.05,
14.53,
12.62,
11.74,
6.70,
12.37,
7.58,
15.86,
9.16,
13.44,
3.79,
11.06,
10.53,
13.06,
6.95,
10.06,
8.88,
10.12,
4.45,
5.95,
6.16,
9.63,
6.56,
15.43,
13.44,
17.20,
8.10,
12.62,
11.62,
12.94,
13.32,
10.21,
13.07,
10.47,
4.53,
7.28,
8.06,
5.61,
6.70,
10.28,
9.42,
9.11,
8.78,
9.32,
6.33,
12.00,
12.06,
10.62,
12.94,
12.94,
9.94,
10.69,
10.00,
11.00,
6.05,
10.92,
13.00,
15.40,
8.11,
14.00,
7.27,
4.11,
11.64,
7.30,
4.35,
7.32,
8.89,
10.37,
16.22,
5.11,
5.79,
4.89,
15.36,
7.06,
11.25,
11.60,
12.58,
11.16,
11.07,
9.07,
13.45,
11.08,
10.37,
5.62,
6.48,
9.50,
10.20,
10.74,
16.50,
12.89,
4.50,
8.66,
12.45,
11.22,
9.76,
11.84,
12.58,
13.00,
9.11,
10.78,
11.95,
11.95,
12.74,
14.05,
12.18,
15.00,
13.65,
5.17,
12.79,
7.00,
10.89,
9.06,
10.08,
17.25,
9.22,
11.97,
10.28,
14.69,
12.94,
10.47,
4.72,
8.00,
12.11,
4.67,
9.33,
9.37,
10.72,
8.45,
11.28,
15.78,
16.31,
12.00,
16.00,
7.72,
14.60,
6.28,
3.85,
10.21,
14.00,
4.90,
7.60,
11.38,
12.00,
12.21,
11.18,
7.33,
7.47,
10.55,
9.38,
9.84,
11.11,
16.25,
7.47,
11.93,
9.23,
15.75,
8.38,
12.00,
10.89,
11.45,
5.95,
9.53,
8.88,
9.06,
4.60,
14.41,
11.33,
7.90,
15.43,
11.26,
7.50,
3.73,
5.78,
8.89,
6.90,
11.78,
3.67,
7.60,
10.47,
6.78,
6.16,
10.44,
7.72,
4.78,
6.84,
10.67,
12.80,
12.39,
5.56,
9.37,
6.94,
11.11,
7.10,
9.83,
5.72,
14.62,
5.41,
3.28,
12.41,
13.89,
12.44,
9.89,
13.31,
11.06,
13.57,
12.20,
14.20,
14.59,
6.94,
15.75,
18.00,
13.00,
15.71,
15.70,
13.18,
13.12,
12.63,
5.78,
9.38,
8.57,
13.00,
15.33,
6.39,
6.06,
9.20,
13.27,
12.44,
8.83,
10.50,
6.44,
15.25,
16.29,
10.67,
17.55,
15.05,
15.12,
8.56,
15.38,
17.00,
6.72,
16.17,
13.00,
16.60,
15.47,
9.83,
13.06,
14.57,
9.68,
15.20,
14.92,
15.45,
18.50,
13.53,
8.75,
7.15,
9.16,
14.50,
5.06,
13.86,
12.19,
15.27,
17.17,
12.64,
3.26,
9.61,
9.81,
16.75,
12.67,
13.16,
8.19,
16.25,
13.73,
11.05,
21.00,
14.94,
15.44,
14.27,
9.89,
9.26,
8.78,
10.28,
8.22,
14.00,
14.80,
5.89,
15.25,
6.63,
12.67,
11.06,
12.89,
12.44,
10.75,
13.67,
14.10,
16.00,
14.75,
14.08,
4.74,
10.89,
6.78,
11.07,
11.38,
12.94,
12.45,
10.84,
11.19,
13.25,
9.88,
6.26,
8.21,
13.60,
12.80,
11.42,
14.64,
15.38,
13.67,
8.84,
14.59,
12.72,
9.23,
12.22,
16.00,
11.65,
5.11,
10.43,
13.10,
15.71,
11.06,
6.00,
10.28,
11.06,
11.40,
13.72,
7.53,
5.78,
12.55,
13.37,
14.40,
6.67,
5.11,
7.83,
13.44,
5.33,
10.25,
6.27,
7.11,
13.60,
10.84,
11.94,
14.22,
4.32,
0.00,
7.61,
12.83,
16.38,
15.25,
7.32,
13.17,
12.33,
7.89,
10.05,
13.43,
8.39,
11.78,
12.18,
10.79,
12.29,
10.39,
14.29,
5.37,
7.78,
7.00,
9.05,
8.62,
5.61,
11.93,
4.00,
5.74,
11.67,
8.45,
8.83,
10.44,
13.33,
7.56,
13.44,
11.15,
9.11,
12.26,
12.63,
11.11,
8.39,
7.90,
15.00,
6.39,
4.83,
13.88,
6.63,
7.43,
12.68,
9.78,
14.00,
14.62,
15.00,
13.59,
6.96,
9.82,
11.67,
7.22,
13.82,
14.33,
16.00,
9.04,
15.20,
13.53,
15.29,
13.64,
16.00,
8.15,
13.92,
11.33,
8.39,
5.53,
9.40,
9.16,
14.00,
15.00,
12.07,
9.83,
6.90,
8.24,
12.41,
7.47,
14.92,
13.17,
8.10,
11.42,
12.65,
14.42,
8.53,
7.84,
14.36,
12.93,
10.50,
13.70,
11.89,
13.00,
12.41,
12.42,
15.46,
10.81,
16.33,
15.89,
10.24,
11.00,
13.47,
13.33,
10.44,
6.06,
15.50,
10.27,
8.94,
10.44,
10.68,
10.58,
9.44,
9.06,
9.06,
6.39,
14.62,
3.37,
17.00,
15.00,
11.23,
6.58,
13.65,
15.75,
13.25,
9.28,
15.00,
14.44,
14.33,
14.36,
13.47,
10.83,
15.00,
10.55,
16.31,
11.70,
12.25,
11.79,
15.60,
16.60,
12.33,
18.22,
13.83,
12.25,
14.44,
14.00,
15.25,
6.20,
14.89,
5.40,
11.16,
11.74,
10.11,
8.00,
14.89,
13.47,
13.42,
13.58,
15.50,
13.32,
12.58,
5.65,
11.56,
9.95,
9.89,
5.09,
8.83,
8.37,
6.61,
10.11,
10.89,
9.75,
10.17,
12.36,
11.06,
7.56,
9.48,
11.33,
12.06,
12.26,
13.64,
16.86,
12.33,
11.83,
10.94,
7.78,
13.33,
9.06,
15.62,
12.50,
12.29,
14.06,
10.21,
14.00,
15.11,
6.95,
14.55,
11.00,
9.67,
15.36,
7.28,
16.50,
15.06,
17.08,
8.06,
13.22,
6.68,
11.42,
13.93,
14.53,
7.39,
9.83,
6.05,
11.44,
13.06,
6.37,
9.95,
11.19,
5.84,
8.26,
13.00,
15.20,
2.74,
5.16,
11.83,
9.38,
5.63,
9.33,
6.16,
14.09,
11.93,
14.06,
10.94,
14.14,
7.72,
12.50,
14.00,
14.82,
12.75,
7.00,
3.11,
7.56,
14.83,
10.95,
5.94,
6.35,
6.78,
15.62,
12.30,
14.14,
6.74,
10.22,
13.50,
12.89,
12.32,
13.25,
10.22,
4.84,
12.18,
9.47,
9.75,
8.68,
6.74,
13.05,
11.53,
9.58,
13.12,
8.26,
11.87,
17.44,
7.32,
6.37,
12.29,
14.88,
7.24,
11.22,
9.06,
13.07,
15.50,
12.27,
16.41,
5.80,
9.36,
10.25,
12.43,
14.25,
11.94,
9.83,
11.05,
12.84,
16.83,
17.10,
3.68,
13.32,
12.33,
11.75,
14.56,
16.20,
8.55,
11.62,
7.84,
14.54,
15.00,
12.56,
13.95,
14.28,
15.55,
15.71,
14.00,
16.00,
10.56,
14.90,
17.00,
17.00,
15.70,
11.15,
12.11,
15.07,
13.62,
11.45,
4.61,
9.56,
7.63,
12.00,
11.22,
8.74,
11.00,
11.72,
10.44,
9.47,
8.15,
10.85,
12.19,
12.00,
5.17,
15.77,
9.47,
6.61,
14.50,
14.40,
15.06,
10.58,
14.37,
14.84,
14.60,
15.50,
7.84,
9.77,
11.32,
9.42,
7.17,
6.89,
6.65,
12.83,
11.18,
6.53,
8.38,
11.16,
10.32,
14.88,
13.33,
9.61,
6.18,
12.94,
9.59,
12.06,
13.89,
13.62,
5.84,
12.38,
11.78,
15.00,
15.14,
14.76,
6.22,
9.71,
8.25,
10.00,
14.56,
12.90,
12.47,
9.16,
9.50,
6.74,
16.13,
6.28,
8.84,
10.56,
12.83,
10.11,
7.50,
12.06,
14.50,
14.70,
12.81,
9.26,
11.57,
12.11,
17.25,
9.86,
15.50,
4.75,
14.50,
19.00,
11.22,
12.25,
6.21,
7.05,
10.06,
8.11,
16.67,
15.40,
5.78,
13.96,
11.00,
17.92,
12.37,
11.94,
10.00,
8.94,
12.47,
11.55,
10.75,
9.89,
11.47,
14.67,
11.53,
13.65,
14.07,
13.22,
15.30,
6.89,
7.78,
16.33,
9.74,
12.05,
13.05,
13.00,
11.14,
6.00,
9.39,
13.00,
13.11,
10.18,
6.57,
12.29,
10.80,
10.67,
8.89,
9.55,
10.47,
11.89,
13.17,
8.56,
7.39,
8.00,
14.65,
11.79,
11.72,
12.25,
13.14,
14.56,
15.17,
14.47,
16.38,
13.00,
7.00,
15.33,
13.60,
8.37,
9.50,
4.44,
14.45,
12.47,
10.42,
16.20,
15.50,
12.82,
10.89,
11.59,
7.11,
16.55,
15.57,
13.00,
8.20,
7.24,
10.11,
11.11,
8.42,
11.60,
10.58,
11.10,
14.67,
14.08,
13.00,
13.95,
14.67,
11.63,
14.36,
15.38,
15.58,
13.65,
13.00,
11.68,
10.25,
16.17,
8.00,
13.38,
13.94,
5.22,
7.00,
3.43,
12.18,
12.09,
10.84,
12.21,
11.00,
12.95,
13.33,
13.80,
11.35,
4.47,
5.78,
8.05,
7.55,
8.37,
13.75,
9.30,
13.67,
11.72,
10.18,
14.50,
7.53,
10.26,
8.61,
9.37,
7.78,
13.64,
11.45,
12.12,
13.09,
12.54,
11.77,
8.63,
10.00,
16.20,
4.26,
10.37,
12.41,
11.58,
12.19,
12.29,
10.26,
11.20,
5.79,
15.50,
6.88,
12.36,
16.33,
15.43,
11.50,
13.00,
13.38,
10.05,
8.50,
10.00,
16.00,
11.38,
14.92,
6.95,
9.16,
12.19,
15.09,
6.42,
9.34,
6.47,
10.47,
9.93,
11.16,
10.61,
9.56,
12.16,
8.00,
14.00,
16.25,
7.50,
10.63,
9.11,
12.57,
11.82,
12.47,
10.50,
7.63,
13.00,
12.36,
14.88,
8.09,
10.79,
6.26,
10.89,
13.33,
10.20,
7.59,
10.33,
14.00,
11.45,
5.53,
11.22,
14.33,
12.00,
12.20,
13.11,
14.62,
12.28,
12.50,
11.64,
11.78,
7.33,
13.22,
16.00,
14.64,
7.56,
9.33,
8.22,
10.24,
9.84,
13.35,
12.71,
13.28,
12.11,
7.11,
9.35,
9.71,
10.82,
10.60,
9.84,
9.67,
8.79,
5.11,
6.06,
5.53,
8.67,
6.89,
6.74,
9.28,
6.11,
7.78,
9.28,
9.00,
6.44,
13.00,
12.36,
14.17,
6.06,
7.14,
5.06,
7.17,
11.24,
10.63,
6.64,
5.00,
7.68,
8.15,
6.00,
9.22,
8.59,
7.19,
4.33,
9.75,
4.78,
8.11,
13.20,
6.21,
8.16,
8.30,
15.00,
9.50,
9.70,
12.91,
8.94,
9.94,
14.24,
15.78,
16.07,
12.53,
14.67,
17.50,
8.90,
14.50,
5.58,
13.50,
12.18,
11.93,
14.57,
5.37,
8.37,
10.79,
5.05,
6.55,
12.32,
14.33,
17.00,
10.79,
4.05,
6.39,
7.00,
6.74,
14.67,
14.45,
9.53,
13.57,
12.75,
12.90,
5.53,
9.79,
3.26,
5.95,
13.43,
12.50,
10.22,
12.33,
7.53,
10.75,
13.78,
10.78,
5.15,
10.37,
8.00,
9.25,
7.94,
6.33,
8.78,
9.38,
8.11,
7.95,
4.10,
6.61,
13.33,
7.58,
6.40,
9.94,
6.50,
8.65,
6.39,
7.83,
6.58,
7.17,
7.26,
4.22,
10.05,
12.67,
10.33,
10.47,
8.17,
12.54,
13.07,
12.20,
5.89,
4.70,
8.20,
14.31,
16.00,
17.57,
14.86,
14.05,
12.67,
4.63,
9.12,
14.08,
10.53,
9.22,
11.45,
9.59,
11.71,
12.21,
15.33,
11.95,
13.89,
11.07,
14.67,
13.21,
18.71,
12.22,
11.71,
8.57,
14.00,
17.00,
11.75,
16.50,
10.69,
13.14,
13.47,
3.33,
7.05,
5.17,
6.53,
7.83,
6.11,
13.50,
11.00,
8.60,
17.25,
12.69,
13.88,
13.15,
16.43,
6.11,
17.60,
4.74,
6.56,
8.37,
6.74,
11.47,
8.21,
10.60,
9.16,
8.50,
7.85,
8.07,
11.50,
9.71,
5.79,
13.16,
12.25,
12.14,
12.86,
13.46,
11.73,
14.75,
13.83,
7.50,
7.89,
8.00,
11.08,
7.95,
18.00,
13.67,
8.43,
10.56,
4.35,
4.14,
12.56,
15.22,
16.92,
8.32,
13.80,
12.89,
11.00,
13.35,
11.78,
11.25,
9.45,
13.40,
13.40,
11.74,
14.50,
12.83,
14.23,
16.00,
11.69,
8.47,
10.60,
7.78,
6.72,
10.92,
6.30,
12.00,
12.57,
9.37,
9.33,
13.67,
9.24,
9.78,
10.95,
8.53,
7.42,
5.15,
6.94,
9.30,
8.84,
9.47,
6.42,
14.07,
13.14,
9.00,
7.68,
14.75,
13.50,
11.54,
16.00,
15.33,
16.17,
5.83,
11.62,
7.67,
6.39,
15.17,
12.27,
14.79,
13.75,
10.72,
14.50,
14.33,
7.57,
14.44,
8.85,
11.26,
13.75,
13.53,
13.78,
5.37,
13.82,
12.50,
13.12,
14.25,
3.67,
11.78,
14.56,
12.62,
7.68,
13.25,
9.79,
10.05,
13.17,
13.47,
17.00,
12.24,
12.11,
11.84,
12.50,
11.28,
14.15,
16.67,
9.84,
13.75,
13.53,
4.53,
14.36,
13.07,
18.67,
11.08,
15.00,
12.00,
13.13,
12.95,
13.29,
15.50,
7.74,
12.90,
9.95,
15.80,
12.11,
6.63,
6.56,
13.42,
10.32,
11.33,
11.80,
11.35,
10.89,
9.76,
13.38,
10.00,
10.06,
9.89,
11.05,
10.90,
9.38,
9.33,
10.17,
9.05,
13.05,
16.12,
15.00,
13.06,
12.33,
7.37,
12.38,
11.40,
10.58,
11.63,
10.89,
13.00,
8.11,
11.05,
13.28,
9.53,
6.95,
13.29,
12.19,
3.40,
11.39,
9.25,
12.33,
12.40,
15.78,
15.67,
12.06,
13.57,
11.24,
9.22,
15.50,
10.55,
8.95,
8.63,
4.95,
8.42,
7.68,
13.23,
9.04,
11.11,
11.58,
9.65,
14.11,
15.40,
15.80,
10.39,
7.15,
5.63,
10.32,
8.74,
12.16,
10.28,
12.42,
12.33,
13.38,
13.56,
4.70,
8.28,
12.11,
5.32,
3.89,
5.76,
7.63,
6.56,
8.11,
8.22,
9.11,
5.06,
5.80,
8.95,
11.11,
12.50,
8.25,
13.94,
9.00,
12.95,
13.31,
8.94,
12.56,
11.83,
14.12,
9.89,
10.15,
9.72,
11.89,
10.79,
6.74,
14.12,
7.50,
7.75,
13.67,
16.20,
12.13,
6.94,
6.89,
12.05,
14.12,
7.28,
9.61,
13.24,
7.95,
10.22,
10.79,
5.30,
8.35,
8.05,
13.67,
10.94,
10.12,
7.74,
10.47,
7.28,
9.37,
11.84,
13.35,
9.44,
11.43,
6.61,
7.76,
9.56,
8.89,
12.42,
12.50,
15.20,
14.00,
6.95,
9.68,
9.35,
8.53,
4.42,
8.74,
8.79,
10.59,
12.94,
16.00,
9.39,
10.36,
16.50,
14.71,
11.67,
8.89,
14.20,
9.19,
4.50,
7.32,
5.00,
9.75,
8.00,
12.11,
11.05,
5.30,
8.83,
3.11,
15.33,
6.67,
7.83,
14.71,
4.22,
3.63,
14.29,
8.37,
7.60,
9.60,
5.50,
16.86,
10.88,
11.56,
5.74,
6.35,
3.72,
8.84,
9.50,
14.50,
14.56,
5.89,
13.17,
11.17,
7.44,
13.62,
5.22,
6.06,
7.71,
8.62,
7.32,
8.22,
7.26,
8.22,
9.37,
8.22,
10.00,
10.24,
8.33,
6.89,
11.39,
17.50,
14.18,
13.95,
14.00,
16.38,
6.65,
16.22,
16.00,
13.94,
10.33,
9.11,
11.56,
6.43,
8.11,
7.89,
9.39,
9.00,
13.73,
3.58,
5.36,
8.56,
8.56,
10.80,
10.65,
11.47,
14.87,
6.52,
13.47,
10.40,
10.00,
9.00,
10.50,
7.47,
7.50,
12.16,
14.14,
13.92,
15.14,
11.32,
13.00,
15.17,
12.29,
10.45,
9.44,
13.00,
10.86,
11.00,
12.60,
10.00,
12.42,
14.14,
11.12,
14.15,
15.62,
8.74,
6.25,
12.42,
13.62,
9.53,
10.50,
6.84,
14.00,
6.50,
8.95,
11.50,
13.00,
12.89,
8.11,
11.23,
14.56,
16.47,
13.42,
14.56,
12.61,
10.28,
14.50,
9.71,
11.92,
14.67,
18.00,
16.65,
11.84,
11.69,
13.22,
13.88,
12.10,
13.24,
13.42,
15.33,
14.36,
14.91,
15.06,
12.59,
12.12,
14.06,
11.56,
12.78,
4.94,
10.11,
7.78,
5.53,
12.08,
14.43,
7.79,
14.09,
11.30,
11.33,
14.08,
14.86,
13.92,
17.60,
14.16,
15.22,
13.63,
15.29,
12.18,
13.20,
12.15,
14.65,
12.82,
11.60,
14.35,
11.95,
13.71,
12.25,
15.21,
16.00,
13.67,
13.69,
9.67,
4.17,
10.62,
11.11,
9.95,
11.17,
10.94,
15.93,
15.35,
4.74,
4.33,
9.00,
3.95,
8.95,
9.72,
6.89,
6.72,
14.00,
13.06,
15.00,
11.90,
17.00,
11.84,
10.61,
14.28,
12.33,
11.63,
8.33,
14.67,
9.17,
11.26,
6.56,
8.89,
12.43,
11.33,
15.06,
13.63,
13.69,
12.42,
5.61,
8.11,
8.32,
7.21,
9.81,
8.00,
8.37,
16.33,
14.44,
13.30,
9.79,
7.05,
15.80,
9.05,
19.00,
13.92,
14.76,
14.14,
13.92,
14.33,
11.41,
12.17,
8.26,
10.75,
9.62,
8.76,
12.32,
10.16,
14.00,
10.74,
9.83,
9.72,
9.84,
15.76,
8.21,
13.91,
4.00,
8.06,
11.18,
14.86,
9.74,
5.95,
13.33,
4.89,
3.50,
12.65,
11.94,
6.81,
9.00,
11.50,
16.94,
13.50,
8.98,
14.31,
7.56,
10.85,
15.27,
10.83,
14.24,
5.50,
10.33,
11.89,
11.74,
12.11,
8.42,
7.47,
8.78,
10.61,
10.89,
11.42,
3.32,
9.80,
8.89,
10.94,
11.09,
9.44,
8.11,
12.50,
9.83,
15.33,
8.16,
14.43,
14.80,
7.29,
7.17,
8.61,
8.67,
9.89,
17.20,
6.06,
7.00,
9.42,
5.89,
15.56,
4.77,
7.47,
8.67,
11.72,
11.88,
8.19,
12.85,
7.58,
11.32,
12.00,
14.00,
10.24,
11.37,
11.50,
10.81,
10.32,
12.39,
11.71,
13.69,
8.84,
11.89,
11.94,
10.84,
13.44,
6.17,
13.95,
14.65,
14.05,
12.69,
13.60,
15.86,
13.00,
13.62,
17.20,
13.65,
14.72,
13.37,
10.06,
9.33,
13.56,
12.94,
9.83,
13.75,
10.56,
14.56,
13.53,
11.56,
6.94,
13.21,
8.56,
12.00,
10.17,
9.00,
11.94,
9.62,
12.33,
15.12,
12.05,
12.59,
12.39,
7.63,
8.47,
13.72,
10.25,
9.68,
8.15,
14.30,
11.74,
13.22,
13.79,
7.28,
12.72,
11.82,
13.60,
9.33,
12.45,
16.47,
10.79,
11.00,
11.00,
9.58,
12.12,
10.83,
12.58,
6.84,
9.37,
11.94,
8.57,
9.27,
8.44,
13.11,
14.94,
8.44,
9.05,
11.74,
10.72,
10.16,
12.44,
0.00,
12.61,
9.06,
12.57,
11.60,
11.80,
13.37,
14.18,
6.94,
12.58,
10.95,
11.75,
7.85,
9.06,
10.37,
9.06,
13.56,
10.79,
12.56,
13.50,
14.35,
7.28,
13.75,
7.59,
7.84,
14.91,
10.22,
10.56,
6.58,
9.63,
8.56,
11.21,
11.42,
12.16,
13.48,
11.94,
9.32,
8.39,
9.50,
13.41,
13.67,
7.11,
10.33,
10.53,
10.78,
14.38,
15.88,
9.16,
9.33,
12.15,
9.44,
14.50,
9.72,
12.30,
14.00,
11.26,
12.62,
11.16,
11.22,
10.05,
12.74,
12.83,
10.33,
10.68,
11.83,
12.32,
12.06,
9.10,
11.44,
14.05,
12.79,
11.44,
12.16,
14.82,
14.14,
12.18,
12.29,
10.95,
9.70,
13.24,
12.17,
10.11,
10.36,
10.38,
12.56,
9.64,
16.67,
14.88,
11.50,
10.81,
11.11,
10.62,
12.00,
12.30,
12.06,
11.50,
10.39,
8.16,
8.35,
12.55,
12.08,
11.44,
11.28,
13.28,
15.31,
7.42,
8.79,
8.45,
8.95,
14.88,
13.40,
12.89,
9.33,
14.43,
10.50,
14.00,
13.80,
12.19,
14.44,
14.21,
12.60,
12.93,
10.48,
9.44,
11.78,
10.76,
10.19,
16.22,
14.54,
13.94,
7.44,
15.13,
12.60,
11.69,
10.67,
12.72,
13.00,
10.80,
13.94,
12.50,
13.15,
11.06,
12.11,
11.30,
12.06,
12.32,
11.80,
11.95,
14.07,
19.00,
9.45,
8.42,
11.21,
7.68,
10.61,
11.68,
14.75,
12.44,
12.00,
12.05,
12.25,
13.57,
13.61,
8.58,
11.00,
11.61,
14.15,
7.44,
13.11,
4.67,
11.20,
12.30,
16.18,
14.71,
10.14,
13.00,
13.13,
12.74,
11.00,
10.10,
10.88,
13.39,
9.39,
13.22,
12.90,
7.11,
12.50,
8.55,
11.05,
11.37,
7.39,
9.61,
11.22,
9.28,
9.58,
11.78,
12.57,
11.68,
12.06,
9.71,
10.70,
11.35,
9.33,
9.05,
10.78,
12.45,
13.00,
15.60,
9.58,
11.52,
15.88,
15.92,
16.00,
10.50,
12.78,
14.81,
11.79,
12.88,
12.83,
13.41,
14.56,
12.41,
12.39,
14.80,
9.89,
8.58,
11.56,
6.33,
8.02,
9.51,
6.95,
9.94,
15.20,
13.65,
11.50,
13.20,
12.59,
14.88,
11.81,
9.45,
10.20,
11.24,
13.28,
12.94,
8.26,
9.57,
7.89,
7.25,
11.67,
10.29,
9.42,
10.74,
9.05,
12.53,
9.35,
11.37,
13.89,
11.88,
13.00,
12.27,
14.43,
14.21,
13.11,
16.87,
12.50,
12.17,
9.67,
13.94,
14.86,
11.37,
12.15,
9.07,
12.11,
7.58,
12.00,
9.47,
8.00,
11.78,
10.44,
8.95,
10.42,
14.00,
12.20,
13.25,
14.28,
11.57,
13.75,
16.67,
10.06,
10.00,
10.32,
9.33,
11.94,
9.72,
11.95,
10.94,
10.70,
9.74,
14.67,
13.08,
11.88,
11.12,
12.07,
10.00,
12.61,
12.32,
9.95,
12.59,
6.72,
10.90,
11.80,
14.33,
10.78,
13.78,
12.29,
13.95,
13.67,
14.38,
16.50,
12.11,
8.72,
9.33,
10.42,
8.22,
9.50,
13.28,
16.35,
14.53,
15.00,
9.11,
10.95,
10.28,
10.00,
14.14,
12.05,
14.53,
10.68,
12.56,
12.35,
14.89,
14.33,
8.82,
15.70,
12.79,
15.55,
14.00,
11.70,
11.24,
11.39,
14.06,
11.00,
13.12,
11.06,
11.95,
9.84,
9.58,
16.87,
9.89,
14.59,
14.94,
12.70,
9.05,
9.68,
12.00,
12.41,
11.05,
9.75,
11.00,
10.42,
11.44,
8.72,
7.67,
14.61,
9.37,
9.53,
13.61,
15.00,
13.16,
15.54,
14.33,
10.95,
12.26,
9.79,
11.47,
11.21,
14.05,
13.78,
13.65,
11.95,
12.47,
7.78,
11.62,
8.17,
7.88,
13.33,
6.50,
11.28,
12.22,
10.90,
11.44,
14.80,
10.58,
11.82,
13.00,
12.28,
13.65,
12.44,
13.33,
13.47,
12.42,
12.00,
12.07,
8.37,
12.78,
12.94,
10.72,
8.50,
7.44,
13.06,
8.21,
12.19,
11.59,
10.00,
13.19,
14.06,
8.35,
11.06,
14.06,
13.73,
10.65,
12.00,
10.05,
7.53,
13.68,
11.39,
14.35,
10.44,
11.53,
11.76,
11.72,
14.39,
15.31,
13.11,
13.16,
14.06,
9.21,
12.89,
10.16,
13.12,
13.22,
10.89,
10.63,
12.32,
15.62,
12.63,
11.39,
14.40,
13.22,
10.53,
10.28,
9.85,
17.33,
13.50,
14.43,
12.47,
9.37,
11.25,
10.83,
13.71,
13.63,
13.62,
13.50,
14.06,
12.81,
15.15,
7.26,
7.44,
10.32,
12.16,
11.17,
18.50,
13.25,
12.84,
14.56,
13.00,
13.69,
13.93,
15.06,
12.56,
11.82,
14.56,
12.95,
10.17,
9.40,
9.57,
12.10,
9.26,
15.11,
13.00,
12.00,
11.61,
13.41,
13.42,
13.43,
7.79,
10.89,
11.72,
12.00,
10.39,
10.61,
12.72,
8.53,
11.31,
12.65,
12.76,
11.00,
10.85,
8.78,
11.28,
7.58,
14.91,
14.80,
14.00,
14.00,
12.28,
11.00,
12.00,
7.16,
13.33,
4.22,
7.22,
6.95,
10.37,
10.71,
3.37,
5.10,
7.42,
9.44,
4.58,
11.18,
6.11,
10.27,
7.90,
9.11,
9.94,
9.75,
8.25,
14.14,
8.28,
8.00,
8.56,
10.78,
10.89,
11.84,
11.05,
12.85,
5.74,
4.94,
15.36,
9.44,
8.17,
8.72,
14.60,
10.28,
7.95,
10.65,
9.58,
11.59,
16.60,
12.40,
11.07,
15.43,
13.00,
13.80,
15.38,
6.06,
10.38,
6.42,
13.09,
13.65,
12.33,
10.89,
15.80,
15.73,
9.06,
6.00,
14.62,
11.78,
14.11,
14.00,
16.88,
10.47,
13.43,
14.67,
10.47,
13.25,
7.63,
14.88,
12.92,
13.88,
9.05,
8.44,
11.67,
7.78,
14.22,
4.61,
9.00,
6.40,
5.16,
13.27,
12.00,
15.21,
10.79,
12.50,
5.21,
12.77,
11.74,
6.42,
5.17,
5.32,
8.05,
13.36,
6.84,
9.11,
6.95,
9.47,
9.26,
8.84,
11.93,
11.36,
12.53,
12.71,
11.39,
13.53,
11.11,
10.84,
11.55,
14.15,
15.53,
11.56,
10.00,
12.71,
16.75,
11.17,
13.33,
14.44,
8.25,
5.37,
9.83,
8.70,
11.51,
9.53,
8.72,
13.35,
13.42,
13.50,
11.85,
10.67,
13.32,
10.56,
9.11,
11.44,
14.00,
14.32,
11.44,
9.47,
10.18,
11.72,
11.50,
12.28,
11.17,
12.50,
10.56,
11.47,
12.64,
10.67,
13.33,
15.75,
12.39,
16.57,
19.50,
12.33,
16.40,
17.00,
14.83,
12.93,
10.83,
12.00,
11.11,
12.05,
9.22,
14.16,
12.41,
11.82,
10.94,
12.06,
10.65,
13.11,
5.67,
10.27,
9.88,
9.21,
4.17,
8.84,
10.95,
11.82,
7.54,
7.53,
13.67,
13.92,
15.00,
8.50,
18.00,
13.57,
6.00,
12.00,
7.71,
14.47,
10.50,
16.00,
3.74,
16.00,
8.11,
4.32,
10.85,
13.29,
15.14,
10.63,
11.91,
9.88,
11.65,
9.42,
9.95,
9.33,
2.61,
8.72,
6.26,
11.63,
7.11,
12.84,
14.24,
12.62,
14.06,
14.13,
11.33,
12.10,
13.20,
12.79,
10.83,
12.11,
11.89,
16.40,
13.45,
12.47,
16.57,
12.93,
14.94,
12.67,
12.94,
13.56,
13.39,
13.76,
11.67,
10.61,
14.62,
11.70,
15.19,
14.67,
12.20,
14.20,
14.67,
15.20,
14.25,
11.74,
14.24,
15.00,
16.50,
16.00,
14.29,
13.22,
11.88,
9.16,
7.05,
11.12,
8.00,
10.06,
8.90,
12.06,
13.00,
5.75,
12.47,
11.37,
11.78,
7.25,
8.42,
9.79,
11.33,
13.59,
7.34,
8.39,
8.65,
12.87,
7.72,
9.00,
12.79,
13.50,
9.48,
12.15,
12.32,
9.95,
15.00,
4.95,
9.42,
12.13,
14.56,
14.82,
14.23,
15.77,
15.73,
9.63,
12.40,
11.30,
14.67,
5.42,
10.89,
9.32,
11.32,
14.75,
12.65,
12.16,
12.25,
12.07,
15.13,
13.67,
3.94,
7.62,
11.06,
8.84,
7.42,
4.55,
10.78,
6.74,
9.38,
11.44,
13.53,
7.56,
12.92,
10.71,
11.84,
9.38,
8.67,
11.50,
12.88,
9.64,
9.11,
14.43,
16.50,
17.43,
11.67,
11.07,
6.68,
16.00,
14.75,
9.17,
5.28,
14.57,
8.00,
9.00,
10.83,
10.06,
6.33,
11.33,
4.90,
7.26,
7.42,
11.90,
9.35,
5.22,
5.83,
10.30,
8.80,
11.06,
9.45,
11.54,
13.37,
10.32,
11.00,
8.50,
15.22,
6.45,
6.78,
12.06,
15.54,
17.00,
10.11,
11.25,
15.50,
9.37,
12.69,
6.21,
11.56,
6.50,
10.67,
14.50,
7.72,
13.31,
12.83,
6.11,
9.00,
13.57,
13.69,
13.13,
7.06,
10.14,
8.11,
9.17,
12.48,
9.56,
12.62,
10.73,
8.74,
3.89,
7.47,
7.47,
8.89,
9.35,
3.20,
9.10,
7.95,
8.83,
5.22,
8.10,
7.74,
6.89,
5.95,
10.40,
8.72,
11.17,
6.11,
7.79,
7.10,
7.79,
12.26,
13.94,
8.74,
8.32,
6.84,
7.32,
13.29,
13.06,
12.10,
13.36,
14.80,
12.37,
13.11,
9.42,
13.19,
9.73,
12.16,
15.18,
14.71,
13.65,
9.17,
5.89,
8.00,
11.12,
8.65,
7.44,
11.80,
11.43,
14.19,
12.41,
12.00,
15.07,
10.89,
13.83,
15.14,
17.89,
13.82,
7.84,
11.64,
10.35,
12.06,
11.36,
12.71,
15.07,
17.67,
11.43,
11.50,
7.56,
10.00,
6.05,
13.65,
8.62,
7.72,
14.80,
6.76,
7.67,
6.78,
12.89,
11.56,
13.53,
14.00,
14.83,
15.63,
8.78,
11.38,
10.28,
9.11,
11.17,
15.67,
8.50,
8.68,
14.83,
9.14,
7.95,
8.83,
9.33,
9.00,
7.22,
9.80,
8.79,
6.39,
7.33,
14.08,
10.21,
8.39,
10.20,
9.71,
11.83,
7.14,
7.61,
10.87,
11.33,
11.19,
9.00,
9.89,
10.64,
5.11,
11.19,
10.15,
10.69,
12.80,
16.00,
7.84,
6.33,
9.95,
13.33,
12.44,
7.63,
11.13,
11.06,
9.08,
4.74,
11.10,
12.26,
9.88,
10.67,
12.14,
13.22,
13.79,
10.16,
12.22,
13.62,
12.71,
12.93,
10.17,
12.00,
8.50,
11.10,
12.73,
6.61,
10.63,
11.12,
10.75,
7.21,
8.94,
9.32,
11.80,
8.50,
12.79,
17.25,
8.17,
5.74,
8.72,
7.14,
7.63,
10.64,
7.80,
10.68,
14.42,
16.09,
9.05,
9.20,
15.55,
8.94,
9.00,
6.88,
11.11,
11.37,
13.69,
7.63,
8.89,
17.44,
9.33,
10.26,
11.62,
5.89,
6.68,
7.61,
8.53,
6.39,
11.85,
7.71,
5.63,
7.85,
8.39,
8.79,
5.44,
10.45,
12.56,
7.58,
8.50,
5.95,
10.38,
7.63,
7.21,
12.11,
2.78,
6.35,
16.67,
16.12,
14.67,
15.07,
13.40,
10.68,
11.78,
14.21,
13.75,
14.22,
14.43,
14.73,
14.50,
15.44,
14.76,
14.00,
7.78,
12.58,
12.00,
11.82,
15.44,
14.00,
5.40,
13.33,
6.06,
6.50,
7.32,
17.00,
11.33,
11.20,
13.11,
13.21,
14.23,
15.14,
13.42,
14.38,
6.61,
5.72,
10.85,
11.00,
6.16,
12.78,
11.57,
10.89,
8.44,
9.84,
15.86,
11.67,
10.84,
15.79,
13.81,
12.11,
13.50,
13.25,
12.81,
15.61,
10.65,
10.63,
13.40,
15.50,
12.39,
11.63,
13.21,
10.56,
9.52,
11.00,
13.65,
10.33,
10.53,
14.50,
12.85,
13.00,
12.95,
14.58,
12.13,
14.00,
16.29,
10.84,
13.06,
13.74,
3.57,
6.39,
4.11,
6.40,
12.40,
17.43,
14.60,
10.70,
15.67,
15.67,
13.60,
11.68,
15.00,
6.81,
10.72,
10.82,
13.12,
10.17,
11.17,
10.00,
9.58,
11.24,
17.33,
10.26,
15.17,
11.46,
8.33,
6.64,
16.22,
6.39,
7.62,
14.56,
9.11,
12.88,
12.31,
9.21,
8.94,
8.22,
10.10,
10.35,
13.00,
13.28,
7.47,
7.32,
12.16,
13.56,
12.14,
13.41,
12.88,
14.79,
4.95,
8.79,
12.73,
13.00,
7.52,
9.11,
6.67,
9.22,
5.53,
8.83,
12.69,
14.83,
6.79,
12.60,
8.50,
11.47,
8.05,
12.39,
9.50,
8.36,
11.16,
7.21,
12.16,
10.56,
12.50,
9.22,
4.43,
14.67,
12.41,
12.26,
4.26,
7.39,
7.39,
11.82,
4.70,
11.81,
9.89,
11.76,
8.83,
6.94,
7.95,
10.76,
11.60,
12.50,
12.88,
9.86,
11.44,
16.00,
14.00,
17.09,
16.25,
16.08,
15.33,
15.24,
14.42,
15.25,
7.50,
11.06,
12.20,
13.44,
8.56,
13.75,
9.58,
14.29,
16.43,
12.00,
9.63,
10.56,
9.94,
8.13,
14.62,
13.07,
13.44,
13.25,
16.14,
11.81,
14.12,
19.43,
12.69,
14.50,
11.71,
15.67,
15.54,
6.72,
10.21,
13.73,
14.25,
14.33,
9.17,
14.08,
16.50,
2.58,
2.56,
13.20,
5.63,
12.26,
11.43,
8.44,
16.70,
6.00,
14.00,
10.94,
8.76,
15.47,
6.85,
9.30,
13.14,
5.55,
10.34,
14.50,
6.06,
6.58,
7.20,
14.00,
11.79,
8.30,
12.24,
8.94,
7.67,
10.21,
9.50,
12.53,
8.47,
7.83,
16.43,
4.63,
10.00,
6.00,
4.09,
5.11,
11.50,
13.91,
10.74,
7.94,
14.93,
4.61,
5.60,
9.05,
6.89,
9.38,
12.37,
15.50,
18.25,
12.08,
10.84,
6.63,
8.16,
9.14,
12.33,
9.11,
3.74,
6.85,
6.74,
11.06,
10.82,
4.83,
11.67,
12.70,
5.79,
8.00,
10.50,
18.00,
7.38,
8.44,
11.60,
7.03,
7.74,
9.21,
12.30,
9.26,
12.53,
10.42,
15.50,
14.20,
12.47,
5.84,
11.78,
13.00,
11.35,
14.80,
10.17,
4.95,
8.62,
12.13,
11.56,
13.80,
14.86,
10.31,
11.58,
7.00,
3.50,
9.67,
12.43,
8.10,
6.74,
7.74,
7.21,
4.94,
9.94,
10.80,
5.10,
10.00,
7.79,
10.24,
9.19,
11.28,
12.08,
11.93,
11.83,
11.56,
12.45,
5.17,
10.40,
10.25,
10.69,
12.82,
12.87,
9.56,
10.80,
11.89,
6.11,
15.36,
11.10,
6.83,
8.55,
10.95,
7.47,
6.00,
9.39,
11.21,
13.78,
5.55,
8.41,
14.27,
5.37,
9.47,
9.62,
10.94,
12.42,
12.24,
14.36,
14.00,
13.93,
11.11,
9.56,
10.95,
15.15,
16.31,
15.46,
13.68,
17.33,
14.17,
14.26,
12.25,
10.74,
18.00,
13.11,
14.60,
11.22,
10.44,
12.29,
13.17,
14.40,
13.78,
10.58,
14.26,
10.16,
12.17,
12.00,
10.16,
11.67,
9.35,
13.64,
14.53,
10.33,
10.68,
13.09,
12.87,
8.79,
9.44,
10.00,
10.83,
9.93,
10.95,
12.94,
10.50,
8.68,
11.06,
13.19,
11.47,
5.01,
9.00,
7.47,
14.26,
10.00,
10.83,
12.30,
7.20,
8.79,
14.00,
17.00,
12.60,
9.21,
12.67,
13.41,
12.46,
10.84,
7.57,
11.44,
13.06,
6.45,
12.20,
11.40,
14.00,
10.74,
12.50,
15.08,
9.74,
13.93,
13.53,
10.68,
11.11,
12.78,
15.56,
13.53,
10.48,
13.75,
12.80,
14.24,
12.29,
12.91,
11.33,
13.16,
15.31,
11.37,
13.05,
12.53,
10.89,
7.00,
5.91,
5.94,
10.10,
10.25,
13.17,
13.90,
15.12,
13.27,
9.44,
8.56,
11.32,
11.40,
15.18,
12.06,
14.50,
16.00,
9.21,
9.56,
13.00,
10.84,
13.79,
10.89,
12.47,
9.72,
9.21,
10.28,
5.47,
9.21,
8.00,
10.00,
5.17,
12.15,
14.85,
12.32,
12.56,
16.33,
13.73,
15.94,
12.05,
10.85,
8.74,
14.06,
14.36,
13.20,
12.72,
10.26,
13.00,
10.11,
13.17,
7.84,
8.16,
11.42,
9.05,
13.20,
8.80,
9.45,
12.47,
10.80,
9.89,
9.78,
11.88,
12.88,
16.29,
14.40,
13.88,
12.35,
10.45,
14.65,
14.32,
15.10,
12.33,
11.61,
12.89,
14.00,
12.50,
14.40,
14.44,
8.53,
8.44,
7.29,
8.39,
12.61,
8.78,
11.26,
10.06,
11.56,
11.58,
13.70,
16.45,
14.13,
15.06,
11.26,
13.50,
7.65,
10.26,
12.39,
15.29,
14.36,
14.86,
7.80,
10.57,
11.13,
11.65,
11.67,
12.10,
9.78,
13.81,
13.45,
14.56,
13.35,
11.84,
13.00,
11.50,
10.35,
18.00,
13.80,
13.00,
10.53,
9.37,
13.00,
11.58,
16.05,
13.00,
12.33,
11.50,
13.20,
8.78,
15.50,
13.00,
12.71,
12.61,
13.32,
7.78,
17.00,
11.32,
14.00,
13.67,
8.84,
11.40,
13.79,
10.47,
8.20,
12.06,
6.50,
8.94,
8.68,
8.11,
9.72,
12.73,
16.00,
13.26,
11.00,
10.79,
12.61,
6.63,
10.00,
11.88,
8.11,
7.68,
7.76,
8.50,
12.79,
14.22,
11.11,
13.28,
13.68,
9.95,
12.41,
12.94,
9.79,
11.50,
12.56,
16.38,
14.18,
7.83,
10.78,
13.92,
12.05,
13.11,
11.61,
12.56,
15.25,
11.61,
12.32,
11.71,
13.29,
15.42,
16.57,
12.11,
10.67,
15.09,
15.62,
11.21,
13.22,
10.17,
11.21,
10.22,
12.64,
12.65,
9.11,
9.05,
7.16,
13.27,
13.46,
10.32,
13.62,
13.00,
14.00,
14.33,
13.75,
13.57,
9.05,
10.11,
11.63,
9.30,
15.00,
13.88,
13.83,
12.89,
15.50,
12.56,
10.80,
14.33,
12.78,
14.92,
13.41,
5.70,
15.31,
13.88,
12.33,
12.55,
10.05,
9.45,
14.17,
13.40,
8.22,
8.00,
14.36,
10.56,
10.95,
11.17,
11.24,
15.00,
12.16,
10.20,
11.78,
16.00,
10.79,
7.33,
7.21,
15.14,
15.43,
11.33,
5.22,
8.81,
15.36,
10.16,
15.00,
7.80,
8.83,
11.48,
12.11,
15.47,
9.89,
9.84,
12.61,
9.78,
8.50,
10.05,
8.78,
10.67,
10.68,
11.00,
9.32,
11.50,
11.44,
14.45,
10.92,
12.53,
14.22,
14.24,
12.40,
12.44,
13.50,
12.29,
14.54,
13.27,
12.33,
12.65,
13.95,
14.33,
13.56,
15.28,
8.65,
13.59,
13.65,
13.18,
9.11,
13.62,
10.88,
13.37,
13.79,
15.00,
13.37,
15.19,
15.20,
9.75,
12.28,
9.47,
11.74,
10.71,
11.89,
10.25,
13.68,
12.29,
11.17,
9.37,
16.33,
15.64,
8.00,
9.72,
17.64,
14.64,
12.00,
6.45,
17.17,
10.67,
12.00,
14.32,
10.45,
15.00,
14.38,
14.33,
12.50,
13.38,
14.06,
14.22,
14.44,
12.00,
13.93,
14.15,
14.93,
13.00,
12.62,
12.30,
16.75,
13.94,
12.57,
13.35,
13.12,
12.82,
14.13,
16.15,
13.50,
9.19,
9.61,
9.84,
7.67,
8.80,
7.94,
11.22,
12.26,
13.85,
13.88,
11.95,
12.29,
11.45,
15.53,
13.69,
8.35,
10.76,
12.33,
7.94,
7.67,
15.33,
15.14,
12.69,
13.64,
10.05,
11.60,
11.44,
11.82,
12.20,
14.88,
9.05,
12.10,
11.00,
9.95,
12.94,
13.33,
5.56,
11.47,
8.85,
12.06,
12.06,
12.79,
9.56,
12.79,
12.79,
9.22,
9.88,
13.31,
11.28,
10.89,
12.38,
9.11,
15.62,
13.93,
14.62,
13.50,
14.00,
5.61,
13.94,
12.33,
8.56,
12.50,
10.21,
11.43,
13.55,
6.70,
7.74,
8.81,
10.21,
7.62,
8.05,
10.11,
14.17,
9.22,
9.94,
12.53,
9.79,
7.00,
10.05,
12.28,
13.80,
13.05,
9.53,
11.89,
10.33,
6.58,
9.42,
15.44,
10.16,
12.24,
7.33,
11.78,
10.76,
12.65,
11.81,
8.94,
12.87,
8.89,
13.71,
13.41,
13.00,
14.12,
11.78,
10.47,
13.00,
12.82,
10.94,
14.23,
10.06,
12.74,
15.58,
10.63,
9.11,
10.56,
16.34,
14.67,
13.69,
14.61,
12.84,
13.00,
13.41,
12.16,
13.29,
12.86,
10.33,
16.14,
12.19,
12.73,
12.67,
9.80,
11.06,
10.83,
11.25,
7.28,
10.28,
8.63,
9.63,
14.59,
13.94,
14.12,
13.11,
12.53,
16.50,
8.06,
5.00,
9.83,
13.21,
8.72,
10.37,
11.08,
12.38,
14.64,
9.79,
11.89,
10.75,
12.19,
13.86,
13.36,
13.43,
9.58,
9.55,
12.44,
11.78,
11.33,
10.18,
10.11,
10.44,
11.24,
7.37,
13.67,
8.68,
12.33,
10.17,
11.28,
14.71,
14.09,
16.38,
18.33,
17.75,
10.74,
11.17,
12.72,
11.75,
16.88,
16.27,
16.56,
11.00,
10.72,
12.33,
12.90,
16.53,
8.00,
9.63,
6.68,
11.50,
16.18,
13.93,
14.64,
15.30,
11.15,
15.50,
11.00,
13.00,
14.70,
14.00,
10.44,
12.90,
6.47,
10.18,
14.50,
3.40,
16.27,
15.22,
15.00,
12.58,
15.44,
14.09,
7.72,
14.90,
16.64,
15.33,
15.33,
14.18,
15.27,
15.67,
16.83,
10.62,
12.75,
7.56,
6.37,
9.92,
12.65,
16.10,
15.23,
15.47,
10.89,
15.70,
10.84,
10.26,
12.11,
10.60,
12.56,
11.72,
13.06,
7.28,
13.60,
16.43,
16.90,
15.33,
9.80,
9.56,
15.20,
4.53,
12.00,
14.23,
11.67,
9.25,
11.36,
12.93,
16.38,
19.50,
12.35,
10.62,
9.00,
7.35,
5.50,
12.37,
11.63,
12.76,
11.10,
5.85,
6.83,
13.19,
14.08,
11.17,
13.67,
12.78,
13.40,
4.19,
9.00,
9.47,
10.26,
10.44,
8.12,
9.26,
11.42,
15.80,
13.95,
15.76,
14.94,
9.02,
10.89,
10.47,
12.24,
9.58,
14.61,
14.89,
15.50,
12.47,
14.33,
11.82,
12.62,
14.62,
12.95,
14.47,
14.53,
15.17,
15.41,
10.72,
16.08,
11.95,
11.78,
8.20,
12.11,
12.95,
7.06,
5.74,
10.26,
11.89,
16.57,
10.95,
13.47,
14.50,
14.00,
15.65,
14.67,
9.76,
7.84,
10.00,
9.69,
9.89,
11.82,
9.00,
15.08,
8.95,
6.83,
12.62,
9.41,
4.26,
10.54,
10.67,
12.22,
11.29,
11.64,
8.65,
6.80,
8.65,
3.99,
4.05,
8.90,
11.20,
4.91,
9.75,
14.00,
11.07,
11.43,
11.15,
12.79,
11.40,
17.62,
7.00,
16.15,
11.00,
19.33,
15.33,
9.33,
10.89,
11.05,
12.21,
14.73,
7.72,
8.69,
13.62,
16.10,
10.78,
17.25,
15.88,
11.63,
9.68,
6.68,
8.50,
12.88,
13.88,
11.00,
8.50,
16.08,
13.81,
12.33,
9.57,
10.62,
14.25,
13.95,
14.17,
12.00,
14.30,
3.83,
5.37,
4.55,
9.33,
9.05,
7.67,
13.13,
10.39,
10.67,
13.06,
13.19,
8.37,
8.44,
8.48,
11.19,
5.37,
9.00,
7.00,
6.95,
8.72,
8.32,
8.89,
8.05,
8.72,
10.11,
13.31,
12.38,
12.40,
18.00,
9.79,
13.67,
8.22,
9.00,
13.81,
11.05,
14.06,
13.64,
10.68,
9.50,
10.06,
14.14,
11.41,
13.95,
10.00,
10.42,
15.33,
12.79,
13.44,
12.56,
11.56,
13.61,
10.74,
8.16,
11.73,
12.71,
9.00,
7.70,
8.22,
12.89,
13.90,
11.72,
14.47,
12.44,
10.53,
11.00,
11.11,
13.16,
14.00,
9.53,
14.12,
13.50,
7.89,
10.65,
11.86,
11.32,
13.08,
11.95,
11.63,
12.41,
13.16,
14.21,
14.80,
9.50,
9.32,
10.22,
9.11,
12.40,
12.06,
11.63,
7.61,
8.11,
7.84,
11.10,
13.28,
11.89,
13.89,
11.61,
12.67,
13.78,
10.78,
11.37,
10.89,
11.59,
9.41,
12.68,
9.53,
7.89,
11.53,
7.40,
13.44,
12.89,
7.55,
9.89,
13.05,
16.60,
12.17,
13.20,
12.17,
13.90,
14.50,
13.17,
11.61,
12.21,
16.12,
15.73,
16.61,
12.58,
12.84,
12.44,
14.25,
13.69,
13.94,
11.28,
12.60,
14.10,
8.22,
9.10,
11.06,
8.67,
9.06,
7.68,
7.80,
6.06,
10.37,
4.89,
14.50,
11.63,
11.22,
14.00,
5.68,
6.85,
11.53,
10.47,
9.50,
6.33,
5.86,
9.45,
7.16,
6.37,
6.39,
13.79,
13.95,
15.76,
12.71,
10.63,
10.74,
10.84,
12.82,
15.38,
12.11,
13.39,
11.42,
11.56,
11.12,
13.33,
9.00,
12.40,
13.56,
15.43,
12.84,
13.16,
11.67,
11.72,
7.47,
11.82,
5.42,
10.95,
10.05,
11.05,
10.58,
10.95,
10.56,
10.89,
10.90,
13.00,
11.63,
8.15,
10.00,
13.33,
12.10,
12.13,
9.79,
7.84,
6.63,
6.61,
15.78,
8.78,
9.56,
9.63,
11.11,
10.89,
12.21,
13.22,
11.11,
10.78,
9.89,
11.61,
14.88,
13.69,
13.35,
16.00,
12.50,
14.07,
13.39,
11.00,
12.67,
11.39,
11.89,
13.50,
11.93,
9.50,
7.94,
10.84,
12.11,
13.00,
15.23,
14.67,
11.10,
12.65,
7.72,
8.06,
8.95,
10.11,
10.75,
8.50,
12.12,
12.24,
13.80,
13.25,
13.56,
13.70,
14.06,
10.21,
15.08,
12.05,
9.72,
8.83,
13.64,
13.50,
15.58,
10.35,
13.57,
11.95,
13.95,
12.62,
8.78,
7.06,
12.61,
14.47,
9.17,
9.22,
9.44,
8.79,
10.95,
11.11,
9.95,
9.41,
11.75,
10.11,
11.56,
12.00,
11.53,
13.44,
13.33,
15.64,
13.57,
15.75,
11.44,
14.47,
14.73,
13.72,
16.64,
13.29,
12.30,
13.33,
13.80,
15.42,
13.22,
13.50,
13.56,
12.88,
14.76,
13.40,
13.10,
9.20,
12.82,
14.69,
13.18,
13.00,
15.10,
6.81,
8.95,
12.39,
10.11,
13.83,
11.12,
12.88,
12.86,
13.93,
13.92,
13.18,
14.59,
9.61,
12.75,
14.61,
10.50,
10.35,
10.39,
11.24,
11.28,
11.70,
10.78,
11.58,
11.22,
10.32,
11.47,
8.72,
11.35,
9.58,
11.00,
11.16,
10.32,
12.43,
10.32,
11.42,
11.00,
11.56,
13.26,
9.79,
10.10,
11.62,
8.22,
9.33,
9.72,
8.63,
13.88,
12.55,
12.17,
10.95,
12.20,
6.22,
14.20,
13.53,
10.88,
9.30,
10.27,
7.88,
9.21,
14.88,
15.50,
13.58,
11.44,
7.21,
6.89,
12.59,
14.79,
13.71,
12.00,
13.11,
13.10,
12.58,
15.07,
10.79,
11.33,
14.22,
16.10,
16.70,
13.86,
14.00,
14.25,
7.16,
12.79,
9.72,
8.44,
12.23,
10.16,
11.12,
10.41,
10.58,
8.72,
7.45,
10.44,
13.18,
15.12,
8.90,
11.63,
10.53,
10.07,
12.65,
12.64,
10.35,
8.06,
5.50,
8.68,
3.60,
9.67,
12.00,
8.53,
15.40,
12.39,
14.82,
8.22,
12.64,
13.00,
12.19,
8.67,
8.37,
11.38,
12.00,
4.60,
13.28,
13.78,
16.38,
15.56,
11.89,
16.28,
9.86,
13.00,
11.56,
11.90,
11.90,
14.17,
8.75,
11.91,
7.75,
9.89,
13.93,
8.12,
7.60,
12.29,
14.50,
2.80,
6.05,
13.00,
8.62,
8.94,
9.50,
12.43,
3.68,
6.74,
8.38,
12.89,
13.87,
7.71,
11.44,
9.08,
11.38,
5.89,
12.94,
11.93,
9.35,
3.68,
5.06,
4.86,
9.80,
14.53,
13.67,
6.05,
11.23,
11.72,
8.44,
10.55,
11.39,
12.81,
13.00,
13.27,
15.86,
10.11,
11.11,
8.89,
11.79,
10.33,
16.00,
10.31,
12.44,
6.17,
7.70,
11.65,
8.20,
9.33,
6.00,
14.22,
10.06,
9.47,
6.12,
8.14,
10.40,
8.65,
7.58,
9.42,
12.78,
11.11,
3.05,
5.05,
8.39,
8.06,
10.30,
4.70,
7.06,
9.32,
7.21,
8.14,
7.56,
6.11,
6.61,
6.55,
9.27,
15.47,
9.44,
14.12,
8.82,
15.67,
6.58,
6.90,
9.89,
12.38,
10.17,
14.08,
11.22,
7.75,
13.00,
8.05,
10.10,
15.00,
14.38,
14.00,
4.33,
12.57,
14.30,
11.47,
11.60,
6.00,
11.78,
14.40,
8.07,
5.58,
11.06,
13.00,
13.24,
12.86,
11.77,
8.79,
9.44,
8.33,
13.39,
6.63,
8.47,
5.42,
13.14,
16.75,
12.56,
14.21,
6.63,
12.21,
15.00,
11.93,
10.24,
11.00,
13.43,
4.93,
11.79,
10.61,
16.44,
11.35,
11.47,
9.06,
11.61,
5.86,
16.29,
13.56,
13.67,
9.89,
7.33,
11.39,
8.41,
12.74,
9.37,
12.33,
3.91,
10.92,
12.00,
12.70,
6.42,
12.25,
8.50,
7.84,
11.19,
9.74,
14.59,
11.00,
12.80,
16.33,
14.56,
14.33,
8.00,
6.89,
9.06,
11.60,
11.11,
12.09,
12.00,
16.50,
14.50,
7.58,
11.22,
13.78,
8.83,
8.84,
9.82,
12.45,
10.50,
5.58,
6.80,
12.00,
12.00,
6.26,
10.84,
7.00,
9.11,
12.07,
13.13,
7.39,
8.72,
8.67,
11.89,
9.74,
11.39,
9.11,
12.00,
10.78,
8.60,
12.53,
14.55,
13.80,
4.11,
9.63,
9.06,
5.37,
6.05,
4.83,
11.17,
9.72,
14.60,
9.44,
10.50,
6.78,
17.20,
4.88,
10.25,
7.25,
7.29,
10.80,
7.59,
9.00,
8.75,
11.92,
8.42,
12.50,
14.20,
13.59,
13.48,
9.35,
4.05,
4.28,
5.68,
9.30,
8.79,
7.61,
5.41,
11.14,
6.50,
8.24,
8.89,
10.33,
9.53,
7.14,
10.67,
14.00,
3.47,
7.64,
8.53,
5.89,
10.86,
6.78,
11.78,
5.26,
12.80,
4.95,
5.78,
7.56,
14.12,
13.08,
16.50,
12.33,
10.11,
6.11,
10.12,
8.21,
6.94,
3.26,
9.06,
9.33,
10.05,
8.56,
13.00,
18.50,
17.25,
7.94,
6.32,
13.67,
7.00,
11.50,
9.50,
8.00,
10.65,
15.12,
8.11,
11.90,
13.00,
7.53,
12.93,
16.50,
14.00,
4.63,
8.20,
6.05,
7.79,
9.26,
10.72,
9.42,
11.94,
11.60,
4.11,
13.64,
6.16,
8.94,
12.06,
8.63,
13.57,
12.69,
12.87,
11.06,
12.19,
11.83,
14.36,
9.85,
12.90,
3.50,
8.27,
4.95,
10.71,
13.20,
7.44,
10.74,
14.22,
14.69,
12.60,
8.55,
7.89,
11.55,
7.53,
9.45,
10.81,
16.14,
8.82,
10.11,
11.00,
8.68,
8.58,
7.89,
11.08,
15.17,
14.00,
8.05,
12.77,
8.90,
11.40,
14.50,
4.50,
7.56,
10.47,
7.58,
11.26,
11.47,
5.17,
6.58,
9.56,
8.50,
9.06,
8.28,
13.00,
9.21,
11.91,
9.65,
11.05,
12.47,
7.55,
12.25,
10.50,
6.58,
7.94,
13.86,
14.50,
14.40,
11.81,
11.05,
8.95,
12.11,
9.68,
13.83,
12.42,
10.05,
11.79,
10.32,
17.00,
14.00,
15.50,
6.80,
8.74,
8.88,
5.06,
9.28,
8.11,
10.93,
6.47,
11.58,
4.74,
13.27,
10.84,
7.15,
15.00,
7.65,
13.25,
11.06,
9.00,
10.78,
9.84,
9.05,
11.25,
11.83,
16.00,
7.56,
13.67,
12.00,
14.94,
7.11,
10.91,
12.32,
14.09,
13.93,
10.50,
13.63,
11.21,
11.47,
18.40,
12.32,
13.26,
13.12,
17.45,
16.40,
16.20,
12.33,
16.81,
12.80,
4.95,
8.50,
10.94,
5.83,
3.63,
6.06,
9.24,
7.95,
8.32,
10.59,
12.30,
9.33,
6.00,
5.31,
11.83,
7.00,
7.57,
10.84,
10.22,
11.94,
8.06,
9.45,
10.28,
7.58,
7.17,
10.48,
5.37,
11.94,
12.82,
14.17,
11.63,
9.38,
9.06,
7.53,
5.35,
9.11,
6.39,
10.71,
9.11,
12.56,
7.00,
11.69,
9.60,
8.44,
6.94,
9.72,
13.25,
8.61,
11.50,
10.33,
5.43,
9.40,
2.78,
8.93,
5.94,
10.61,
14.71,
12.40,
8.86,
9.45,
11.12,
11.38,
11.18,
16.50,
16.67,
12.60,
13.74,
13.79,
13.50,
8.00,
17.50,
16.19,
14.57,
10.15,
10.76,
12.83,
10.21,
13.39,
12.65,
12.72,
12.00,
11.56,
12.30,
12.32,
14.55,
12.56,
11.06,
12.17,
13.47,
13.26,
15.50,
15.06,
13.15,
14.56,
13.05,
16.50,
16.00,
13.44,
15.07,
19.20,
6.17,
12.29,
10.53,
12.78,
9.22,
11.00,
8.68,
11.17,
14.53,
14.44,
14.07,
12.87,
13.44,
9.57,
10.21,
8.42,
10.11,
13.70,
12.94,
12.47,
16.33,
7.84,
6.70,
8.26,
8.55,
15.33,
6.47,
9.37,
16.69,
13.20,
15.56,
9.26,
10.83,
10.12,
12.30,
11.89,
15.50,
13.47,
15.86,
14.00,
13.50,
14.63,
15.67,
16.36,
14.94,
9.67,
11.53,
13.76,
18.50,
15.73,
16.00,
8.16,
9.78,
12.86,
13.62,
13.50,
14.29,
13.27,
15.58,
3.89,
9.05,
7.69,
8.63,
11.61,
7.45,
8.30,
8.47,
7.11,
18.00,
10.89,
14.27,
15.62,
13.50,
13.75,
15.00,
11.95,
14.12,
13.68,
13.38,
13.00,
14.18,
14.55,
17.46,
13.75,
14.38,
13.00,
16.75,
5.36,
12.71,
6.05,
5.87,
14.33,
25.00,
6.28,
13.32,
13.18,
14.32,
14.05,
8.05,
10.53,
9.86,
13.50,
11.53,
13.63,
16.20,
8.85,
11.61,
11.89,
11.41,
4.78,
7.67,
11.28,
8.17,
13.00,
7.28,
14.53,
9.17,
12.11,
12.00,
13.80,
6.36,
8.10,
8.75,
6.60,
7.95,
10.89,
9.80,
12.50,
13.29,
13.00,
12.43,
14.53,
12.15,
8.94,
9.24,
13.11,
15.85,
14.94,
13.63,
12.94,
13.40,
13.89,
15.12,
16.38,
12.40,
7.30,
9.21,
17.38,
12.75,
12.16,
12.78,
14.54,
15.20,
9.61,
9.72,
12.62,
10.50,
12.12,
6.11,
4.80,
14.27,
9.11,
9.95,
5.47,
5.78,
4.32,
12.57,
14.26,
11.39,
10.84,
10.21,
11.44,
11.17,
12.39,
13.87,
13.86,
11.53,
7.05,
12.50,
14.41,
11.81,
9.06,
13.85,
16.17,
11.79,
10.67,
12.32,
11.37,
13.47,
11.71,
11.74,
6.53,
15.57,
16.15,
12.21,
12.06,
12.76,
15.33,
15.86,
11.52,
12.19,
16.00,
12.21,
12.15,
13.06,
13.67,
14.50,
16.44,
14.60,
12.76,
13.61,
11.53,
16.11,
12.95,
11.05,
14.62,
6.11,
7.83,
8.60,
6.28,
12.00,
12.53,
11.76,
13.22,
10.50,
12.21,
14.74,
13.24,
16.21,
10.32,
12.71,
13.45,
10.83,
14.27,
14.56,
14.07,
17.00,
11.33,
13.00,
7.05,
12.12,
15.44,
13.67,
11.00,
10.17,
13.19,
11.68,
17.18,
16.44,
12.44,
12.94,
14.92,
8.26,
9.76,
10.47,
5.94,
13.47,
16.22,
12.68,
10.86,
17.60,
15.75,
11.79,
11.42,
13.36,
14.59,
11.93,
12.53,
15.75,
12.64,
13.26,
10.33,
13.62,
18.11,
13.35,
6.37,
9.45,
14.88,
11.26,
11.68,
12.71,
11.36,
13.32,
14.11,
12.84,
14.32,
16.00,
7.44,
15.00,
10.72,
11.26,
12.38,
13.55,
8.44,
15.91,
14.29,
14.06,
16.00,
14.06,
11.41,
11.53,
7.84,
10.15,
9.50,
13.78,
12.10,
14.32,
9.61,
9.44,
9.50,
4.79,
15.22,
9.00,
12.05,
13.83,
13.29,
14.13,
13.75,
15.47,
10.42,
13.32,
14.26,
11.50,
10.82,
12.90,
12.44,
13.31,
12.76,
13.88,
10.37,
12.71,
18.00,
16.85,
15.82,
18.55,
18.40,
11.04,
14.86,
7.94,
6.95,
11.88,
9.21,
10.16,
12.30,
11.28,
9.78,
12.69,
14.29,
8.85,
9.72,
13.21,
13.00,
11.89,
10.84,
8.63,
8.50,
7.70,
9.94,
7.89,
13.60,
13.06,
13.94,
11.67,
13.16,
14.00,
14.93,
7.17,
9.47,
4.28,
9.37,
10.44,
11.94,
13.89,
12.16,
13.41,
15.50,
10.11,
5.25,
15.25,
8.89,
10.79,
16.54,
14.44,
16.62,
15.88,
13.67,
16.64,
15.18,
14.73,
15.65,
12.06,
12.22,
16.06,
14.31,
15.69,
11.72,
13.88,
15.69,
13.17,
13.61,
11.73,
10.68,
11.06,
10.53,
10.70,
11.21,
13.56,
7.26,
6.90,
9.44,
8.95,
15.00,
6.52,
14.90,
15.20,
13.69,
10.35,
9.11,
11.11,
9.83,
10.50,
16.73,
10.42,
8.89,
16.09,
6.28,
9.89,
12.06,
15.00,
14.83,
10.89,
10.11,
10.58,
11.56,
9.94,
9.95,
11.24,
11.95,
14.17,
14.00,
14.67,
13.15,
5.75,
8.44,
8.85,
15.88,
8.26,
9.50,
11.17,
12.17,
12.47,
11.33,
11.30,
11.35,
12.21,
13.38,
15.67,
15.92,
14.83,
12.81,
6.53,
8.95,
6.96,
12.20,
9.58,
12.12,
14.82,
10.89,
11.14,
10.30,
7.94,
9.31,
14.58,
14.77,
12.25,
12.29,
13.97,
13.71,
13.58,
9.58,
10.58,
11.22,
11.35,
12.11,
12.72,
11.11,
11.69,
11.00,
10.79,
14.85,
14.00,
5.67,
16.20,
12.89,
13.06,
12.67,
7.50,
7.85,
8.84,
7.39,
13.72,
14.21,
9.05,
8.78,
9.17,
12.47,
11.89,
7.22,
9.90,
12.37,
12.62,
9.50,
12.89,
12.11,
14.12,
14.86,
12.80,
12.06,
14.06,
12.17,
6.84,
10.37,
12.41,
11.11,
15.00,
13.25,
13.60,
15.00,
11.22,
12.16,
13.67,
15.42,
11.89,
7.00,
8.17,
11.18,
14.70,
12.72,
14.53,
11.64,
12.24,
9.28,
6.25,
12.53,
9.26,
9.85,
9.83,
13.50,
12.89,
13.87,
12.64,
11.72,
13.11,
8.37,
14.14,
13.77,
16.00,
14.06,
15.00,
16.75,
15.45,
13.75,
18.00,
14.82,
15.36,
14.47,
10.86,
14.17,
12.35,
17.17,
16.25,
11.05,
15.33,
15.60,
16.20,
12.12,
13.44,
17.43,
13.00,
15.67,
12.67,
12.39,
12.35,
12.41,
14.85,
13.74,
16.40,
14.40,
18.57,
7.83,
12.87,
16.22,
17.67,
12.62,
12.59,
17.25,
15.00,
17.00,
14.62,
13.88,
16.06,
13.13,
15.80,
12.12,
15.67,
13.79,
6.47,
10.27,
11.00,
10.06,
11.50,
12.61,
12.67,
10.67,
8.79,
8.59,
11.50,
14.57,
12.76,
14.67,
14.67,
12.72,
16.09,
12.67,
12.28,
15.00,
13.17,
10.82,
15.40,
7.50,
15.67,
13.84,
15.31,
15.53,
11.06,
10.26,
14.24,
14.62,
13.79,
11.14,
14.75,
13.67,
14.17,
8.50,
5.68,
4.95,
11.69,
16.67,
12.83,
14.00,
13.11,
10.89,
15.88,
15.38,
14.29,
14.23,
13.60,
10.60,
13.80,
10.17,
13.29,
10.32,
11.76,
12.63,
14.44,
14.62,
15.26,
13.06,
6.94,
14.18,
14.57,
15.00,
12.70,
15.77,
13.79,
6.21,
11.33,
17.67,
14.85,
13.10,
14.75,
8.26,
7.53,
11.58,
15.25,
15.86,
10.94,
11.33,
12.83,
7.11,
9.75,
11.16,
6.60,
10.58,
10.16,
13.77,
11.83,
15.60,
11.67,
17.00,
14.50,
16.00,
10.84,
18.00,
15.38,
17.00,
16.62,
7.12,
15.85,
11.06,
13.67,
10.42,
10.60,
15.00,
14.86,
13.26,
13.45,
12.93,
8.39,
11.90,
10.28,
10.63,
10.37,
10.84,
10.55,
13.11,
14.67,
8.78,
9.79,
11.51,
11.95,
12.44,
14.83,
14.13,
14.44,
8.06,
12.93,
10.58,
8.26,
8.60,
14.68,
13.44,
14.87,
10.21,
10.90,
11.06,
10.89,
10.44,
15.67,
15.00,
15.71,
14.80,
15.87,
17.58,
17.46,
9.50,
16.05,
17.43,
10.62,
15.67,
15.73,
12.67,
12.47,
15.25,
14.38,
14.53,
16.40,
15.90,
12.11,
12.82,
13.89,
0.00,
14.00,
13.28,
8.90,
9.56,
11.11,
12.28,
9.11,
11.37,
11.18,
12.94,
12.39,
10.94,
10.05,
10.39,
11.81,
12.00,
12.50,
13.12,
5.60,
6.37,
11.94,
5.84,
7.76,
7.81,
9.32,
17.38,
9.58,
12.74,
7.03,
12.92,
6.26,
13.23,
8.95,
8.20,
4.20,
4.88,
5.05,
10.64,
4.83,
7.61,
4.44,
8.05,
5.61,
10.65,
10.74,
12.61,
10.58,
12.55,
14.00,
14.13,
6.71,
8.33,
10.17,
8.50,
15.00,
14.40,
10.58,
14.85,
15.75,
13.05,
10.00,
12.89,
10.50,
13.33,
10.63,
13.32,
9.31,
8.74,
13.93,
16.65,
8.16,
11.33,
11.86,
15.25,
14.72,
7.49,
11.00,
9.53,
9.84,
9.70,
12.62,
12.86,
8.21,
8.00,
8.85,
11.95,
7.39,
10.17,
12.83,
11.81,
11.78,
10.80,
10.89,
13.00,
9.95,
11.56,
8.24,
7.37,
13.44,
6.48,
9.39,
9.50,
11.00,
9.58,
10.00,
8.35,
9.95,
16.75,
13.25,
13.33,
11.50,
9.79,
11.12,
14.08,
6.61,
6.21,
7.20,
12.67,
6.26,
8.94,
7.94,
12.21,
9.35,
10.39,
13.95,
10.16,
12.63,
13.75,
11.50,
14.00,
15.57,
12.11,
14.53,
12.80,
13.11,
11.22,
11.78,
12.62,
16.00,
15.43,
10.11,
14.50,
9.37,
6.33,
9.47,
11.86,
14.27,
15.07,
14.57,
13.06,
10.22,
9.05,
9.79,
11.65,
12.61,
14.50,
14.54,
13.06,
11.72,
13.11,
13.83,
6.17,
9.81,
11.35,
11.58,
14.40,
11.21,
13.20,
12.22,
8.42,
9.19,
7.61,
11.44,
9.11,
11.25,
14.75,
15.00,
14.21,
11.79,
11.78,
13.16,
12.50,
14.29,
13.76,
13.06,
14.62,
16.40,
11.00,
8.89,
11.61,
11.22,
14.94,
16.41,
14.94,
8.65,
6.68,
17.89,
15.25,
14.79,
10.38,
14.28,
12.36,
15.59,
12.06,
12.11,
11.47,
17.50,
13.22,
14.06,
13.64,
10.42,
14.39,
8.42,
11.44,
10.74,
11.94,
11.14,
16.33,
15.12,
16.92,
12.10,
11.11,
14.53,
8.70,
11.21,
11.18,
9.28,
10.32,
15.44,
15.22,
13.53,
13.47,
13.21,
14.00,
14.33,
10.17,
14.17,
12.56,
9.59,
13.06,
12.50,
14.00,
12.84,
10.72,
6.33,
8.95,
8.58,
10.21,
11.11,
10.59,
9.63,
10.61,
14.80,
13.60,
13.50,
9.21,
13.33,
7.68,
13.18,
6.50,
12.06,
8.32,
7.74,
10.50,
12.85,
12.25,
13.38,
11.67,
7.00,
10.53,
12.26,
12.53,
14.75,
15.11,
13.11,
14.74,
8.26,
12.89,
7.06,
7.11,
7.16,
9.19,
10.00,
10.89,
12.89,
13.79,
11.00,
11.58,
12.05,
13.00,
10.68,
14.05,
14.77,
13.75,
10.44,
12.88,
8.80,
10.00,
6.94,
13.47,
14.39,
13.18,
14.83,
11.47,
8.78,
10.05,
10.74,
17.69,
13.64,
11.17,
12.25,
14.83,
12.35,
13.00,
15.18,
14.62,
15.83,
9.89,
12.11,
10.24,
15.19,
8.65,
11.83,
13.50,
10.72,
13.81,
10.53,
15.60,
9.25,
10.11,
8.17,
15.91,
9.56,
14.06,
7.67,
8.89,
8.95,
7.35,
12.88,
12.56,
13.17,
15.11,
14.67,
6.11,
11.05,
11.61,
13.35,
10.33,
15.41,
13.76,
15.00,
13.75,
13.95,
13.69,
10.45,
9.32,
15.67,
14.57,
8.12,
12.79,
9.11,
10.74,
9.68,
10.74,
15.11,
9.47,
14.24,
11.78,
11.26,
14.94,
13.73,
12.05,
13.35,
12.93,
13.39,
10.94,
17.88,
15.33,
13.30,
14.59,
13.43,
14.82,
3.75,
3.83,
12.43,
5.50,
7.39,
6.61,
8.61,
6.33,
4.90,
5.80,
8.42,
6.33,
10.63,
9.00,
3.44,
11.88,
7.59,
8.00,
12.11,
10.20,
11.00,
8.79,
9.28,
8.56,
10.00,
6.68,
11.72,
12.89,
7.83,
11.24,
13.00,
3.75,
7.32,
10.26,
12.11,
12.44,
12.85,
10.28,
16.00,
14.81,
10.28,
16.29,
14.07,
12.84,
14.41,
13.05,
9.26,
14.14,
6.47,
11.67,
14.50,
12.24,
9.72,
6.89,
15.67,
10.25,
10.16,
10.25,
6.95,
11.10,
9.72,
11.42,
6.00,
8.13,
16.00,
11.44,
8.00,
7.53,
11.28,
9.94,
5.67,
10.62,
7.22,
11.70,
5.61,
10.72,
5.17,
8.22,
4.95,
16.00,
7.62,
8.42,
10.83,
9.83,
12.14,
5.83,
7.28,
11.12,
11.00,
14.11,
15.40,
7.42,
15.31,
11.54,
4.71,
13.40,
13.83,
11.06,
11.80,
14.60,
12.12,
13.83,
10.67,
12.81,
6.72,
11.94,
11.88,
11.63,
12.47,
12.07,
13.33,
7.26,
9.89,
12.11,
7.21,
12.10,
11.72,
10.63,
3.38,
10.00,
9.39,
9.67,
6.84,
5.63,
11.71,
12.89,
15.00,
12.41,
8.06,
7.17,
15.07,
12.46,
11.67,
11.84,
8.47,
10.77,
13.30,
14.70,
9.58,
7.21,
10.94,
8.40,
15.75,
4.88,
14.00,
7.44,
12.12,
16.00,
15.19,
8.21,
8.43,
12.95,
3.85,
4.74,
7.89,
5.89,
5.37,
7.90,
11.47,
6.00,
15.17,
17.00,
11.78,
14.00,
11.00,
10.33,
5.78,
12.67,
14.83,
15.78,
16.71,
10.21,
11.22,
8.63,
14.33,
14.68,
15.57,
8.20,
9.67,
3.74,
10.00,
7.06,
7.26,
8.72,
13.21,
14.94,
5.15,
9.05,
13.50,
13.89,
14.39,
13.65,
10.26,
11.40,
10.30,
10.18,
11.12,
8.67,
8.82,
4.11,
8.50,
11.40,
7.65,
9.44,
11.44,
15.88,
12.93,
11.83,
10.30,
8.61,
6.72,
11.75,
7.94,
8.33,
6.37,
14.58,
14.20,
6.29,
6.94,
10.42,
9.63,
13.72,
12.20,
16.20,
13.25,
6.94,
11.90,
5.72,
8.58,
8.40,
9.68,
11.40,
10.81,
14.43,
4.79,
7.50,
9.79,
9.37,
9.00,
7.78,
13.00,
13.12,
7.81,
11.26,
4.67,
9.35,
12.23,
10.29,
8.11,
11.79,
10.53,
10.33,
10.67,
8.42,
7.00,
11.33,
17.00,
11.67,
9.89,
13.40,
14.73,
16.00,
17.14,
14.89,
5.68,
13.50,
13.29,
12.17,
12.69,
8.56,
9.39,
11.65,
4.17,
10.70,
9.06,
7.29,
12.25,
5.11,
9.26,
5.31,
13.93,
12.73,
9.17,
14.82,
15.69,
14.00,
8.43,
3.28,
10.00,
14.75,
14.93,
8.50,
8.89,
11.54,
10.37,
10.71,
14.06,
12.45,
6.10,
5.89,
10.06,
8.26,
12.44,
13.06,
12.06,
14.28,
14.06,
13.71,
13.31,
11.11,
16.64,
6.28,
11.84,
10.94,
10.18,
8.50,
13.68,
12.60,
12.93,
13.20,
12.59,
14.71,
8.67,
9.74,
10.40,
12.24,
8.47,
17.70,
13.71,
8.00,
8.63,
10.62,
9.32,
14.45,
9.47,
9.53,
7.67,
15.08,
13.24,
11.75,
16.10,
10.57,
9.88,
14.44,
11.01,
8.39,
8.42,
12.25,
9.84,
14.60,
15.94,
11.95,
6.61,
14.46,
13.60,
13.44,
14.94,
15.25,
16.80,
12.75,
15.00,
13.91,
16.67,
13.62,
12.94,
12.72,
10.33,
11.93,
12.80,
13.69,
18.00,
5.84,
9.12,
8.94,
10.06,
6.00,
16.60,
10.32,
8.47,
11.68,
6.50,
7.00,
10.82,
10.84,
15.08,
14.58,
15.58,
16.89,
16.65,
14.94,
14.77,
12.47,
11.12,
13.24,
14.09,
14.17,
14.75,
11.00,
15.50,
7.22,
8.00,
11.76,
9.06,
8.28,
8.56,
9.81,
11.15,
12.82,
8.52,
7.89,
15.71,
12.67,
6.10,
9.44,
9.91,
10.80,
10.39,
12.05,
9.04,
11.11,
10.28,
10.84,
11.28,
6.10,
5.36,
5.55,
8.06,
5.47,
9.89,
6.89,
9.63,
11.85,
12.78,
13.06,
7.53,
12.11,
10.75,
12.75,
9.00,
11.29,
13.73,
16.56,
8.21,
10.60,
9.89,
15.29,
14.87,
13.06,
5.06,
8.83,
10.95,
6.82,
10.00,
6.95,
10.89,
14.50,
11.00,
10.00,
11.41,
8.05,
7.45,
7.05,
7.17,
11.42,
7.30,
12.21,
6.58,
7.74,
8.56,
12.50,
10.30,
12.52,
11.16,
14.00,
8.91,
5.78,
6.06,
7.44,
10.00,
11.47,
12.39,
3.43,
11.65,
4.00,
7.56,
6.00,
9.47,
13.89,
6.32,
10.42,
12.84,
7.27,
8.75,
3.25,
9.94,
8.58,
10.32,
9.47,
12.29,
13.75,
7.94,
5.56,
10.21,
5.16,
6.39,
5.76,
5.95,
5.36,
9.17,
5.05,
7.37,
8.73,
9.21,
8.72,
9.39,
12.75,
10.63,
3.69,
13.56,
11.00,
12.33,
6.61,
4.95,
4.95,
11.17,
14.50,
13.06,
15.27,
9.12,
12.86,
4.39,
8.83,
10.73,
12.33,
14.89,
4.05,
7.64,
5.47,
5.16,
7.06,
9.95,
8.78,
6.84,
4.72,
7.69,
6.18,
7.64,
8.71,
11.59,
13.65,
10.55,
6.94,
9.67,
5.94,
12.67,
6.72,
12.90,
14.72,
16.40,
14.47,
4.58,
8.11,
6.29,
8.25,
14.50,
16.00,
5.74,
12.38,
8.74,
8.86,
4.51,
11.86,
5.00,
8.32,
12.47,
11.33,
12.38,
8.00,
8.53,
7.06,
8.56,
6.50,
12.41,
11.33,
12.61,
8.90,
14.94,
5.33,
15.00,
13.64,
15.87,
14.88,
9.50,
13.31,
6.44,
11.59,
13.12,
11.33,
11.56,
12.19,
8.91,
13.56,
5.95,
11.11,
9.63,
11.78,
14.00,
13.68,
12.47,
6.25,
14.06,
11.39,
13.80,
8.89,
7.32,
6.32,
10.06,
12.65,
15.47,
12.15,
12.50,
9.44,
7.21,
16.67,
8.50,
10.47,
9.84,
11.45,
7.42,
10.61,
7.78,
9.11,
14.42,
10.32,
5.61,
13.60,
14.47,
7.74,
10.78,
5.68,
9.30,
11.69,
12.64,
11.11,
9.53,
11.67,
9.47,
12.53,
6.95,
7.05,
9.53,
12.44,
12.44,
10.14,
9.61,
9.29,
13.50,
11.22,
14.00,
12.50,
13.85,
9.28,
10.59,
12.55,
6.30,
9.83,
7.40,
9.32,
9.78,
9.74,
9.33,
12.45,
15.78,
12.29,
7.79,
8.06,
11.41,
13.77,
14.11,
8.33,
10.20,
10.75,
12.50,
10.74,
14.50,
8.30,
10.84,
14.31,
12.83,
12.00,
8.68,
9.65,
7.58,
14.21,
16.50,
13.25,
7.55,
10.20,
8.94,
7.47,
8.94,
11.94,
14.15,
9.21,
7.89,
11.56,
8.95,
9.62,
13.17,
9.15,
5.11,
12.55,
13.88,
8.58,
7.58,
8.68,
10.53,
13.67,
10.95,
10.05,
10.60,
10.12,
15.75,
5.39,
10.39,
9.47,
11.33,
7.18,
14.17,
10.32,
6.58,
11.17,
8.68,
12.80,
8.50,
4.44,
9.35,
7.84,
10.47,
12.50,
12.18,
6.68,
13.79,
12.40,
6.68,
11.94,
8.84,
10.56,
11.00,
15.00,
12.93,
13.71,
8.45,
7.06,
8.26,
14.14,
12.55,
10.07,
12.75,
8.80,
6.89,
10.94,
10.79,
8.76,
14.50,
7.40,
3.11,
7.05,
9.16,
9.53,
6.55,
8.06,
13.54,
6.47,
11.67,
13.14,
12.22,
11.81,
11.53,
10.93,
10.42,
4.85,
5.95,
7.94,
13.61,
10.10,
12.40,
13.62,
13.36,
8.16,
9.67,
10.08,
11.94,
12.31,
9.67,
13.47,
13.83,
13.82,
15.09,
13.17,
9.44,
5.17,
10.73,
12.21,
8.47,
11.61,
8.35,
10.24,
14.50,
3.05,
10.00,
8.94,
14.60,
10.76,
10.95,
7.95,
7.44,
11.87,
10.11,
9.48,
11.79,
5.79,
6.18,
11.86,
8.44,
6.15,
7.37,
12.38,
10.00,
8.32,
14.00,
9.95,
6.21,
12.91,
10.75,
9.68,
7.11,
8.94,
15.40,
7.57,
13.08,
4.95,
8.21,
15.43,
9.55,
10.63,
14.25,
12.93,
8.19,
9.56,
12.25,
7.33,
11.11,
8.63,
14.75,
12.38,
12.80,
5.11,
7.79,
6.21,
10.67,
14.67,
9.21,
14.17,
13.00,
10.50,
10.78,
11.50,
3.25,
13.47,
7.56,
11.17,
7.56,
11.68,
11.58,
8.94,
8.95,
9.71,
12.67,
3.44,
9.41,
4.84,
8.71,
8.53,
8.82,
11.20,
11.67,
12.40,
9.12,
11.16,
9.05,
11.20,
12.90,
10.95,
11.55,
11.59,
10.47,
11.60,
11.06,
7.72,
11.33,
5.78,
9.11,
6.55,
11.83,
7.32,
15.62,
5.38,
6.46,
11.10,
6.00,
9.16,
7.74,
10.63,
7.92,
11.71,
12.00,
14.58,
16.44,
4.39,
11.47,
12.60,
16.00,
13.71,
13.85,
11.94,
15.22,
8.47,
9.63,
6.00,
7.89,
13.74,
7.56,
11.00,
8.10,
17.83,
12.17,
8.65,
14.07,
12.29,
12.77,
9.05,
11.70,
13.50,
15.47,
14.17,
11.57,
9.37,
7.26,
11.76,
11.62,
12.26,
11.82,
10.85,
10.89,
5.58,
8.74,
10.39,
11.41,
13.24,
14.00,
10.33,
13.31,
12.90,
11.45,
11.67,
11.78,
13.30,
14.11,
16.27,
9.00,
14.40,
12.41,
15.20,
10.95,
12.24,
13.11,
14.33,
13.57,
10.78,
13.82,
13.42,
6.28,
11.80,
14.77,
11.64,
8.56,
10.44,
9.94,
12.92,
10.06,
11.35,
11.11,
15.86,
9.33,
11.16,
10.84,
13.27,
10.60,
12.78,
10.40,
13.18,
15.00,
9.80,
11.00,
10.74,
4.78,
7.84,
7.47,
7.00,
8.76,
5.44,
6.68,
8.21,
7.28,
16.00,
3.63,
6.84,
9.11,
11.29,
7.58,
10.12,
10.50,
11.39,
17.14,
14.38,
12.59,
11.47,
11.47,
9.67,
12.11,
8.45,
11.39,
15.71,
11.29,
11.37,
10.47,
13.50,
13.50,
10.75,
12.00,
13.44,
12.35,
14.94,
10.86,
12.00,
13.80,
11.53,
6.48,
12.72,
7.17,
12.72,
12.35,
10.75,
12.67,
10.83,
12.00,
10.53,
10.05,
14.19,
10.17,
9.05,
8.00,
10.11,
6.53,
10.05,
12.52,
4.24,
12.00,
11.78,
6.08,
7.50,
10.32,
9.16,
9.01,
7.75,
14.40,
9.10,
12.00,
5.15,
10.94,
9.50,
10.42,
12.25,
11.60,
12.67,
7.17,
4.93,
12.24,
10.50,
4.68,
16.60,
7.89,
13.36,
5.02,
13.73,
11.06,
9.33,
9.94,
10.39,
12.39,
10.11,
11.68,
14.00,
12.00,
10.06,
10.80,
13.38,
9.80,
8.28,
9.88,
11.53,
14.21,
13.61,
9.63,
9.58,
9.64,
11.16,
13.17,
7.67,
10.74,
8.83,
12.81,
12.58,
15.95,
15.67,
13.46,
15.50,
10.58,
10.14,
13.26,
10.67,
13.17,
15.12,
14.80,
10.32,
12.95,
15.31,
14.68,
16.00,
15.58,
10.11,
11.12,
12.22,
11.47,
10.37,
10.69,
11.42,
8.02,
8.95,
10.74,
7.60,
5.61,
6.58,
5.17,
5.21,
14.88,
10.79,
11.00,
7.05,
13.84,
13.58,
10.94,
11.38,
15.00,
14.40,
12.82,
10.63,
11.06,
11.44,
12.79,
16.47,
10.32,
10.28,
4.89,
4.63,
6.05,
10.56,
10.47,
13.69,
12.39,
9.53,
15.50,
9.79,
6.94,
11.20,
14.50,
6.22,
8.84,
8.47,
13.71,
10.45,
9.44,
7.72,
11.11,
12.32,
13.75,
14.12,
9.22,
13.91,
9.80,
13.12,
4.67,
7.47,
3.57,
7.95,
6.84,
4.50,
5.62,
13.64,
11.85,
14.40,
13.08,
7.53,
8.83,
5.65,
10.11,
6.55,
5.83,
9.84,
12.79,
8.59,
9.11,
9.61,
5.50,
10.16,
10.26,
12.17,
15.86,
13.80,
13.00,
11.64,
14.00,
10.67,
9.00,
9.05,
9.65,
4.32,
11.23,
6.89,
8.85,
8.50,
13.80,
4.44,
11.50,
5.19,
11.23,
10.89,
12.02,
8.86,
10.76,
13.00,
5.21,
9.44,
7.72,
4.95,
6.33,
12.56,
10.67,
16.57,
13.00,
4.52,
7.41,
13.60,
5.60,
14.50,
11.37,
3.63,
8.28,
7.88,
10.60,
10.39,
12.12,
12.30,
6.80,
11.47,
12.17,
9.17,
7.97,
7.75,
8.35,
5.79,
8.56,
6.81,
11.32,
11.22,
10.89,
10.74,
15.89,
11.69,
5.78,
8.11,
12.53,
7.17,
17.25,
10.55,
15.00,
13.21,
8.70,
4.24,
13.00,
7.65,
9.17,
15.82,
11.12,
8.06,
9.18,
8.67,
13.50,
11.78,
12.71,
12.83,
11.17,
3.67,
9.10,
8.94,
11.58,
12.50,
9.56,
13.47,
12.47,
9.90,
13.76,
15.55,
10.47,
9.11,
9.40,
9.22,
7.28,
14.33,
9.50,
9.67,
10.70,
13.62,
12.94,
13.33,
8.44,
16.75,
9.95,
8.61,
8.44,
5.47,
8.89,
5.22,
13.62,
13.58,
20.00,
8.78,
12.25,
10.24,
13.00,
14.71,
10.60,
10.50,
9.89,
10.28,
5.89,
12.69,
16.20,
13.00,
13.18,
5.72,
6.68,
13.73,
11.63,
14.54,
8.84,
13.86,
10.78,
11.57,
12.47,
13.63,
5.50,
13.57,
10.08,
6.67,
13.11,
13.55,
13.00,
7.16,
13.79,
11.06,
12.62,
14.29,
7.11,
7.68,
5.05,
9.41,
13.85,
7.92,
13.67,
12.12,
11.57,
10.29,
10.85,
13.08,
13.82,
8.63,
14.90,
11.17,
18.86,
16.00,
12.27,
16.17,
7.58,
6.94,
9.29,
13.40,
11.40,
12.25,
7.11,
10.12,
10.53,
13.71,
11.75,
6.21,
14.06,
10.29,
15.08,
7.67,
10.57,
12.44,
10.28,
12.25,
11.68,
12.79,
8.68,
12.69,
0.00,
12.93,
12.50,
6.25,
8.32,
11.24,
13.11,
15.00,
15.50,
9.47,
11.40,
12.33,
16.88,
13.56,
14.00,
12.88,
9.05,
10.26,
9.22,
13.44,
4.26,
10.94,
11.65,
11.12,
14.50,
14.07,
17.00,
13.69,
9.89,
14.06,
14.50,
12.60,
17.06,
12.55,
11.31,
9.22,
14.89,
11.18,
15.71,
13.33,
13.86,
12.00,
12.22,
11.94,
14.33,
8.50,
13.73,
14.15,
17.29,
14.55,
8.22,
11.72,
9.83,
5.26,
12.05,
4.89,
12.93,
12.29,
14.57,
5.33,
6.50,
11.94,
6.42,
14.67,
11.61,
8.58,
10.18,
15.88,
14.00,
10.93,
6.89,
11.20,
8.32,
13.30,
14.79,
11.96,
14.88,
16.20,
14.11,
11.72,
5.32,
11.07,
11.71,
7.95,
10.50,
14.08,
10.90,
15.60,
9.80,
8.93,
10.47,
6.25,
8.05,
12.00,
10.11,
8.05,
14.59,
17.53,
16.88,
15.93,
14.31,
14.73,
16.15,
17.29,
12.13,
13.30,
5.32,
13.00,
11.93,
11.67,
9.94,
10.12,
8.38,
6.50,
9.21,
7.61,
15.50,
14.64,
11.54,
13.17,
12.06,
10.83,
11.81,
13.12,
13.27,
9.32,
10.76,
9.22,
11.50,
10.84,
12.56,
11.25,
15.60,
9.32,
12.17,
9.00,
9.76,
10.86,
9.37,
9.83,
10.43,
13.38,
7.61,
10.24,
10.89,
10.32,
8.40,
10.79,
9.59,
14.24,
7.21,
7.55,
12.58,
13.15,
12.60,
12.50,
7.68,
7.94,
13.18,
16.67,
7.00,
11.75,
13.89,
13.32,
12.89,
8.05,
13.67,
11.74,
11.95,
10.26,
10.44,
10.78,
9.52,
12.68,
13.69,
11.11,
11.00,
9.06,
7.22,
10.68,
13.76,
11.84,
12.53,
13.54,
6.67,
9.82,
11.84,
14.43,
7.21,
14.81,
13.20,
15.00,
13.31,
12.75,
11.80,
12.00,
12.00,
13.29,
4.89,
11.67,
6.89,
10.55,
9.81,
7.78,
7.00,
10.00,
15.00,
14.11,
9.42,
12.43,
9.06,
12.33,
11.60,
13.33,
17.86,
9.67,
8.89,
10.72,
8.15,
11.72,
10.67,
10.47,
10.00,
16.00,
13.00,
10.33,
9.74,
11.22,
13.00,
12.31,
15.12,
16.82,
14.81,
14.42,
17.25,
14.12,
11.41,
7.06,
12.81,
14.83,
14.62,
5.95,
13.00,
12.77,
9.05,
15.36,
12.80,
11.53,
12.39,
5.29,
6.74,
13.29,
16.09,
12.94,
14.12,
14.40,
13.73,
11.74,
3.17,
9.50,
9.40,
6.72,
10.11,
10.33,
15.00,
16.50,
12.00,
10.15,
5.06,
9.47,
8.17,
12.18,
12.11,
9.59,
11.31,
4.72,
11.94,
11.70,
8.79,
14.11,
13.33,
11.72,
11.62,
9.74,
5.05,
7.94,
10.56,
12.05,
15.05,
6.64,
12.37,
5.48,
7.69,
13.94,
12.42,
13.24,
7.39,
11.56,
13.50,
13.50,
11.89,
10.94,
11.89,
13.47,
12.06,
8.05,
6.00,
13.53,
9.11,
12.12,
12.33,
13.60,
14.21,
14.32,
10.40,
5.00,
12.83,
12.00,
10.22,
4.00,
6.26,
9.47,
7.32,
8.06,
10.21,
6.81,
10.94,
11.30,
4.28,
10.24,
5.83,
9.11,
10.63,
8.00,
11.55,
10.58,
12.71,
8.05,
5.79,
11.00,
10.71,
8.56,
12.08,
15.15,
8.00,
15.30,
10.92,
11.00,
10.90,
9.21,
9.11,
9.22,
10.61,
14.62,
9.33,
12.00,
16.18,
4.47,
4.35,
7.33,
9.26,
15.29,
9.26,
9.72,
10.68,
8.05,
14.10,
8.42,
7.19,
14.67,
11.62,
8.79,
12.43,
15.10,
15.00,
9.28,
10.94,
10.69,
14.33,
13.40,
13.31,
7.72,
10.47,
10.22,
9.24,
12.46,
17.71,
9.21,
11.86,
5.22,
17.14,
6.35,
4.94,
12.00,
10.17,
12.23,
11.16,
12.39,
7.41,
9.78,
15.64,
6.50,
8.90,
12.53,
13.76,
12.33,
15.83,
13.08,
13.14,
9.00,
9.36,
9.28,
9.00,
10.47,
11.74,
9.50,
11.39,
8.21,
7.40,
9.90,
9.06,
11.88,
8.28,
15.67,
13.92,
4.30,
6.94,
6.28,
9.19,
13.78,
4.67,
7.56,
10.15,
15.29,
12.87,
17.73,
16.00,
15.25,
13.50,
14.32,
12.22,
13.60,
10.76,
14.26,
13.45,
14.71,
14.43,
15.08,
16.78,
12.69,
11.35,
11.00,
11.45,
10.84,
10.10,
10.37,
15.75,
8.94,
11.27,
3.37,
13.14,
6.89,
7.79,
8.00,
7.89,
9.83,
9.24,
10.37,
13.67,
5.21,
11.78,
12.69,
8.80,
10.25,
11.84,
11.29,
5.58,
10.00,
8.94,
11.44,
7.67,
7.88,
12.40,
4.35,
8.06,
7.69,
9.95,
8.05,
8.37,
8.47,
11.25,
14.00,
14.77,
9.78,
11.06,
7.55,
9.14,
8.84,
6.95,
7.71,
11.56,
9.94,
8.81,
9.44,
7.05,
5.41,
16.00,
8.81,
7.10,
15.83,
12.00,
13.17,
6.94,
10.19,
14.55,
4.50,
8.56,
10.17,
14.85,
7.16,
7.16,
7.68,
16.18,
7.83,
11.67,
10.21,
14.13,
9.35,
9.62,
14.08,
5.94,
14.94,
5.53,
8.42,
3.55,
3.47,
10.05,
6.37,
10.00,
10.16,
6.05,
7.44,
8.79,
7.00,
6.84,
5.72,
7.79,
6.85,
4.83,
14.57,
15.80,
11.75,
10.33,
10.83,
7.12,
6.75,
5.15,
9.41,
12.50,
14.08,
7.21,
10.24,
9.17,
7.68,
9.50,
12.23,
13.46,
15.33,
5.74,
12.88,
9.74,
6.81,
8.00,
15.00,
9.16,
13.85,
10.67,
13.63,
10.16,
12.40,
8.89,
10.65,
9.95,
13.65,
14.83,
9.53,
12.00,
12.89,
8.50,
10.95,
8.47,
11.00,
6.16,
11.47,
15.67,
17.67,
5.37,
8.24,
7.94,
7.29,
8.40,
8.33,
11.84,
8.50,
11.25,
13.60,
10.94,
14.17,
5.72,
7.90,
13.42,
9.00,
7.56,
7.28,
9.47,
14.55,
13.80,
12.17,
12.72,
12.06,
7.44,
11.11,
9.41,
15.73,
6.37,
13.89,
10.00,
12.25,
11.38,
6.68,
8.75,
5.26,
3.58,
4.33,
4.68,
14.67,
11.79,
4.39,
16.88,
13.32,
15.63,
2.58,
3.11,
3.67,
9.50,
10.11,
3.06,
5.50,
13.54,
5.89,
10.83,
13.06,
12.24,
4.22,
9.94,
12.01,
11.89,
11.19,
3.94,
6.90,
15.56,
7.37,
8.16,
10.67,
12.67,
9.47,
10.11,
16.88,
13.27,
10.00,
16.50,
9.94,
14.56,
12.41,
8.20,
12.80,
3.94,
5.78,
10.75,
14.80,
6.33,
14.78,
8.90,
7.42,
7.74,
8.88,
10.26,
12.78,
11.39,
14.40,
10.71,
8.26,
14.12,
11.89,
15.12,
7.06,
8.78,
7.19,
10.73,
11.56,
12.47,
9.12,
7.37,
6.06,
17.80,
16.56,
13.07,
11.06,
12.00,
11.78,
15.36,
8.39,
6.00,
5.74,
7.11,
8.90,
11.16,
13.38,
7.33,
6.94,
12.53,
12.81,
8.50,
6.39,
5.05,
8.38,
8.33,
10.90,
7.63,
7.53,
3.79,
12.17,
9.63,
11.00,
14.08,
9.22,
7.11,
14.00,
8.22,
12.88,
6.90,
6.47,
9.26,
6.37,
8.40,
9.72,
11.73,
14.00,
8.00,
11.63,
8.67,
8.80,
12.62,
10.79,
8.39,
11.79,
10.00,
8.75,
11.26,
13.54,
6.53,
7.26,
13.19,
9.16,
12.00,
15.00,
8.72,
10.06,
5.84,
8.37,
8.68,
11.47,
12.73,
6.70,
10.44,
10.94,
10.39,
12.36,
11.44,
12.17,
9.71,
8.95,
10.22,
11.13,
11.08,
7.75,
8.70,
13.50,
9.22,
5.35,
5.72,
13.00,
11.32,
9.22,
13.39,
7.78,
8.78,
11.50,
8.39,
7.68,
11.95,
12.82,
16.50,
5.21,
8.68,
14.73,
11.78,
16.29,
12.00,
7.29,
5.53,
4.89,
11.88,
6.00,
11.56,
11.72,
14.08,
9.83,
13.31,
10.35,
10.88,
13.56,
10.61,
12.18,
5.94,
12.93,
12.06,
9.00,
13.00,
12.36,
8.21,
12.50,
4.79,
7.53,
5.79,
10.62,
4.43,
6.70,
8.95,
11.00,
9.13,
10.78,
16.71,
10.42,
11.22,
11.60,
11.33,
8.60,
11.16,
8.94,
8.37,
8.75,
8.39,
5.89,
11.93,
10.00,
8.79,
10.06,
17.00,
9.68,
15.00,
13.15,
15.75,
11.05,
17.18,
11.00,
6.25,
12.78,
9.52,
9.28,
9.44,
9.48,
9.35,
12.24,
10.33,
12.39,
14.14,
10.27,
14.62,
19.75,
11.95,
5.44,
8.16,
10.37,
11.15,
6.21,
9.26,
7.76,
10.50,
12.64,
8.95,
7.11,
11.11,
8.06,
9.76,
7.74,
13.00,
12.94,
12.23,
13.25,
16.62,
11.75,
7.05,
9.67,
9.92,
8.05,
9.21,
16.33,
13.12,
5.32,
9.26,
12.50,
15.00,
17.25,
9.37,
8.65,
13.35,
10.63,
10.83,
10.78,
7.29,
4.89,
5.32,
12.05,
15.25,
5.53,
9.33,
8.89,
12.27,
11.11,
5.58,
10.69,
13.00,
8.79,
7.18,
7.52,
8.11,
8.94,
13.75,
12.25,
10.13,
9.71,
12.58,
11.58,
10.45,
9.37,
9.74,
9.44,
12.06,
13.90,
9.79,
6.78,
9.05,
10.16,
13.55,
8.06,
7.74,
11.47,
13.94,
10.00,
8.47,
8.89,
10.50,
15.77,
12.15,
8.12,
15.80,
11.53,
9.15,
7.17,
12.50,
11.17,
11.00,
6.96,
8.67,
7.79,
12.38,
11.67,
14.67,
12.36,
4.11,
10.33,
11.72,
6.00,
16.00,
6.63,
6.74,
7.06,
7.74,
9.25,
14.33,
8.95,
13.67,
15.56,
15.12,
11.71,
14.00,
12.69,
13.13,
12.27,
14.33,
12.82,
16.75,
7.67,
13.76,
12.17,
10.44,
9.44,
10.65,
11.53,
13.65,
11.59,
11.16,
12.50,
14.50,
13.39,
16.22,
14.70,
11.53,
12.33,
13.00,
11.00,
12.33,
16.00,
10.00,
11.17,
15.42,
12.75,
17.00,
11.94,
7.90,
10.14,
8.16,
3.17,
8.33,
4.70,
4.05,
6.29,
6.39,
8.86,
6.65,
8.61,
6.06,
9.93,
8.67,
9.68,
8.70,
10.83,
8.17,
13.58,
12.05,
7.84,
7.11,
8.50,
5.70,
17.67,
16.20,
13.33,
12.80,
10.90,
5.45,
9.67,
11.89,
11.07,
7.61,
13.50,
11.00,
12.11,
14.67,
5.35,
10.42,
11.33,
10.79,
11.22,
10.53,
14.28,
14.44,
13.58,
14.42,
5.38,
6.39,
14.17,
13.47,
17.57,
14.00,
8.11,
10.55,
7.53,
16.00,
4.10,
5.21,
11.35,
5.42,
10.15,
11.93,
7.11,
10.70,
6.80,
4.37,
9.74,
2.74,
7.95,
8.69,
12.21,
8.89,
8.05,
10.11,
7.31,
5.06,
10.78,
11.89,
6.28,
11.38,
6.42,
10.74,
7.59,
10.40,
9.26,
8.00,
7.37,
11.19,
12.61,
9.78,
10.79,
7.22,
6.30,
6.26,
10.37,
7.79,
13.06,
9.93,
8.82,
7.74,
10.94,
9.19,
5.11,
7.00,
8.56,
11.53,
6.00,
6.67,
6.50,
9.32,
14.12,
9.75,
6.71,
9.22,
11.11,
5.40,
6.40,
7.35,
7.78,
6.68,
10.00,
11.50,
6.78,
9.50,
8.33,
10.84,
9.42,
13.39,
7.83,
10.53,
12.24,
11.38,
8.71,
9.91,
11.06,
15.56,
5.26,
14.06,
5.56,
6.17,
2.72,
16.00,
10.84,
9.84,
10.35,
16.07,
8.28,
12.00,
4.39,
10.50,
10.40,
7.06,
11.61,
12.85,
8.36,
8.72,
6.94,
8.25,
8.42,
10.17,
14.43,
14.47,
7.54,
8.16,
13.06,
10.33,
11.00,
8.53,
8.63,
9.84,
6.56,
9.17,
6.78,
11.19,
12.67,
15.00,
11.00,
10.47,
12.86,
12.69,
5.00,
10.25,
7.63,
7.70,
11.89,
7.32,
12.40,
10.38,
15.39,
11.68,
12.06,
8.79,
7.83,
7.55,
12.11,
11.21,
11.81,
12.00,
11.20,
14.00,
10.46,
10.38,
9.47,
9.62,
7.40,
9.53,
12.74,
14.85,
13.20,
8.65,
12.86,
9.00,
12.16,
11.50,
10.28,
3.33,
9.61,
10.16,
7.00,
12.00,
13.64,
12.00,
8.20,
8.32,
12.29,
5.53,
6.42,
8.56,
10.71,
8.82,
8.29,
10.92,
8.72,
9.08,
14.67,
12.79,
7.58,
10.60,
9.75,
12.57,
7.95,
17.00,
3.72,
9.13,
13.20,
10.50,
11.86,
6.74,
16.00,
10.36,
5.32,
9.12,
7.57,
11.06,
7.32,
11.87,
6.32,
10.68,
7.61,
8.00,
11.62,
7.12,
9.45,
13.31,
8.72,
3.81,
3.42,
6.71,
9.39,
6.33,
9.86,
13.57,
8.16,
9.06,
11.47,
10.78,
12.18,
9.50,
8.72,
6.79,
8.11,
10.90,
10.00,
10.59,
10.26,
11.50,
11.00,
10.16,
7.74,
10.00,
9.80,
8.63,
8.72,
7.68,
9.79,
5.74,
13.11,
9.05,
11.56,
10.83,
15.17,
7.15,
10.75,
9.44,
11.11,
11.84,
13.62,
13.00,
11.06,
8.40,
13.14,
12.53,
12.24,
7.47,
9.61,
7.70,
7.56,
12.50,
10.11,
7.61,
7.79,
3.80,
10.19,
6.05,
12.72,
12.76,
9.89,
5.17,
9.72,
7.22,
10.32,
9.11,
8.56,
9.00,
10.47,
10.00,
10.76,
10.95,
9.79,
10.05,
10.47,
9.50,
11.56,
13.00,
10.68,
10.67,
10.22,
8.72,
10.95,
9.94,
5.33,
6.10,
10.48,
9.67,
14.61,
9.97,
7.63,
9.89,
9.05,
4.22,
6.22,
10.86,
6.11,
4.05,
8.16,
8.70,
7.00,
12.17,
12.47,
14.11,
9.95,
8.90,
8.63,
12.67,
9.91,
16.27,
16.47,
15.76,
11.05,
12.73,
13.61,
7.85,
11.00,
12.88,
10.83,
16.33,
15.92,
10.07,
5.42,
11.17,
10.17,
11.24,
13.27,
9.56,
12.71,
9.67,
10.61,
12.30,
5.26,
15.27,
10.94,
12.27,
9.86,
11.69,
5.11,
15.00,
11.38,
14.50,
12.37,
12.11,
12.88,
10.05,
3.76,
10.90,
5.71,
12.47,
17.33,
3.65,
4.80,
5.44,
7.24,
8.32,
9.67,
11.32,
13.59,
8.37,
14.00,
16.50,
14.56,
15.81,
14.78,
9.85,
13.33,
9.88,
12.20,
14.00,
13.45,
17.75,
13.33,
12.52,
15.64,
13.88,
14.57,
12.94,
13.00,
6.39,
11.09,
10.32,
8.62,
8.47,
13.19,
14.67,
11.24,
12.62,
17.70,
15.60,
14.24,
15.71,
5.09,
10.00,
13.57,
9.05,
15.00,
14.25,
11.28,
15.78,
15.60,
12.88,
11.94,
11.47,
12.38,
8.92,
13.06,
7.74,
9.61,
3.74,
11.42,
11.05,
12.86,
14.83,
14.35,
12.20,
9.79,
12.71,
15.42,
14.88,
17.25,
14.73,
8.47,
12.76,
12.29,
15.11,
15.91,
6.47,
8.20,
10.88,
10.68,
9.55,
10.35,
11.37,
14.46,
16.50,
15.22,
10.20,
12.75,
4.39,
6.90,
12.95,
11.59,
12.12,
11.22,
8.30,
9.60,
13.30,
12.00,
13.14,
15.50,
13.76,
12.22,
13.58,
12.69,
12.82,
12.42,
7.95,
10.39,
10.59,
14.59,
14.21,
8.37,
7.74,
13.88,
10.20,
5.35,
8.94,
11.58,
9.39,
14.44,
4.47,
8.44,
11.25,
9.53,
11.32,
6.86,
11.00,
13.86,
12.47,
11.11,
16.50,
9.53,
12.00,
10.53,
17.75,
4.58,
10.44,
14.38,
14.14,
13.56,
3.79,
12.09,
10.62,
14.27,
11.20,
10.94,
13.33,
7.61,
8.24,
7.35,
11.59,
6.22,
11.20,
6.50,
11.72,
10.76,
11.85,
9.40,
8.50,
8.63,
12.53,
3.47,
6.16,
7.95,
6.00,
14.40,
3.43,
5.21,
9.21,
11.10,
11.22,
12.60,
12.24,
7.95,
5.95,
15.08,
6.17,
7.68,
11.75,
11.93,
10.78,
5.79,
16.31,
7.00,
14.42,
9.00,
12.87,
17.60,
3.69,
6.33,
10.17,
8.60,
16.16,
13.33,
15.17,
11.58,
8.47,
10.00,
15.59,
6.42,
14.86,
14.89,
4.75,
9.39,
10.56,
9.53,
7.68,
13.07,
7.39,
6.89,
10.61,
7.95,
10.17,
15.33,
11.47,
12.25,
14.00,
11.67,
9.12,
7.44,
8.35,
10.47,
10.35,
13.67,
8.22,
11.87,
5.44,
14.33,
16.00,
12.53,
8.72,
9.61,
5.70,
11.75,
12.50,
9.69,
10.47,
9.29,
11.32,
12.60,
8.95,
15.14,
4.67,
6.85,
9.94,
12.33,
8.35,
5.05,
8.53,
5.19,
5.01,
7.88,
15.25,
12.61,
8.11,
15.00,
7.44,
8.63,
7.32,
12.08,
15.33,
10.84,
12.11,
12.00,
10.32,
5.55,
13.72,
12.33,
13.47,
13.25,
3.87,
11.32,
11.22,
12.94,
12.12,
8.72,
11.57,
10.21,
9.35,
7.45,
8.89,
9.40,
11.32,
6.59,
8.58,
11.16,
16.87,
9.40,
14.26,
8.33,
8.83,
9.21,
14.29,
12.78,
11.44,
9.84,
7.22,
7.37,
5.89,
11.65,
12.61,
12.94,
11.75,
15.10,
12.20,
12.43,
11.78,
16.40,
14.88,
13.42,
14.14,
9.56,
15.67,
14.70,
13.44,
12.37,
12.55,
13.33,
11.69,
12.72,
5.46,
5.00,
5.44,
6.22,
9.74,
7.11,
7.56,
8.60,
8.26,
5.39,
9.78,
10.00,
7.89,
8.95,
14.93,
10.18,
11.63,
14.29,
13.06,
5.50,
8.28,
10.33,
12.69,
11.82,
12.67,
10.17,
6.90,
12.07,
6.26,
15.13,
11.82,
13.61,
10.89,
10.93,
11.75,
6.11,
11.62,
16.50,
10.89,
5.94,
14.11,
7.78,
13.00,
13.12,
9.16,
4.84,
4.89,
6.84,
9.67,
8.20,
9.67,
10.57,
14.00,
5.80,
10.58,
12.00,
8.30,
8.15,
12.47,
12.00,
4.84,
13.75,
9.32,
7.28,
13.24,
9.18,
13.27,
12.94,
15.00,
17.67,
10.37,
9.94,
5.81,
8.35,
8.50,
9.80,
8.25,
7.58,
7.58,
4.15,
7.00,
12.00,
6.06,
11.32,
11.25,
10.24,
9.00,
13.06,
6.05,
10.84,
8.83,
9.94,
9.79,
5.85,
10.00,
9.53,
5.71,
12.32,
15.36,
14.12,
15.07,
5.33,
11.06,
14.45,
12.56,
12.71,
5.55,
8.78,
9.53,
7.83,
8.05,
9.89,
15.58,
13.00,
8.47,
9.90,
11.47,
13.73,
3.37,
10.75,
12.06,
6.77,
14.44,
4.55,
6.05,
14.07,
11.35,
11.50,
10.75,
9.42,
12.21,
8.50,
9.76,
12.94,
5.74,
5.85,
8.58,
9.45,
3.16,
10.63,
11.11,
12.18,
9.79,
8.68,
8.00,
9.10,
9.33,
8.83,
7.32,
7.56,
13.38,
7.61,
7.50,
14.80,
9.65,
9.00,
11.86,
8.62,
9.88,
7.47,
9.22,
6.83,
7.76,
11.79,
8.28,
13.71,
7.00,
8.72,
12.29,
8.58,
9.28,
5.36,
17.00,
7.14,
14.92,
5.55,
9.50,
11.82,
16.67,
17.20,
10.11,
11.89,
9.72,
8.39,
13.73,
10.44,
12.54,
9.40,
10.12,
9.28,
8.00,
2.58,
4.90,
9.20,
6.89,
7.78,
7.72,
9.06,
11.17,
9.59,
10.00,
5.80,
4.83,
9.37,
13.12,
14.00,
14.89,
15.67,
12.56,
13.62,
12.00,
9.28,
13.78,
12.06,
14.15,
7.81,
9.87,
13.10,
8.89,
9.21,
9.79,
8.29,
12.06,
12.58,
13.47,
8.56,
12.41,
9.28,
9.80,
12.42,
8.89,
9.89,
8.21,
10.21,
11.07,
11.56,
6.95,
14.84,
8.89,
7.17,
14.69,
13.85,
11.15,
8.56,
6.11,
8.24,
8.21,
8.15,
10.23,
8.44,
8.68,
8.63,
5.47,
13.20,
6.06,
3.63,
8.33,
10.64,
9.74,
6.06,
6.53,
7.26,
12.06,
10.76,
8.75,
9.22,
8.06,
9.46,
5.25,
7.35,
4.44,
4.00,
7.75,
10.84,
5.53,
13.00,
4.44,
7.21,
8.63,
10.21,
14.75,
12.00,
14.67,
9.72,
12.74,
8.10,
10.57,
0.00,
11.75,
14.56,
13.70,
14.30,
14.88,
13.20,
6.89,
10.22,
12.72,
12.95,
14.06,
16.40,
10.56,
14.67,
16.91,
15.78,
14.26,
13.95,
17.67,
14.33,
17.50,
14.88,
16.36,
14.78,
14.47,
14.92,
11.00,
14.60,
14.69,
11.11,
14.87,
16.43,
16.40,
14.55,
14.12,
15.08,
12.80,
15.18,
16.93,
15.38,
14.06,
14.00,
13.25,
12.12,
12.75,
6.61,
9.00,
11.45,
9.84,
13.81,
15.25,
7.50,
8.31,
17.50,
11.00,
11.00,
8.05,
14.47,
12.44,
11.16,
15.73,
12.94,
15.19,
15.33,
17.00,
13.82,
14.50,
14.33,
16.65,
17.43,
13.62,
19.11,
17.09,
13.60,
13.89,
17.53,
13.57,
15.20,
16.28,
17.17,
10.94,
18.12,
14.91,
15.92,
17.67,
15.00,
14.69,
11.56,
10.79,
8.53,
11.33,
9.94,
10.00,
9.44,
13.37,
13.47,
11.70,
10.32,
10.30,
9.89,
11.13,
13.37,
13.50,
13.29,
12.22,
14.00,
11.65,
10.63,
13.06,
14.53,
15.67,
15.53,
16.20,
15.71,
14.41,
12.36,
14.00,
13.31,
12.80,
12.79,
11.89,
12.21,
13.24,
15.43,
16.50,
16.60,
13.67,
15.79,
14.44,
12.06,
10.67,
10.94,
2.79,
13.64,
14.67,
16.25,
11.71,
13.71,
11.26,
3.86,
8.21,
13.31,
10.95,
7.11,
9.72,
10.17,
3.33,
7.44,
9.73,
12.00,
6.89,
9.00,
7.97,
16.11,
14.60,
5.78,
5.53,
4.42,
10.11,
12.22,
15.75,
16.10,
14.57,
12.79,
16.67,
5.83,
12.41,
5.95,
9.50,
12.53,
14.00,
11.89,
13.29,
11.61,
15.15,
8.72,
10.28,
8.68,
12.37,
8.00,
9.45,
13.00,
13.67,
12.67,
14.27,
11.00,
14.06,
14.69,
17.85,
14.07,
15.28,
6.50,
8.79,
8.90,
11.53,
8.37,
10.14,
11.67,
10.75,
12.40,
13.23,
5.36,
7.84,
5.42,
12.43,
9.82,
12.53,
12.21,
15.12,
15.17,
13.25,
10.44,
11.75,
10.21,
9.50,
6.74,
10.11,
7.61,
15.89,
13.21,
12.56,
5.60,
9.21,
10.94,
12.44,
10.42,
12.94,
9.95,
12.00,
12.75,
13.55,
11.32,
11.86,
7.00,
10.88,
11.00,
14.67,
9.20,
16.19,
11.42,
10.50,
9.24,
13.87,
10.21,
14.33,
13.35,
8.32,
7.95,
9.75,
8.41,
12.05,
8.50,
12.17,
10.28,
9.83,
11.89,
6.47,
6.28,
8.74,
6.06,
10.06,
15.29,
15.80,
9.94,
11.17,
13.21,
13.93,
15.08,
15.29,
14.83,
12.44,
9.20,
9.00,
12.61,
10.74,
9.72,
11.83,
9.06,
10.17,
12.63,
12.21,
8.76,
6.77,
13.92,
11.24,
11.84,
9.68,
11.28,
9.78,
9.70,
12.63,
12.21,
13.37,
13.00,
15.80,
10.05,
12.24,
12.59,
12.50,
12.37,
14.61,
13.68,
11.22,
14.55,
15.50,
11.11,
11.00,
10.47,
12.56,
10.61,
12.72,
9.33,
12.32,
12.26,
11.11,
10.53,
17.75,
15.94,
16.29,
13.77,
15.62,
14.50,
15.19,
11.47,
9.95,
11.47,
12.18,
11.67,
11.63,
11.75,
13.32,
12.22,
11.67,
11.16,
12.83,
10.68,
14.92,
13.22,
13.47,
12.05,
15.25,
7.89,
14.83,
7.39,
12.06,
12.47,
11.82,
11.37,
16.08,
13.26,
14.82,
13.47,
16.57,
14.44,
13.45,
12.47,
11.11,
14.56,
13.24,
12.67,
12.93,
12.53,
10.45,
9.83,
14.28,
12.68,
12.61,
13.41,
11.77,
13.36,
12.56,
13.93,
12.53,
10.84,
10.11,
10.95,
10.15,
14.00,
13.69,
13.44,
14.71,
15.07,
15.18,
14.41,
13.57,
14.27,
11.85,
11.94,
15.06,
13.31,
13.00,
12.68,
11.95,
14.06,
12.11,
13.38,
12.82,
11.68,
12.22,
14.58,
13.83,
12.44,
12.85,
12.50,
10.78,
7.26,
9.06,
10.37,
6.80,
5.79,
12.19,
11.39,
15.86,
11.54,
15.67,
12.26,
12.15,
9.50,
7.32,
10.37,
16.61,
14.00,
14.84,
12.53,
12.56,
13.26,
13.32,
12.39,
11.44,
12.89,
12.18,
14.06,
14.50,
13.89,
12.95,
13.11,
14.78,
10.17,
9.33,
9.74,
14.22,
12.65,
12.50,
9.48,
15.29,
11.35,
9.84,
10.06,
12.67,
12.32,
13.20,
13.61,
9.42,
13.21,
7.47,
8.05,
15.91,
13.25,
13.06,
9.68,
11.11,
13.17,
12.88,
15.56,
14.82,
15.30,
9.00,
13.82,
10.39,
13.50,
13.21,
12.71,
11.53,
10.28,
15.57,
15.47,
3.69,
10.67,
10.39,
9.89,
10.94,
10.84,
10.41,
10.72,
9.33,
10.45,
12.68,
11.95,
13.74,
13.53,
15.23,
13.21,
14.16,
14.25,
10.31,
16.12,
16.53,
12.16,
14.00,
15.00,
11.89,
10.16,
10.00,
12.44,
13.87,
11.94,
10.65,
11.90,
12.84,
11.85,
11.71,
11.22,
16.25,
12.37,
10.44,
13.00,
11.26,
12.84,
12.68,
13.50,
14.50,
12.22,
15.44,
12.11,
12.39,
11.50,
13.29,
15.06,
13.05,
11.94,
13.28,
16.88,
12.72,
11.95,
10.44,
14.44,
12.14,
10.45,
12.55,
12.92,
14.11,
18.33,
12.71,
12.26,
13.44,
5.11,
16.90,
6.63,
12.17,
10.10,
9.06,
10.47,
11.37,
12.06,
12.19,
13.87,
13.77,
11.44,
11.72,
13.50,
10.70,
13.12,
14.57,
14.86,
12.79,
11.88,
10.90,
10.63,
6.47,
12.18,
11.89,
13.72,
13.89,
14.73,
13.84,
9.67,
9.00,
13.47,
12.21,
12.55,
12.16,
11.42,
11.74,
12.11,
11.42,
7.95,
12.85,
11.42,
13.44,
12.94,
11.05,
12.32,
13.56,
14.15,
14.75,
13.80,
15.11,
10.78,
9.72,
10.05,
11.94,
10.70,
10.65,
12.67,
10.59,
15.06,
14.41,
14.18,
13.25,
9.37,
9.58,
12.32,
13.67,
13.11,
15.38,
6.65,
13.29,
12.57,
13.18,
13.58,
12.90,
14.00,
7.42,
11.06,
8.80,
12.94,
13.12,
10.67,
11.50,
11.82,
11.68,
11.35,
11.17,
10.00,
13.94,
16.89,
15.73,
17.50,
14.33,
15.00,
13.75,
10.11,
15.43,
12.67,
12.40,
12.15,
12.06,
12.89,
10.72,
11.67,
10.33,
12.38,
16.00,
7.74,
15.40,
15.00,
11.67,
11.88,
11.16,
12.07,
12.67,
11.94,
15.67,
15.94,
17.38,
10.26,
10.33,
11.81,
12.79,
12.67,
8.53,
9.28,
11.72,
12.55,
10.16,
13.83,
13.11,
7.89,
12.00,
9.74,
12.00,
12.05,
10.33,
17.00,
11.82,
12.81,
15.20,
14.88,
9.22,
10.47,
13.41,
13.82,
11.94,
10.11,
12.28,
13.18,
13.79,
8.89,
11.26,
13.75,
12.50,
14.39,
14.53,
13.74,
12.76,
11.22,
12.00,
14.00,
12.47,
13.14,
13.21,
12.21,
7.70,
12.74,
12.40,
11.50,
9.94,
10.83,
9.33,
15.31,
15.67,
12.81,
11.36,
14.33,
5.05,
4.64,
15.27,
12.05,
14.27,
12.24,
11.72,
12.42,
13.37,
14.82,
12.24,
11.44,
10.74,
11.84,
10.22,
12.58,
11.45,
11.55,
13.33,
10.44,
12.60,
14.58,
13.73,
14.60,
11.20,
11.50,
14.89,
10.68,
10.82,
14.59,
11.79,
10.78,
12.44,
11.94,
17.25,
14.29,
13.72,
13.76,
9.68,
15.21,
14.72,
13.10,
12.84,
16.09,
14.39,
9.76,
12.39,
14.07,
12.38,
11.60,
12.00,
15.45,
7.71,
11.63,
12.80,
14.27,
8.05,
10.17,
11.16,
11.26,
12.07,
15.17,
12.82,
12.63,
10.55,
13.00,
11.56,
11.00,
10.56,
9.29,
6.42,
16.27,
13.00,
15.12,
14.65,
12.26,
10.44,
14.58,
13.00,
11.43,
12.52,
12.60,
9.15,
8.05,
11.21,
12.59,
15.57,
12.71,
13.32,
10.57,
12.00,
12.41,
10.53,
14.32,
13.39,
9.58,
11.83,
11.56,
13.89,
8.11,
8.94,
12.53,
12.00,
13.06,
11.86,
9.60,
14.23,
13.28,
8.83,
13.12,
12.83,
13.00,
11.56,
10.05,
11.00,
13.33,
9.00,
8.37,
9.50,
11.82,
16.06,
10.75,
10.33,
10.94,
11.79,
10.74,
12.00,
12.06,
11.60,
10.32,
11.05,
10.37,
14.69,
9.37,
9.76,
8.79,
10.32,
12.05,
12.32,
7.19,
9.17,
10.16,
11.67,
19.00,
13.53,
13.11,
13.41,
9.32,
14.56,
13.28,
10.50,
10.58,
14.37,
15.07,
12.12,
13.08,
10.32,
11.79,
13.35,
12.21,
13.84,
14.40,
16.14,
14.67,
13.43,
11.18,
12.12,
13.73,
12.93,
12.21,
7.85,
6.69,
12.27,
10.24,
14.40,
17.00,
12.22,
11.00,
9.60,
11.76,
14.06,
7.26,
10.26,
13.20,
14.70,
12.32,
9.05,
13.55,
12.24,
12.05,
11.16,
11.11,
12.78,
13.19,
11.45,
11.37,
11.26,
13.17,
11.47,
12.00,
13.50,
13.00,
12.80,
8.29,
11.74,
9.61,
10.17,
9.88,
10.12,
10.17,
10.68,
11.53,
15.24,
12.50,
15.19,
7.53,
13.79,
7.56,
10.89,
5.16,
9.26,
14.00,
11.89,
12.89,
8.00,
10.85,
11.76,
9.29,
13.69,
13.39,
9.76,
8.90,
12.80,
7.52,
9.89,
13.58,
7.94,
12.22,
14.07,
7.12,
8.37,
9.39,
5.95,
14.00,
11.28,
12.78,
12.68,
11.17,
10.06,
13.56,
11.82,
12.54,
12.67,
11.63,
12.58,
12.00,
13.22,
14.60,
13.37,
9.06,
10.67,
12.74,
10.40,
10.72,
11.37,
12.50,
12.12,
12.39,
12.83,
15.75,
7.96,
10.93,
7.33,
13.39,
10.11,
11.53,
13.12,
11.05,
4.75,
11.06,
13.75,
13.73,
12.11,
9.74,
15.33,
13.84,
14.50,
13.50,
9.91,
10.95,
15.27,
10.61,
8.20,
9.58,
12.37,
4.77,
11.67,
14.47,
10.95,
8.94,
11.94,
10.72,
11.59,
11.17,
11.94,
13.43,
13.16,
16.93,
8.33,
11.32,
10.39,
10.44,
12.21,
14.11,
13.47,
13.53,
12.78,
14.64,
14.32,
13.05,
11.55,
13.27,
15.73,
14.25,
8.21,
8.47,
8.84,
10.67,
10.26,
8.11,
11.25,
14.00,
12.55,
11.81,
10.14,
10.21,
9.57,
7.05,
11.89,
7.54,
11.81,
6.68,
12.00,
12.44,
12.47,
13.06,
10.65,
11.58,
12.21,
10.11,
10.05,
11.16,
13.44,
9.00,
7.47,
11.30,
10.53,
8.19,
6.94,
8.94,
13.05,
13.06,
12.12,
13.42,
11.89,
13.17,
10.22,
15.74,
11.00,
10.21,
11.16,
11.32,
8.63,
11.11,
8.50,
13.30,
12.44,
13.50,
11.40,
10.59,
14.77,
15.41,
13.88,
13.06,
12.88,
15.40,
9.84,
17.00,
12.84,
13.12,
12.06,
10.22,
14.33,
11.47,
11.21,
16.67,
13.14,
10.72,
17.17,
10.68,
10.90,
15.21,
11.81,
15.94,
13.58,
8.56,
8.28,
15.77,
11.00,
15.79,
16.46,
8.94,
14.00,
9.67,
13.56,
13.00,
11.26,
12.95,
9.37,
9.42,
8.74,
11.18,
12.28,
11.73,
9.58,
9.42,
10.84,
13.55,
15.00,
12.78,
13.33,
10.53,
9.50,
11.11,
12.31,
9.94,
12.50,
10.16,
15.18,
15.14,
8.79,
11.47,
13.28,
12.88,
12.58,
11.94,
10.85,
11.72,
13.75,
14.64,
12.84,
14.75,
14.71,
14.69,
7.37,
8.83,
6.95,
12.85,
14.87,
10.44,
10.28,
18.00,
11.10,
13.41,
9.32,
11.68,
13.50,
12.61,
13.29,
13.86,
11.06,
8.89,
14.64,
14.00,
11.56,
12.79,
13.76,
8.61,
14.06,
16.23,
12.78,
10.89,
13.00,
11.76,
13.50,
14.00,
10.39,
13.88,
8.67,
14.80,
14.80,
14.83,
12.33,
15.56,
12.78,
12.94,
14.50,
16.12,
14.00,
15.21,
10.69,
11.25,
13.60,
13.70,
12.32,
9.56,
10.17,
13.80,
12.72,
11.00,
11.88,
12.15,
14.33,
13.11,
14.82,
11.44,
10.67,
11.65,
12.32,
13.93,
6.94,
9.56,
7.95,
9.00,
12.88,
11.28,
10.17,
13.59,
14.00,
9.68,
12.27,
17.40,
15.00,
11.00,
10.56,
11.72,
13.11,
16.07,
10.53,
11.79,
10.11,
11.35,
13.09,
10.72,
9.28,
12.58,
13.29,
12.59,
11.22,
10.47,
9.95,
11.58,
6.52,
12.23,
11.40,
11.00,
12.24,
12.71,
11.33,
12.15,
11.33,
14.94,
12.43,
18.50,
13.00,
13.18,
15.47,
16.50,
14.50,
15.19,
17.00,
15.80,
14.75,
16.64,
14.47,
15.29,
11.00,
13.71,
11.90,
11.50,
10.68,
10.93,
10.31,
15.54,
8.84,
8.21,
9.21,
8.00,
9.56,
12.25,
14.22,
13.57,
12.41,
9.44,
10.68,
10.24,
13.31,
16.25,
15.12,
12.00,
10.58,
12.74,
15.27,
15.67,
13.00,
9.39,
11.33,
10.50,
12.26,
14.06,
13.56,
11.71,
11.71,
15.94,
9.33,
12.56,
12.62,
12.50,
8.16,
8.33,
9.56,
11.82,
8.00,
11.52,
14.58,
12.89,
11.79,
10.78,
12.37,
12.94,
12.53,
9.00,
9.89,
10.68,
9.32,
16.23,
11.39,
10.22,
11.93,
14.69,
11.56,
13.21,
13.06,
10.50,
10.50,
13.14,
16.07,
13.92,
8.40,
7.06,
6.38,
10.56,
6.79,
11.35,
8.79,
14.07,
14.17,
14.11,
11.95,
9.71,
9.22,
11.00,
7.54,
15.07,
11.18,
13.82,
14.19,
15.76,
14.53,
12.47,
14.92,
15.78,
15.78,
11.20,
12.14,
17.67,
14.87,
14.41,
8.96,
11.33,
11.60,
7.32,
15.50,
12.83,
11.39,
8.67,
11.78,
14.17,
6.58,
15.86,
12.82,
14.10,
11.12,
12.23,
11.40,
13.24,
12.43,
11.28,
12.05,
14.41,
11.95,
12.89,
12.19,
14.65,
9.16,
12.63,
11.44,
11.06,
14.59,
12.78,
12.44,
10.67,
12.22,
12.44,
10.89,
10.16,
11.32,
9.72,
12.28,
13.59,
12.68,
11.06,
13.67,
14.28,
9.28,
10.94,
11.72,
8.67,
10.11,
9.47,
8.53,
9.84,
18.00,
15.70,
12.50,
7.41,
10.16,
9.65,
16.58,
10.60,
8.94,
12.35,
13.63,
16.08,
16.00,
13.32,
15.12,
12.79,
12.47,
14.38,
14.53,
14.47,
15.87,
12.76,
8.60,
15.50,
14.08,
4.40,
9.68,
5.05,
5.79,
5.16,
5.68,
12.65,
12.10,
13.53,
14.85,
15.00,
11.84,
6.42,
9.39,
8.42,
9.67,
13.69,
16.25,
14.00,
12.00,
6.96,
12.17,
13.33,
10.00,
16.56,
14.44,
10.83,
3.95,
11.38,
8.10,
10.06,
13.50,
7.39,
7.28,
15.25,
10.17,
11.61,
12.32,
8.11,
7.78,
16.25,
5.74,
12.21,
9.42,
8.83,
10.20,
7.11,
11.33,
10.21,
6.56,
12.92,
11.94,
9.12,
11.56,
8.00,
7.21,
11.16,
13.00,
5.52,
7.33,
15.70,
13.60,
5.44,
11.94,
11.89,
10.26,
10.69,
13.05,
13.69,
14.94,
10.94,
12.63,
9.44,
5.33,
8.53,
6.89,
5.28,
8.13,
8.59,
10.33,
9.45,
8.50,
11.88,
9.39,
7.42,
7.61,
6.12,
5.26,
6.32,
11.88,
12.73,
17.33,
14.50,
8.00,
4.11,
5.63,
6.61,
11.06,
9.59,
17.67,
11.50,
8.63,
8.31,
9.57,
13.89,
12.44,
8.94,
12.60,
8.96,
11.21,
10.28,
5.39,
9.40,
12.11,
12.38,
13.00,
6.22,
8.06,
9.50,
6.67,
12.93,
8.60,
11.86,
9.13,
9.33,
8.70,
12.08,
7.48,
7.63,
15.38,
9.14,
12.28,
16.17,
5.21,
14.90,
13.33,
7.89,
12.44,
9.56,
8.26,
9.00,
10.33,
5.39,
14.56,
11.78,
8.56,
8.28,
9.95,
13.67,
14.42,
14.50,
14.33,
12.44,
6.21,
8.26,
9.51,
9.17,
5.83,
11.35,
12.30,
9.05,
11.95,
11.50,
5.20,
6.55,
7.61,
7.64,
13.09,
8.88,
11.75,
6.59,
9.44,
10.64,
14.00,
8.95,
9.94,
8.17,
12.73,
14.53,
8.50,
10.35,
10.11,
13.12,
7.67,
12.88,
14.75,
10.63,
12.43,
11.33,
13.12,
12.44,
6.74,
7.05,
7.68,
9.42,
7.35,
9.72,
9.29,
11.53,
12.84,
10.61,
8.85,
12.79,
8.94,
10.68,
17.00,
13.15,
12.65,
13.88,
10.67,
5.83,
16.22,
6.39,
7.33,
12.95,
4.40,
14.83,
11.26,
4.84,
12.71,
12.85,
9.45,
11.79,
7.42,
12.55,
15.86,
7.74,
7.78,
2.84,
7.00,
5.80,
9.53,
8.11,
11.20,
11.68,
11.62,
5.26,
7.11,
12.65,
6.62,
14.53,
12.68,
7.60,
10.00,
7.72,
14.00,
12.75,
14.06,
16.20,
17.15,
12.71,
10.16,
11.05,
17.33,
7.01,
9.47,
10.39,
10.74,
10.40,
10.81,
11.47,
13.17,
11.63,
15.87,
14.44,
13.80,
12.12,
7.41,
12.78,
5.55,
13.75,
14.50,
15.71,
11.35,
0.00,
11.85,
7.75,
13.67,
14.69,
7.76,
11.21,
15.00,
10.15,
15.78,
8.80,
10.74,
6.25,
10.44,
0.00,
11.50,
10.12,
10.70,
11.72,
4.42,
8.13,
9.47,
13.41,
12.25,
10.22,
15.00,
20.50,
9.89,
16.62,
17.43,
12.13,
6.94,
14.67,
8.74,
10.95,
4.30,
8.06,
11.56,
13.17,
3.58,
9.32,
9.94,
7.86,
10.50,
11.32,
10.05,
11.44,
11.35,
9.06,
9.89,
14.20,
14.25,
10.50,
14.33,
13.83,
15.88,
12.42,
4.06,
6.76,
13.33,
6.00,
9.58,
6.39,
8.84,
4.28,
8.53,
6.61,
6.71,
7.44,
7.55,
8.42,
9.33,
3.86,
13.00,
11.92,
16.20,
6.35,
6.48,
12.94,
10.69,
11.94,
13.47,
11.32,
15.43,
8.61,
13.09,
11.33,
8.21,
11.08,
11.37,
7.83,
19.00,
4.89,
4.38,
4.56,
5.05,
8.78,
12.36,
12.69,
9.63,
9.50,
6.26,
11.67,
14.00,
14.64,
15.82,
15.15,
15.13,
13.65,
14.00,
10.47,
5.42,
6.05,
12.73,
11.00,
11.50,
12.17,
11.73,
10.85,
11.25,
14.17,
12.80,
10.11,
11.82,
13.29,
11.00,
12.30,
14.67,
16.22,
10.00,
13.12,
3.61,
8.21,
6.88,
6.33,
6.55,
8.00,
3.68,
12.95,
8.63,
4.58,
17.14,
16.57,
16.62,
3.64,
3.54,
7.56,
14.86,
16.00,
13.94,
13.37,
18.67,
10.22,
9.56,
9.65,
14.60,
9.78,
15.78,
13.75,
11.17,
12.30,
14.23,
14.62,
8.80,
10.62,
4.42,
6.37,
6.95,
9.81,
8.06,
15.33,
9.69,
12.21,
9.94,
8.35,
4.15,
6.26,
9.68,
15.43,
7.86,
9.95,
7.24,
4.68,
11.71,
4.63,
12.31,
8.63,
7.89,
10.00,
8.43,
12.57,
10.87,
6.05,
8.84,
8.33,
4.50,
9.12,
10.06,
9.28,
7.68,
9.95,
5.94,
11.28,
8.20,
11.64,
5.35,
15.50,
6.00,
12.67,
18.29,
10.15,
9.91,
8.06,
15.00,
17.80,
13.29,
13.82,
13.44,
9.27,
12.47,
9.00,
13.26,
14.29,
10.56,
17.33,
12.47,
12.10,
14.00,
11.47,
0.00,
8.71,
6.10,
9.47,
14.00,
17.17,
12.00,
8.28,
8.72,
12.72,
12.84,
14.43,
17.25,
10.53,
11.40,
6.44,
10.60,
13.41,
8.28,
14.17,
11.07,
12.95,
14.82,
13.19,
12.94,
14.33,
15.15,
14.58,
12.82,
13.06,
16.17,
7.35,
4.40,
10.50,
15.50,
14.50,
9.83,
3.68,
10.53,
4.84,
11.67,
7.00,
10.33,
11.07,
9.39,
14.00,
12.40,
9.50,
9.42,
11.63,
4.61,
8.22,
9.05,
13.71,
4.15,
14.17,
15.18,
14.00,
12.50,
10.78,
9.21,
12.06,
11.47,
11.81,
14.65,
11.95,
16.38,
11.05,
10.53,
10.42,
12.37,
4.00,
7.00,
10.69,
12.89,
8.39,
5.63,
14.00,
11.79,
13.12,
15.31,
5.22,
10.79,
8.71,
6.72,
9.18,
10.14,
11.00,
10.05,
10.38,
9.61,
10.00,
10.95,
11.00,
9.89,
11.79,
11.00,
9.42,
12.92,
11.82,
12.36,
7.00,
6.79,
15.54,
13.80,
12.67,
14.12,
13.36,
9.72,
14.06,
8.55,
12.14,
3.15,
16.42,
9.92,
10.10,
11.73,
12.69,
11.68,
12.44,
13.83,
15.20,
12.77,
14.69,
13.24,
13.64,
9.74,
14.50,
5.74,
10.94,
7.12,
12.31,
11.33,
14.08,
11.82,
14.50,
8.83,
11.41,
14.75,
10.59,
17.67,
12.72,
5.00,
13.57,
16.91,
16.00,
8.67,
9.47,
10.07,
14.78,
8.56,
4.35,
8.17,
8.00,
12.83,
5.28,
10.89,
14.39,
8.48,
15.17,
4.75,
12.41,
13.48,
14.55,
12.85,
8.05,
12.17,
10.47,
13.33,
14.62,
12.27,
13.47,
10.69,
11.00,
13.00,
14.14,
15.36,
3.79,
9.40,
7.25,
8.95,
5.39,
7.92,
9.16,
10.12,
14.56,
12.29,
12.55,
6.05,
7.78,
9.39,
15.83,
6.44,
15.50,
15.30,
10.58,
9.11,
10.76,
8.33,
10.28,
9.20,
11.05,
13.18,
10.68,
11.68,
9.67,
9.68,
5.45,
6.11,
10.56,
7.78,
11.62,
11.33,
11.68,
13.25,
5.84,
9.44,
10.69,
8.28,
8.78,
12.67,
10.65,
12.76,
10.47,
11.44,
12.67,
13.74,
10.26,
12.07,
6.74,
6.39,
7.79,
9.20,
6.76,
8.22,
12.25,
6.90,
11.75,
8.74,
10.53,
4.60,
12.60,
10.37,
5.62,
8.45,
12.43,
5.47,
9.58,
8.41,
5.78,
7.17,
6.48,
5.53,
10.38,
5.41,
4.44,
7.63,
6.26,
10.80,
11.11,
15.89,
5.79,
14.00,
7.25,
15.00,
7.67,
13.92,
11.84,
5.58,
8.93,
9.75,
16.00,
11.29,
14.69,
15.12,
15.75,
12.92,
13.00,
14.00,
9.50,
8.69,
13.75,
11.38,
11.05,
12.78,
7.84,
11.00,
10.75,
11.17,
5.57,
13.22,
7.28,
5.62,
7.42,
3.00,
9.63,
9.17,
14.79,
14.50,
14.28,
11.50,
12.11,
11.67,
15.00,
14.00,
15.42,
8.42,
9.89,
16.00,
6.89,
10.00,
11.28,
11.17,
10.00,
11.15,
12.87,
14.56,
11.16,
10.94,
11.21,
11.58,
11.50,
11.35,
12.53,
11.68,
13.00,
8.37,
13.33,
8.81,
10.68,
11.20,
9.84,
10.39,
13.83,
15.90,
15.50,
12.65,
4.74,
5.06,
10.21,
9.64,
11.56,
8.45,
9.50,
6.05,
7.84,
9.11,
10.94,
12.22,
11.67,
14.00,
9.53,
13.06,
6.84,
11.88,
14.55,
6.60,
11.68,
13.57,
13.10,
13.05,
14.44,
12.82,
14.71,
8.88,
10.12,
7.80,
10.46,
4.53,
12.82,
10.28,
10.16,
11.06,
12.11,
13.06,
4.74,
9.83,
12.17,
8.52,
4.28,
10.05,
18.57,
10.82,
13.43,
6.00,
12.33,
11.50,
11.95,
7.89,
11.67,
13.00,
15.17,
15.15,
11.06,
11.33,
14.78,
12.19,
12.17,
16.50,
15.93,
13.75,
12.90,
11.11,
14.92,
14.75,
4.89,
14.50,
14.12,
11.17,
14.78,
14.70,
11.75,
12.84,
13.45,
11.53,
14.19,
16.73,
15.94,
8.83,
16.27,
15.71,
15.59,
6.14,
4.95,
13.15,
13.33,
9.37,
8.70,
12.89,
11.79,
13.73,
17.00,
17.00,
17.85,
11.67,
15.50,
4.30,
6.16,
11.71,
5.79,
5.95,
10.06,
15.25,
3.75,
14.64,
11.65,
13.57,
9.50,
5.89,
11.75,
13.47,
7.72,
10.12,
5.63,
9.22,
10.16,
8.65,
8.80,
8.80,
12.93,
7.30,
9.06,
11.71,
10.68,
9.00,
7.93,
4.74,
10.59,
7.76,
11.05,
13.76,
15.53,
4.05,
5.89,
7.95,
9.33,
7.12,
11.33,
10.11,
11.80,
10.44,
6.11,
7.48,
9.00,
4.76,
13.00,
5.94,
7.95,
14.50,
12.00,
7.00,
3.69,
10.50,
10.89,
6.33,
11.59,
8.84,
8.89,
7.44,
12.50,
6.67,
7.16,
9.67,
16.27,
10.38,
9.21,
11.45,
6.61,
9.35,
14.83,
11.89,
12.00,
10.94,
6.30,
9.22,
7.38,
9.53,
12.10,
8.42,
8.17,
7.16,
14.11,
14.70,
14.00,
14.64,
4.85,
11.59,
11.22,
9.32,
11.44,
7.68,
7.42,
12.11,
8.42,
13.00,
10.56,
13.44,
11.56,
10.89,
14.92,
12.59,
12.22,
14.00,
13.82,
15.00,
14.24,
11.91,
9.30,
9.94,
11.26,
11.50,
11.75,
15.25,
10.50,
13.75,
15.06,
7.47,
8.31,
4.42,
7.41,
10.94,
10.80,
17.22,
13.90,
3.79,
15.29,
14.22,
10.39,
12.20,
10.06,
8.95,
5.42,
15.27,
12.06,
13.91,
12.40,
5.83,
14.42,
10.69,
13.63,
13.76,
12.15,
8.40,
7.00,
13.27,
10.44,
13.75,
5.58,
5.40,
5.79,
8.58,
12.11,
12.88,
12.91,
9.86,
11.44,
10.11,
14.38,
9.50,
11.10,
9.63,
14.86,
9.90,
11.00,
13.90,
15.12,
15.40,
14.47,
14.25,
14.71,
13.78,
13.37,
15.37,
15.82,
13.88,
7.61,
6.53,
3.95,
6.39,
12.27,
8.25,
6.10,
11.60,
10.89,
9.00,
8.60,
11.29,
8.56,
14.00,
13.00,
7.89,
12.00,
5.42,
8.61,
7.78,
9.83,
6.84,
11.94,
11.17,
9.57,
11.63,
10.28,
11.12,
13.21,
12.70,
12.29,
11.08,
17.14,
7.71,
13.28,
10.17,
14.79,
14.00,
14.00,
7.44,
8.79,
11.06,
11.78,
14.05,
11.50,
10.25,
12.73,
8.45,
7.95,
10.55,
6.86,
9.05,
12.83,
5.74,
8.94,
8.22,
8.35,
11.65,
11.58,
12.00,
9.58,
8.84,
10.40,
8.95,
6.55,
16.29,
14.20,
12.65,
8.67,
12.20,
16.00,
8.26,
10.13,
9.94,
11.05,
13.06,
10.45,
6.74,
14.33,
16.75,
12.22,
11.69,
9.82,
12.11,
10.58,
9.83,
11.71,
12.47,
12.76,
14.67,
14.90,
14.95,
9.69,
8.16,
14.94,
17.25,
11.24,
9.86,
12.82,
12.33,
11.06,
9.67,
11.94,
11.58,
10.94,
15.25,
3.89,
8.00,
8.40,
5.47,
8.50,
8.74,
5.80,
11.36,
9.83,
8.17,
10.47,
4.24,
11.27,
13.39,
10.72,
6.94,
7.05,
10.37,
11.47,
10.58,
11.67,
11.16,
13.33,
12.04,
13.10,
10.84,
11.21,
8.05,
12.73,
10.44,
12.69,
4.05,
9.68,
10.31,
8.12,
7.06,
4.77,
8.17,
8.78,
9.12,
8.89,
7.59,
7.22,
10.80,
8.60,
7.71,
7.22,
9.10,
10.21,
12.47,
10.00,
11.27,
9.21,
14.94,
15.35,
5.95,
11.56,
10.06,
12.56,
15.62,
14.67,
13.56,
5.78,
6.67,
5.00,
6.26,
16.08,
5.83,
9.33,
11.00,
13.59,
4.54,
8.50,
7.94,
6.61,
10.00,
9.53,
9.82,
16.15,
15.00,
10.26,
7.84,
13.11,
14.60,
14.50,
7.68,
5.17,
8.44,
11.06,
7.79,
15.67,
7.26,
12.32,
9.16,
8.50,
9.68,
10.72,
4.70,
14.18,
14.14,
13.08,
12.28,
6.55,
12.16,
12.72,
12.60,
10.17,
10.56,
8.47,
13.79,
9.11,
12.61,
8.78,
9.00,
10.44,
11.70,
9.78,
12.65,
11.11,
12.94,
12.95,
13.05,
14.69,
13.74,
13.81,
6.53,
8.61,
5.89,
15.12,
12.50,
15.00,
12.84,
13.79,
15.50,
12.53,
13.77,
7.63,
18.22,
14.50,
9.06,
10.61,
5.48,
15.27,
15.88,
7.33,
10.47,
8.90,
9.00,
8.47,
17.00,
14.55,
11.78,
13.31,
13.53,
11.21,
11.56,
12.00,
5.89,
15.69,
6.89,
10.58,
9.15,
9.83,
3.61,
4.25,
9.74,
10.46,
5.37,
4.94,
7.16,
9.83,
13.09,
14.60,
14.42,
14.29,
11.18,
9.00,
13.69,
10.11,
11.67,
11.11,
11.28,
12.22,
12.16,
12.21,
15.00,
12.67,
14.22,
13.88,
10.77,
13.13,
13.13,
10.00,
13.14,
9.26,
13.60,
17.40,
16.11,
14.56,
12.67,
15.00,
13.50,
14.17,
16.25,
16.92,
16.40,
11.54,
12.30,
10.53,
15.17,
13.47,
10.35,
11.47,
12.80,
14.58,
8.32,
14.44,
16.85,
4.35,
14.50,
12.19,
13.33,
5.15,
8.80,
12.47,
14.00,
16.56,
13.08,
9.60,
12.80,
6.53,
8.74,
10.28,
12.04,
13.25,
10.37,
10.72,
12.78,
17.28,
15.40,
13.75,
12.37,
16.67,
16.06,
13.18,
3.55,
8.42,
11.06,
13.73,
10.12,
10.80,
10.65,
9.11,
8.78,
9.42,
14.10,
8.89,
15.93,
11.58,
10.32,
5.89,
10.43,
10.47,
7.79,
9.50,
12.31,
5.52,
5.95,
5.37,
13.00,
12.62,
14.09,
12.78,
9.78,
12.57,
14.31,
15.00,
14.93,
12.67,
11.72,
6.10,
8.80,
10.94,
11.65,
15.00,
15.25,
9.53,
9.44,
8.63,
12.47,
9.70,
8.74,
8.47,
12.30,
9.89,
10.47,
14.67,
17.89,
15.18,
12.83,
6.95,
9.58,
13.08,
11.93,
12.00,
4.65,
6.25,
6.11,
6.00,
4.72,
8.47,
11.93,
6.55,
13.06,
11.63,
10.84,
10.94,
11.70,
12.92,
11.00,
12.67,
14.33,
12.93,
11.78,
13.28,
9.94,
12.06,
10.50,
10.56,
14.33,
9.59,
10.72,
7.63,
15.50,
16.00,
8.80,
11.76,
8.94,
20.00,
4.68,
10.53,
6.94,
13.00,
7.94,
7.44,
13.40,
15.08,
14.56,
14.06,
14.06,
13.31,
13.63,
11.83,
12.33,
13.50,
12.29,
14.33,
13.75,
5.63,
13.69,
15.38,
17.00,
16.75,
11.21,
12.74,
12.73,
16.33,
13.40,
10.89,
9.58,
12.33,
11.37,
13.67,
12.00,
13.67,
15.67,
18.00,
6.78,
9.94,
12.81,
14.11,
10.94,
11.05,
11.22,
10.37,
16.67,
14.33,
11.75,
9.18,
9.89,
13.94,
11.40,
1.89,
11.21,
7.39,
11.71,
12.29,
13.17,
14.86,
7.72,
7.62,
3.11,
12.50,
7.67,
9.94,
10.05,
9.40,
16.00,
12.44,
12.00,
10.56,
10.35,
12.95,
8.95,
11.44,
12.39,
13.76,
16.67,
5.60,
9.89,
11.22,
13.00,
12.30,
16.38,
12.25,
7.00,
11.29,
8.22,
14.53,
8.78,
12.33,
6.83,
12.95,
9.58,
12.95,
8.80,
13.29,
12.11,
14.69,
10.56,
11.85,
11.72,
12.16,
14.17,
14.71,
14.67,
11.10,
10.61,
11.26,
11.33,
8.89,
9.44,
8.39,
9.42,
9.42,
11.92,
12.28,
9.24,
6.83,
6.89,
11.44,
13.21,
8.35,
5.89,
11.88,
17.33,
9.55,
9.11,
12.56,
11.07,
7.20,
8.63,
12.28,
12.56,
14.08,
7.72,
11.00,
14.00,
15.12,
9.28,
9.17,
13.74,
13.85,
7.57,
10.59,
10.79,
9.16,
15.62,
10.10,
12.12,
4.60,
5.60,
5.94,
9.41,
11.71,
10.00,
16.00,
10.95,
14.25,
9.08,
10.00,
9.32,
12.81,
12.47,
12.56,
14.00,
7.05,
9.74,
12.80,
18.33,
6.85,
9.11,
14.43,
8.84,
8.50,
12.60,
10.11,
12.00,
14.00,
15.00,
14.11,
11.00,
8.47,
10.89,
14.00,
11.21,
8.78,
10.26,
9.80,
11.33,
9.89,
10.35,
11.47,
6.48,
4.89,
7.16,
14.06,
12.11,
11.25,
12.25,
8.89,
10.83,
11.89,
12.53,
14.00,
14.44,
10.39,
16.50,
13.00,
13.91,
8.28,
12.00,
13.13,
12.80,
16.00,
7.17,
12.89,
5.16,
12.16,
5.83,
7.54,
10.63,
9.28,
10.50,
3.80,
8.56,
11.69,
10.00,
10.14,
11.11,
15.43,
11.74,
13.00,
13.19,
10.00,
8.27,
14.47,
9.21,
9.50,
10.30,
11.32,
11.71,
6.95,
5.61,
11.62,
9.00,
4.80,
7.33,
15.18,
15.88,
15.35,
10.52,
12.33,
12.00,
11.18,
11.83,
12.69,
9.17,
10.05,
10.90,
10.00,
9.42,
14.72,
11.22,
9.11,
10.59,
14.67,
6.50,
12.84,
10.62,
10.50,
9.56,
11.65,
14.00,
13.39,
10.58,
13.92,
12.08,
9.00,
14.58,
12.21,
12.53,
13.68,
14.94,
4.62,
11.89,
5.72,
8.43,
6.52,
7.56,
9.83,
12.50,
11.95,
11.15,
6.74,
10.25,
8.67,
16.20,
8.06,
11.68,
12.41,
12.58,
15.00,
13.37,
10.05,
8.35,
11.22,
11.83,
5.56,
8.22,
9.17,
6.78,
9.56,
9.83,
13.27,
13.93,
11.32,
13.89,
15.83,
14.75,
15.82,
16.07,
15.83,
12.00,
10.31,
14.42,
13.20,
14.06,
7.56,
11.27,
5.10,
13.70,
9.25,
10.58,
11.00,
14.14,
10.33,
12.50,
12.93,
11.26,
12.46,
12.44,
13.91,
15.08,
13.00,
19.75,
15.55,
12.67,
12.06,
11.79,
10.58,
7.60,
5.65,
4.50,
8.32,
7.74,
11.93,
13.12,
10.37,
6.10,
8.28,
13.17,
14.80,
8.08,
7.11,
13.40,
3.28,
11.38,
6.89,
8.94,
13.80,
9.83,
10.94,
4.74,
6.78,
11.53,
13.30,
4.00,
10.60,
4.79,
6.10,
8.45,
10.75,
10.44,
11.95,
4.53,
8.29,
8.17,
9.28,
9.16,
7.53,
6.05,
7.56,
4.42,
6.00,
11.33,
11.15,
6.43,
9.33,
6.00,
7.83,
8.85,
10.95,
10.61,
12.88,
12.37,
9.50,
6.83,
10.26,
10.22,
11.00,
11.11,
12.81,
12.38,
11.63,
13.16,
11.05,
11.17,
8.68,
10.53,
7.53,
12.90,
4.89,
14.77,
9.67,
14.53,
11.59,
12.37,
11.44,
10.47,
12.06,
10.86,
7.67,
10.00,
14.23,
9.70,
13.60,
13.83,
4.74,
5.72,
8.90,
7.50,
15.72,
12.89,
14.95,
15.36,
18.92,
15.67,
14.30,
12.93,
10.20,
10.82,
13.81,
11.75,
14.10,
13.08,
16.12,
12.94,
12.53,
14.29,
13.63,
15.11,
11.63,
12.24,
16.10,
8.85,
9.79,
10.12,
13.17,
10.89,
6.58,
4.21,
5.47,
10.32,
8.20,
7.78,
10.95,
13.00,
11.12,
11.39,
12.37,
15.40,
11.16,
9.47,
13.82,
8.32,
11.42,
11.17,
6.47,
6.40,
14.50,
9.47,
10.84,
14.91,
13.56,
9.11,
15.83,
15.10,
15.62,
10.21,
15.22,
10.23,
12.88,
12.68,
13.44,
13.44,
14.56,
11.75,
12.57,
10.83,
12.67,
10.39,
14.62,
10.11,
8.84,
15.22,
10.32,
11.11,
13.12,
8.06,
11.37,
5.89,
4.00,
12.33,
13.00,
9.26,
11.65,
9.80,
11.12,
8.11,
9.67,
11.44,
13.00,
12.90,
13.50,
12.32,
8.74,
9.41,
15.57,
10.68,
13.10,
10.19,
11.06,
18.00,
10.47,
13.89,
13.33,
5.68,
11.88,
5.89,
8.41,
15.75,
16.31,
12.00,
15.86,
15.25,
9.89,
12.47,
12.67,
16.11,
13.50,
14.50,
12.31,
4.28,
6.32,
11.26,
7.58,
8.00,
12.60,
15.22,
7.20,
5.05,
15.25,
12.75,
12.00,
15.00,
12.28,
16.31,
5.61,
9.63,
14.53,
15.69,
15.80,
12.85,
13.64,
12.61,
11.00,
11.39,
12.61,
12.67,
11.80,
15.38,
11.59,
13.72,
12.33,
14.53,
14.50,
15.45,
14.88,
14.07,
14.73,
16.07,
13.83,
12.00,
19.00,
7.67,
11.11,
8.84,
12.33,
8.89,
14.00,
8.56,
13.88,
17.06,
19.21,
15.42,
13.21,
15.24,
11.15,
12.74,
15.00,
14.13,
14.24,
15.57,
18.27,
11.47,
10.16,
12.39,
11.06,
12.06,
11.59,
12.33,
12.22,
9.29,
13.70,
14.67,
12.12,
12.60,
6.19,
16.14,
12.25,
10.44,
12.61,
12.76,
13.79,
12.68,
13.50,
13.28,
13.94,
14.00,
13.89,
16.00,
14.27,
17.40,
15.18,
16.80,
15.67,
14.75,
12.12,
13.21,
15.83,
14.00,
14.56,
12.17,
6.25,
13.23,
14.00,
15.48,
16.86,
9.16,
10.11,
12.80,
11.72,
11.28,
7.67,
10.32,
9.21,
14.62,
11.88,
7.37,
13.25,
4.70,
11.85,
10.55,
10.89,
9.83,
11.56,
10.25,
10.28,
9.56,
9.20,
11.43,
11.53,
10.11,
6.21,
10.50,
11.18,
11.53,
10.28,
9.62,
12.44,
13.93,
9.72,
10.89,
8.94,
13.16,
9.78,
9.46,
9.55,
10.89,
9.35,
11.22,
8.50,
5.63,
14.40,
10.00,
11.74,
9.62,
9.67,
9.94,
18.20,
6.78,
10.87,
13.18,
17.00,
8.05,
9.74,
11.05,
7.65,
10.22,
12.00,
12.75,
11.62,
16.67,
12.59,
12.76,
14.00,
10.60,
13.00,
12.59,
8.20,
13.73,
12.00,
3.37,
8.94,
6.37,
4.89,
13.86,
10.27,
6.32,
8.17,
13.21,
15.83,
14.11,
10.78,
14.08,
14.00,
10.05,
10.35,
9.37,
15.30,
15.25,
6.47,
7.06,
8.94,
11.11,
12.88,
16.17,
10.67,
15.67,
14.14,
14.56,
8.00,
12.22,
12.50,
13.60,
8.74,
12.47,
14.88,
10.45,
11.15,
13.00,
13.33,
11.11,
13.06,
12.06,
5.37,
10.47,
12.62,
12.38,
10.90,
9.89,
11.78,
4.24,
9.53,
8.05,
8.67,
14.00,
10.90,
11.12,
12.28,
10.29,
7.25,
9.00,
13.17,
9.56,
12.24,
14.00,
11.33,
13.94,
10.89,
8.11,
8.72,
11.31,
11.68,
10.47,
7.42,
12.81,
9.61,
7.50,
10.32,
18.50,
9.06,
9.11,
9.83,
10.60,
9.21,
10.75,
12.86,
5.94,
12.90,
7.90,
12.83,
5.82,
11.88,
7.26,
10.40,
12.00,
13.29,
11.12,
6.80,
9.30,
10.89,
13.94,
4.89,
12.07,
13.46,
9.56,
14.18,
15.85,
16.00,
14.92,
15.29,
12.24,
11.93,
14.12,
13.00,
5.50,
6.68,
12.26,
11.11,
11.79,
9.83,
11.47,
12.68,
13.00,
14.33,
9.11,
12.08,
12.67,
9.05,
9.17,
8.56,
13.91,
11.58,
10.39,
9.22,
12.11,
16.14,
11.87,
12.13,
10.56,
14.21,
12.41,
12.56,
10.00,
12.67,
12.71,
11.57,
10.11,
10.00,
12.25,
9.22,
17.33,
14.41,
11.00,
7.22,
9.60,
10.53,
11.00,
12.56,
10.89,
9.39,
11.59,
11.18,
10.10,
10.64,
11.63,
12.11,
11.06,
11.16,
11.33,
9.42,
10.05,
9.67,
13.13,
9.65,
9.67,
11.67,
12.17,
14.94,
8.58,
8.26,
14.90,
14.45,
15.13,
16.06,
12.56,
8.72,
11.45,
11.71,
9.47,
12.67,
10.22,
10.11,
11.85,
13.33,
12.72,
13.31,
6.33,
13.80,
10.95,
7.22,
9.95,
9.78,
13.00,
9.33,
8.25,
11.24,
11.58,
12.67,
9.23,
6.35,
6.95,
5.78,
6.39,
9.65,
15.08,
12.78,
10.21,
8.37,
11.72,
12.42,
9.16,
10.00,
8.39,
8.52,
9.15,
9.68,
11.35,
13.29,
15.25,
13.76,
13.89,
16.92,
12.65,
15.12,
13.76,
14.38,
6.83,
4.72,
11.94,
5.11,
7.05,
8.11,
15.80,
13.33,
13.07,
7.72,
11.90,
9.65,
9.67,
8.72,
10.94,
11.58,
12.25,
11.65,
10.00,
11.22,
12.00,
9.05,
9.79,
12.22,
9.06,
13.73,
12.60,
13.92,
9.68,
5.83,
8.21,
13.75,
11.22,
10.79,
14.10,
12.40,
8.58,
13.68,
14.23,
13.89,
12.74,
10.72,
11.17,
10.68,
10.89,
16.57,
12.32,
10.31,
10.63,
9.39,
15.00,
13.17,
15.47,
13.31,
13.94,
14.73,
13.42,
17.17,
13.10,
12.32,
13.15,
12.00,
9.55,
9.28,
6.50,
9.10,
9.33,
9.89,
13.80,
8.12,
8.95,
9.26,
18.60,
11.35,
7.28,
7.33,
11.84,
12.00,
10.61,
12.94,
11.32,
11.56,
9.74,
13.70,
14.55,
13.40,
11.60,
10.22,
9.06,
15.83,
2.22,
7.95,
10.67,
13.39,
10.17,
1.58,
2.50,
10.12,
13.71,
12.19,
10.44,
13.83,
10.26,
10.80,
15.92,
17.11,
12.56,
13.11,
5.11,
9.67,
9.32,
11.29,
11.41,
11.39,
8.32,
12.13,
13.06,
11.38,
11.80,
9.94,
10.24,
14.07,
9.68,
15.14,
10.29,
4.21,
11.46,
13.92,
12.70,
11.22,
16.00,
15.75,
13.78,
13.00,
13.70,
14.06,
12.43,
13.32,
13.06,
11.35,
12.11,
12.39,
13.20,
14.81,
14.14,
12.59,
15.50,
13.91,
12.94,
12.15,
12.64,
13.85,
12.21,
6.74,
11.05,
16.53,
12.12,
11.21,
15.33,
11.11,
13.47,
14.44,
15.38,
12.79,
15.08,
10.12,
13.92,
11.28,
4.58,
13.06,
10.67,
11.53,
5.79,
7.95,
8.45,
10.59,
3.53,
11.72,
11.58,
6.58,
12.00,
9.37,
4.83,
8.24,
11.71,
8.78,
5.50,
12.82,
6.00,
8.47,
11.59,
9.22,
13.05,
12.58,
10.30,
11.17,
11.80,
14.20,
15.86,
12.22,
13.00,
5.22,
14.25,
5.11,
9.37,
9.39,
12.92,
14.88,
9.53,
10.62,
12.50,
13.38,
11.39,
14.12,
12.37,
13.83,
9.75,
12.40,
14.50,
13.29,
9.72,
14.17,
3.78,
12.40,
13.86,
10.53,
15.38,
17.30,
11.93,
9.53,
4.00,
8.67,
9.89,
13.00,
11.47,
13.22,
15.70,
16.57,
15.42,
8.78,
9.84,
12.35,
11.90,
12.43,
12.53,
15.67,
10.20,
13.11,
11.78,
13.31,
19.60,
12.06,
9.78,
9.00,
14.12,
6.10,
8.31,
14.29,
8.47,
10.62,
10.38,
7.11,
14.43,
5.74,
8.32,
2.63,
14.17,
9.74,
9.58,
7.55,
13.88,
10.12,
7.39,
8.55,
10.73,
13.55,
12.22,
14.00,
6.78,
8.83,
10.16,
9.90,
10.60,
12.29,
10.56,
11.06,
11.61,
12.19,
11.80,
7.42,
7.95,
6.53,
9.21,
10.65,
7.78,
5.05,
7.17,
10.89,
7.74,
13.27,
9.12,
10.27,
7.95,
10.41,
11.33,
7.71,
8.53,
6.15,
11.20,
10.56,
7.53,
7.61,
17.17,
9.21,
10.32,
9.94,
11.05,
4.94,
10.35,
6.78,
10.32,
5.40,
11.47,
3.58,
6.37,
10.60,
7.32,
9.15,
7.68,
7.63,
4.62,
6.58,
8.41,
3.56,
10.32,
10.00,
6.76,
6.89,
13.71,
8.44,
15.83,
4.58,
14.22,
17.50,
8.00,
14.10,
15.25,
8.25,
14.12,
13.50,
10.35,
9.56,
4.05,
7.11,
11.32,
11.11,
14.13,
5.42,
13.00,
12.81,
11.00,
10.67,
8.32,
12.84,
13.53,
14.50,
14.00,
10.35,
5.11,
9.84,
9.06,
9.50,
5.15,
11.29,
8.06,
17.00,
13.18,
13.13,
8.88,
9.22,
5.65,
13.71,
17.78,
12.00,
12.24,
13.69,
11.67,
15.00,
17.33,
8.68,
7.11,
10.22,
12.83,
14.76,
12.39,
13.18,
12.50,
13.22,
8.94,
8.33,
11.89,
10.61,
10.42,
13.22,
15.05,
7.79,
12.63,
7.79,
11.72,
7.95,
14.16,
15.38,
11.26,
11.89,
13.47,
12.55,
10.80,
13.00,
10.72,
12.41,
7.47,
7.15,
10.86,
13.20,
11.70,
10.56,
10.05,
10.22,
5.47,
8.42,
7.60,
6.21,
14.05,
14.06,
13.05,
14.00,
13.33,
9.74,
13.42,
8.79,
8.26,
13.00,
9.89,
10.76,
9.61,
11.22,
17.86,
8.45,
9.06,
14.83,
8.79,
13.00,
14.50,
11.94,
13.67,
8.55,
5.78,
14.33,
6.44,
6.56,
3.81,
7.28,
10.88,
11.86,
7.11,
11.67,
13.93,
11.41,
10.94,
8.75,
10.17,
7.10,
11.82,
10.39,
10.94,
12.91,
11.67,
9.77,
10.00,
4.58,
7.63,
15.80,
13.20,
9.47,
4.95,
12.22,
10.47,
10.93,
15.90,
16.18,
10.00,
8.78,
10.83,
13.81,
9.84,
9.28,
12.74,
10.44,
11.53,
12.94,
13.73,
12.37,
10.59,
7.22,
9.50,
13.15,
11.75,
8.90,
13.05,
8.85,
8.44,
13.50,
2.72,
17.60,
16.56,
16.71,
15.25,
16.36,
9.50,
16.64,
15.27,
14.54,
13.33,
11.94,
15.67,
10.31,
11.01,
3.81,
9.00,
9.74,
6.89,
10.62,
10.21,
13.38,
12.89,
11.53,
11.37,
11.89,
8.61,
10.32,
7.11,
11.25,
10.72,
10.53,
13.95,
7.59,
15.00,
14.33,
6.86,
14.00,
9.10,
9.21,
14.30,
5.42,
8.45,
16.00,
11.33,
13.13,
12.44,
4.68,
7.05,
3.68,
10.06,
9.89,
10.29,
4.29,
6.67,
13.67,
16.00,
2.53,
14.75,
10.53,
15.00,
17.25,
4.79,
8.44,
13.17,
14.53,
14.06,
14.50,
14.12,
15.38,
13.11,
12.39,
14.00,
12.36,
9.06,
9.67,
11.44,
6.72,
7.57,
9.57,
12.14,
12.38,
8.75,
13.88,
16.22,
8.05,
16.12,
6.00,
13.07,
15.50,
15.43,
7.74,
6.89,
12.39,
13.47,
14.07,
9.84,
11.78,
9.00,
9.20,
8.35,
9.62,
12.33,
12.08,
8.50,
11.84,
13.67,
11.71,
12.11,
8.56,
6.16,
11.11,
15.30,
11.17,
8.90,
4.90,
8.63,
10.73,
10.84,
8.94,
11.39,
12.47,
9.70,
12.54,
9.60,
11.88,
10.05,
10.20,
11.21,
9.84,
7.15,
10.31,
11.60,
12.28,
15.50,
5.41,
6.85,
7.49,
7.35,
10.83,
9.44,
10.42,
5.43,
8.62,
7.32,
13.17,
11.75,
13.38,
14.79,
13.27,
7.55,
13.22,
16.12,
9.53,
3.00,
9.78,
9.65,
5.00,
10.53,
6.63,
11.58,
16.25,
14.29,
14.11,
14.67,
15.00,
14.76,
15.33,
14.23,
15.07,
8.95,
8.94,
13.44,
3.56,
10.56,
10.11,
5.32,
9.00,
9.15,
12.86,
9.88,
7.32,
13.31,
13.11,
14.28,
7.47,
12.32,
10.53,
9.84,
10.37,
15.92,
12.16,
11.83,
11.33,
12.58,
10.85,
11.74,
11.42,
9.83,
16.67,
7.20,
5.06,
6.47,
10.90,
14.33,
6.74,
11.06,
14.88,
14.40,
16.67,
17.06,
14.60,
16.67,
8.00,
14.19,
14.40,
15.93,
16.71,
15.40,
15.50,
6.37,
17.92,
15.75,
13.75,
15.17,
7.05,
9.72,
9.67,
12.07,
10.50,
6.79,
7.30,
10.71,
5.11,
12.93,
7.00,
10.47,
12.23,
13.75,
10.75,
16.00,
7.80,
14.33,
10.94,
13.42,
12.19,
16.50,
16.71,
16.00,
14.00,
17.12,
14.71,
15.27,
15.93,
13.85,
13.65,
14.22,
14.42,
16.18,
12.06,
13.64,
13.73,
15.40,
16.13,
16.81,
16.65,
14.85,
18.54,
16.64,
16.11,
14.05,
14.11,
15.17,
13.75,
14.11,
13.56,
13.84,
13.58,
16.18,
15.82,
11.12,
10.19,
13.18,
12.11,
13.11,
12.00,
12.58,
13.80,
11.43,
5.07,
17.00,
4.72,
10.17,
6.90,
8.26,
12.33,
11.29,
10.95,
10.45,
8.63,
7.20,
6.16,
11.11,
11.38,
7.53,
9.37,
7.20,
9.56,
9.22,
11.39,
14.53,
9.42,
5.72,
5.78,
7.50,
6.88,
10.00,
10.76,
10.39,
8.89,
9.74,
9.14,
10.00,
12.77,
7.56,
4.35,
14.59,
15.23,
10.31,
7.37,
8.26,
3.95,
5.17,
12.85,
14.06,
7.59,
5.75,
8.78,
12.00,
6.50,
10.22,
7.00,
9.71,
9.12,
13.88,
8.29,
15.33,
11.44,
3.61,
10.17,
10.74,
13.78,
9.12,
8.17,
5.78,
7.82,
8.81,
10.78,
5.21,
8.00,
5.94,
9.47,
6.10,
12.13,
6.67,
9.82,
3.72,
7.17,
16.79,
16.58,
15.36,
13.40,
12.29,
8.61,
13.30,
11.31,
6.82,
3.23,
11.85,
13.33,
6.69,
8.89,
9.37,
5.91,
7.94,
8.88,
9.05,
8.88,
8.57,
14.25,
10.35,
14.12,
11.73,
14.06,
13.00,
16.00,
10.72,
14.38,
13.63,
10.22,
11.88,
2.72,
7.96,
16.25,
11.39,
10.67,
9.74,
10.05,
13.75,
11.78,
5.25,
13.33,
8.89,
11.24,
5.62,
16.19,
8.14,
12.17,
13.71,
6.11,
16.44,
15.89,
4.50,
9.78,
8.21,
9.06,
15.92,
5.33,
12.17,
11.12,
11.16,
14.25,
11.21,
10.67,
11.72,
13.80,
11.00,
16.17,
12.56,
11.79,
12.59,
12.16,
7.89,
12.06,
13.24,
14.17,
12.31,
12.16,
13.78,
12.71,
13.60,
12.17,
14.33,
15.00,
10.78,
14.50,
14.58,
10.38,
11.50,
13.32,
9.74,
14.17,
12.17,
15.00,
10.48,
5.57,
13.00,
13.00,
12.53,
11.85,
10.21,
13.16,
12.22,
11.78,
11.00,
14.08,
8.32,
11.16,
8.53,
12.21,
11.72,
9.35,
12.00,
12.62,
13.68,
12.38,
10.33,
11.70,
10.90,
10.83,
10.94,
12.63,
11.44,
10.89,
11.76,
12.00,
14.88,
12.16,
14.67,
10.24,
15.35,
9.63,
10.00,
10.79,
10.50,
10.50,
11.17,
13.31,
14.17,
12.93,
12.57,
12.67,
11.95,
16.00,
10.82,
12.94,
13.00,
10.95,
9.67,
11.78,
12.89,
10.79,
10.58,
11.00,
11.28,
17.23,
6.95,
12.33,
13.44,
11.29,
10.39,
7.74,
9.47,
12.75,
14.05,
13.05,
9.11,
10.74,
13.79,
11.00,
10.63,
10.63,
14.62,
9.53,
17.33,
9.28,
8.94,
12.11,
3.44,
5.30,
9.74,
5.89,
9.00,
12.82,
6.56,
8.28,
8.61,
9.84,
5.53,
12.89,
11.75,
11.28,
7.20,
8.82,
14.82,
6.55,
10.26,
7.47,
12.88,
12.21,
10.28,
8.80,
10.65,
7.95,
9.12,
10.15,
8.22,
10.88,
8.58,
2.95,
11.11,
5.32,
10.29,
16.80,
12.65,
7.44,
13.21,
12.11,
7.37,
5.47,
3.89,
14.67,
13.05,
10.37,
14.60,
14.11,
13.56,
13.38,
12.78,
8.47,
6.40,
6.37,
8.53,
6.39,
9.94,
5.21,
10.94,
7.35,
8.06,
9.44,
9.89,
11.11,
15.00,
13.81,
11.53,
9.07,
7.28,
9.63,
9.94,
13.25,
15.00,
9.50,
13.67,
10.26,
13.56,
14.71,
11.71,
5.67,
12.38,
10.95,
16.38,
5.25,
8.61,
5.41,
12.72,
9.32,
14.00,
11.05,
10.00,
15.23,
12.25,
17.43,
10.83,
12.82,
13.58,
11.40,
8.21,
9.39,
11.05,
11.11,
12.17,
10.94,
9.18,
12.71,
7.32,
9.59,
11.00,
11.53,
15.37,
14.44,
9.70,
9.26,
3.94,
9.55,
7.83,
8.32,
10.55,
9.32,
8.72,
14.61,
12.95,
8.83,
15.25,
15.17,
14.71,
8.39,
13.93,
13.73,
13.19,
5.84,
9.75,
6.95,
8.21,
14.14,
10.94,
11.53,
5.21,
7.83,
7.24,
11.45,
10.26,
8.83,
13.67,
10.53,
9.74,
9.72,
10.95,
8.89,
12.31,
6.67,
10.28,
7.45,
11.35,
10.00,
11.21,
17.36,
17.16,
15.29,
11.87,
14.82,
7.35,
11.90,
11.67,
11.08,
5.83,
13.72,
9.11,
5.83,
11.39,
4.50,
7.49,
14.33,
15.12,
8.53,
8.53,
14.50,
13.67,
9.39,
11.37,
5.44,
16.90,
15.71,
10.00,
9.44,
7.94,
13.83,
13.95,
9.74,
11.21,
12.72,
10.74,
11.95,
10.25,
14.50,
11.68,
10.95,
12.21,
11.82,
14.53,
12.89,
14.50,
12.72,
13.16,
10.94,
12.56,
13.05,
10.39,
14.80,
16.33,
7.95,
11.75,
10.78,
14.70,
11.83,
10.35,
15.88,
13.67,
11.87,
12.06,
15.09,
10.63,
9.53,
9.32,
9.32,
12.05,
9.12,
9.53,
9.70,
10.84,
9.28,
9.61,
10.89,
11.50,
13.40,
15.44,
12.28,
7.84,
13.70,
13.12,
12.56,
14.69,
10.83,
13.11,
10.94,
10.14,
9.32,
12.60,
14.80,
12.06,
18.71,
9.11,
8.21,
9.94,
12.50,
8.16,
9.05,
9.61,
15.40,
13.42,
16.29,
14.20,
15.10,
14.54,
12.65,
15.07,
12.94,
9.67,
9.95,
10.89,
10.33,
8.89,
9.74,
11.44,
4.74,
9.28,
11.39,
12.74,
12.83,
12.71,
10.00,
13.30,
13.67,
8.00,
11.78,
15.18,
15.50,
9.39,
12.00,
11.60,
15.00,
15.75,
6.26,
15.20,
13.28,
11.36,
19.33,
6.00,
8.86,
10.53,
8.72,
8.58,
10.84,
14.07,
14.00,
6.81,
10.81,
10.33,
10.95,
12.60,
14.20,
16.71,
4.55,
3.83,
14.40,
10.11,
9.89,
9.67,
10.85,
8.61,
9.37,
11.41,
7.48,
10.75,
6.68,
12.94,
6.50,
8.68,
15.54,
14.56,
14.33,
13.92,
13.00,
12.00,
11.87,
11.33,
13.00,
10.47,
11.65,
9.67,
6.53,
13.22,
8.33,
12.56,
4.72,
14.00,
7.33,
7.59,
12.53,
9.97,
11.07,
11.71,
13.79,
7.37,
3.44,
7.90,
11.22,
3.43,
12.61,
3.72,
9.59,
8.61,
6.94,
8.56,
14.75,
15.75,
13.46,
13.93,
12.87,
14.83,
6.68,
8.31,
16.62,
16.78,
13.24,
6.50,
12.05,
15.78,
12.82,
12.50,
10.90,
14.80,
14.00,
11.35,
10.94,
13.12,
13.47,
11.53,
4.01,
8.75,
4.97,
14.79,
15.69,
10.39,
3.23,
11.42,
13.93,
8.47,
8.39,
6.05,
8.94,
4.95,
13.44,
10.84,
10.72,
9.26,
9.67,
12.33,
6.89,
15.15,
16.64,
15.92,
13.29,
8.18,
16.75,
9.33,
7.45,
9.30,
12.43,
12.29,
9.28,
13.95,
5.00,
7.17,
9.60,
6.28,
8.68,
13.00,
7.11,
12.24,
7.84,
10.79,
9.67,
7.95,
15.80,
16.00,
16.82,
11.35,
12.41,
14.44,
13.70,
8.42,
10.88,
12.41,
8.76,
8.63,
10.83,
14.17,
13.65,
12.21,
9.72,
10.71,
11.05,
8.06,
5.06,
11.58,
12.37,
11.16,
12.22,
12.44,
12.83,
13.05,
11.00,
13.85,
12.62,
11.00,
10.72,
11.24,
12.63,
13.74,
12.17,
10.89,
11.42,
12.44,
11.44,
11.44,
8.79,
8.79,
8.62,
13.74,
11.73,
14.71,
13.85,
4.14,
11.82,
9.39,
3.26,
12.30,
7.07,
7.83,
13.56,
13.62,
13.00,
15.10,
14.62,
11.17,
7.95,
12.65,
9.44,
7.67,
9.44,
11.12,
11.05,
11.89,
9.76,
17.25,
11.35,
10.06,
6.26,
10.40,
8.33,
13.17,
12.06,
8.63,
12.75,
13.93,
14.00,
16.25,
11.12,
10.88,
8.72,
13.22,
13.68,
10.61,
14.13,
7.68,
9.16,
11.27,
6.33,
9.55,
14.05,
15.70,
15.00,
13.95,
12.25,
8.35,
14.20,
11.78,
11.40,
9.24,
10.25,
7.67,
9.58,
8.46,
9.72,
11.39,
12.61,
10.00,
14.80,
6.10,
9.74,
11.11,
10.22,
12.00,
10.27,
16.78,
14.86,
15.30,
15.00,
6.94,
8.89,
7.90,
14.75,
15.40,
18.44,
11.68,
10.79,
10.26,
13.16,
14.78,
14.70,
14.42,
12.37,
11.39,
14.44,
14.22,
14.13,
12.61,
14.60,
13.50,
15.75,
16.64,
14.00,
16.40,
12.50,
14.11,
15.11,
15.22,
15.18,
16.15,
18.25,
16.00,
14.72,
14.17,
16.10,
14.29,
14.73,
6.42,
5.33,
9.07,
8.29,
10.27,
11.00,
5.47,
10.94,
20.50,
2.42,
9.62,
8.78,
4.35,
4.60,
8.13,
12.71,
13.07,
3.28,
9.06,
9.89,
12.39,
11.08,
9.95,
13.75,
10.50,
10.62,
11.25,
8.84,
9.67,
9.53,
10.11,
9.89,
12.56,
10.06,
4.60,
4.75,
9.14,
10.81,
10.39,
7.32,
11.50,
9.00,
7.44,
8.10,
11.47,
7.00,
14.16,
13.07,
11.76,
10.89,
11.80,
8.11,
6.94,
11.47,
9.84,
10.83,
7.90,
7.95,
11.76,
12.00,
10.09,
6.33,
13.43,
15.06,
8.32,
8.74,
8.43,
10.65,
11.40,
12.26,
13.86,
15.80,
8.30,
9.56,
11.79,
12.67,
13.25,
10.47,
11.53,
12.16,
10.67,
10.72,
10.46,
9.90,
9.15,
9.05,
10.11,
10.93,
10.61,
12.56,
12.00,
11.41,
8.42,
10.95,
11.78,
10.67,
11.31,
3.58,
9.35,
12.94,
10.94,
10.55,
9.16,
13.50,
10.53,
11.50,
10.58,
7.22,
9.75,
11.83,
10.44,
12.41,
10.95,
14.00,
11.53,
11.78,
12.36,
8.65,
10.70,
10.47,
11.79,
11.39,
13.33,
5.95,
14.44,
12.26,
11.19,
5.67,
5.57,
10.53,
9.76,
11.63,
10.44,
11.58,
12.30,
8.28,
12.53,
14.68,
12.89,
11.00,
11.82,
11.00,
9.78,
14.20,
8.00,
9.39,
8.77,
11.89,
10.54,
9.05,
10.11,
8.95,
8.00,
14.74,
12.83,
10.47,
11.53,
9.00,
8.42,
9.11,
9.17,
11.74,
15.00,
11.40,
12.12,
13.67,
9.68,
11.37,
12.80,
7.78,
10.24,
7.67,
8.50,
12.79,
10.95,
10.95,
12.00,
12.53,
11.22,
11.18,
9.06,
12.17,
11.78,
11.67,
11.63,
13.17,
9.00,
7.79,
6.44,
8.70,
8.83,
10.58,
9.12,
11.30,
9.14,
10.22,
8.37,
8.74,
7.80,
11.39,
13.11,
13.07,
9.78,
9.68,
8.78,
10.67,
9.05,
10.33,
12.21,
9.50,
10.87,
9.72,
12.63,
11.36,
5.06,
9.16,
9.00,
9.68,
13.11,
10.65,
13.28,
13.32,
7.94,
11.61,
10.21,
9.06,
11.71,
12.47,
9.00,
9.52,
12.50,
11.19,
10.05,
10.68,
9.89,
10.58,
13.50,
12.50,
12.45,
8.39,
9.42,
7.76,
11.00,
11.68,
12.76,
10.22,
15.00,
9.44,
12.50,
10.89,
11.28,
12.22,
11.42,
9.89,
9.00,
14.17,
10.58,
9.57,
13.53,
12.83,
11.22,
10.26,
11.83,
11.58,
12.47,
13.56,
10.11,
14.62,
9.44,
11.17,
10.67,
9.39,
14.06,
13.46,
11.47,
8.61,
9.17,
10.85,
9.65,
12.79,
8.56,
9.21,
9.94,
10.78,
9.67,
11.44,
14.87,
11.00,
14.16,
12.73,
13.26,
11.61,
12.29,
8.61,
6.21,
11.42,
5.21,
7.50,
9.39,
4.72,
15.25,
12.44,
11.23,
10.00,
13.16,
14.53,
14.00,
13.20,
14.79,
11.50,
12.29,
17.17,
8.63,
12.26,
14.00,
12.94,
14.16,
8.00,
12.79,
4.60,
16.20,
8.21,
12.42,
12.06,
17.20,
11.45,
12.52,
4.56,
13.39,
12.75,
14.47,
10.37,
6.55,
9.62,
6.61,
8.89,
10.00,
8.94,
13.12,
14.60,
13.14,
9.37,
8.83,
6.83,
8.45,
6.58,
8.16,
8.33,
12.42,
11.69,
8.84,
11.39,
14.25,
14.45,
13.00,
14.59,
5.16,
10.00,
10.53,
12.86,
10.11,
16.29,
15.50,
11.45,
12.38,
6.16,
4.26,
8.89,
6.44,
11.59,
8.91,
6.53,
11.17,
4.45,
12.50,
6.35,
6.22,
5.60,
14.73,
5.26,
10.30,
5.94,
11.29,
4.27,
3.48,
5.74,
6.89,
14.83,
12.72,
11.72,
13.54,
16.75,
14.71,
15.50,
6.95,
11.28,
10.00,
14.57,
12.50,
12.83,
10.50,
13.78,
15.62,
13.00,
17.50,
12.27,
14.00,
9.62,
12.00,
17.60,
17.11,
14.53,
13.23,
6.00,
13.50,
12.83,
14.64,
11.10,
7.44,
14.12,
13.93,
13.60,
15.00,
15.33,
14.53,
14.00,
14.25,
14.67,
9.82,
13.40,
9.33,
9.50,
4.72,
16.50,
12.92,
15.00,
4.26,
16.50,
12.84,
13.50,
16.12,
5.67,
12.26,
14.24,
13.47,
13.80,
9.78,
16.86,
9.11,
8.22,
11.44,
10.57,
10.50,
11.82,
13.12,
8.35,
9.28,
14.00,
15.50,
16.00,
13.88,
12.39,
12.27,
16.00,
8.32,
5.94,
13.70,
11.80,
6.67,
5.75,
15.14,
9.33,
8.17,
3.23,
9.42,
6.26,
6.06,
10.53,
14.22,
13.00,
3.05,
13.35,
12.00,
14.47,
10.42,
10.84,
4.00,
7.94,
7.56,
6.42,
10.71,
10.91,
8.33,
6.72,
7.53,
10.38,
14.33,
16.64,
13.92,
10.77,
9.63,
10.88,
10.16,
13.02,
11.24,
14.54,
7.00,
11.11,
6.53,
16.60,
15.58,
8.67,
14.90,
13.78,
13.81,
13.67,
11.65,
14.50,
7.05,
6.53,
16.00,
15.11,
9.95,
11.44,
11.05,
10.39,
16.41,
9.67,
14.31,
9.89,
12.23,
8.68,
10.95,
17.67,
13.21,
14.93,
12.79,
15.50,
12.30,
12.67,
12.42,
13.27,
13.16,
13.44,
13.53,
16.91,
16.33,
9.94,
11.83,
14.36,
12.18,
15.50,
11.00,
13.41,
11.80,
15.11,
14.20,
10.67,
11.82,
9.19,
10.50,
15.00,
7.83,
11.41,
12.32,
11.33,
19.00,
4.22,
12.75,
10.26,
9.39,
15.20,
8.47,
9.72,
9.55,
15.30,
10.76,
14.94,
16.64,
9.95,
10.79,
11.59,
14.33,
4.47,
10.32,
5.94,
9.56,
12.80,
13.56,
12.00,
14.05,
12.44,
8.94,
13.22,
8.21,
13.08,
12.69,
11.47,
13.47,
16.60,
16.78,
15.73,
17.00,
14.14,
17.75,
13.67,
5.58,
13.62,
11.15,
12.85,
18.00,
14.17,
14.71,
8.95,
10.75,
13.00,
5.11,
10.89,
11.24,
9.68,
11.21,
12.40,
8.42,
7.05,
7.56,
12.78,
12.14,
10.28,
13.50,
8.05,
11.70,
14.20,
7.89,
14.05,
14.25,
5.85,
9.89,
8.05,
8.94,
4.58,
16.25,
5.39,
9.79,
8.11,
8.56,
14.55,
12.88,
12.67,
6.65,
11.50,
9.84,
12.00,
10.79,
11.28,
11.20,
8.89,
13.78,
13.35,
13.07,
12.16,
8.83,
6.78,
5.78,
4.84,
9.59,
10.05,
12.36,
14.67,
10.55,
14.00,
11.50,
6.79,
11.43,
8.42,
7.21,
6.33,
9.11,
11.17,
5.07,
7.28,
16.58,
8.65,
9.22,
13.42,
14.25,
14.89,
13.00,
12.41,
14.56,
11.53,
17.00,
11.32,
13.10,
6.11,
9.15,
12.22,
11.79,
13.41,
16.24,
13.39,
12.84,
14.60,
14.05,
13.13,
6.50,
7.68,
7.05,
15.86,
8.00,
14.10,
15.29,
12.50,
12.76,
12.35,
16.10,
15.15,
14.00,
15.31,
10.14,
8.39,
9.00,
8.56,
9.50,
12.56,
12.95,
12.29,
11.00,
12.56,
14.50,
13.50,
9.18,
6.89,
8.76,
15.60,
12.62,
14.13,
9.44,
15.75,
6.75,
8.37,
6.30,
10.53,
10.58,
5.89,
9.06,
14.19,
10.11,
8.15,
5.50,
8.55,
6.72,
8.78,
9.41,
10.30,
13.33,
12.92,
8.67,
10.47,
13.62,
12.06,
7.11,
12.79,
3.95,
6.32,
9.29,
7.22,
7.65,
9.11,
8.72,
9.55,
9.79,
14.57,
10.25,
4.21,
7.63,
5.76,
12.31,
7.11,
8.31,
7.11,
5.00,
4.72,
4.00,
6.28,
8.50,
8.56,
12.17,
11.83,
9.79,
12.93,
13.08,
5.68,
6.80,
14.36,
12.71,
7.89,
9.00,
10.00,
15.22,
12.89,
0.00,
12.00,
12.44,
13.50,
17.30,
6.50,
14.46,
13.54,
7.29,
8.44,
17.25,
20.00,
10.80,
9.75,
10.72,
14.00,
11.42,
14.76,
11.70,
12.74,
13.71,
14.06,
13.37,
14.94,
16.00,
3.89,
5.79,
3.65,
6.05,
8.00,
6.44,
14.80,
8.22,
8.33,
9.35,
13.21,
10.21,
13.71,
11.88,
10.79,
15.47,
6.83,
8.32,
16.12,
13.40,
14.11,
18.00,
18.50,
18.00,
8.22,
15.11,
10.18,
14.18,
10.84,
10.89,
9.95,
5.11,
12.82,
11.00,
8.89,
12.50,
15.48,
4.06,
5.31,
10.30,
11.85,
13.21,
11.75,
10.83,
11.05,
13.06,
14.33,
5.68,
9.95,
9.56,
13.79,
9.00,
8.53,
12.31,
13.60,
13.20,
11.22,
11.61,
11.00,
9.25,
11.47,
10.75,
4.67,
14.58,
11.43,
16.57,
15.88,
13.28,
14.38,
13.63,
12.00,
14.14,
10.89,
13.62,
11.78,
13.33,
13.11,
17.00,
17.00,
15.89,
14.29,
20.60,
11.20,
12.27,
3.52,
8.75,
15.50,
5.47,
12.08,
11.42,
5.70,
6.21,
8.56,
9.00,
13.10,
15.47,
15.50,
8.66,
11.25,
14.75,
14.75,
15.62,
10.42,
8.19,
10.26,
12.94,
12.59,
12.06,
11.53,
11.84,
12.72,
13.67,
9.16,
13.07,
16.00,
13.00,
10.19,
10.47,
9.50,
11.40,
15.73,
13.29,
16.75,
14.28,
14.71,
5.78,
12.12,
9.81,
11.61,
11.63,
12.95,
6.16,
11.50,
15.33,
13.77,
13.05,
11.42,
11.83,
8.39,
7.74,
6.50,
7.06,
8.81,
10.47,
12.60,
16.27,
12.42,
14.73,
7.60,
16.75,
15.00,
14.43,
12.47,
12.33,
9.63,
17.22,
6.78,
12.71,
11.23,
14.07,
16.50,
13.67,
12.79,
14.33,
10.16,
10.95,
11.72,
14.36,
16.75,
16.86,
14.69,
18.11,
16.33,
10.42,
14.35,
13.92,
12.78,
9.63,
10.42,
9.05,
9.00,
14.62,
12.19,
12.00,
7.75,
16.13,
13.89,
12.93,
14.42,
10.94,
6.11,
11.16,
12.53,
9.47,
15.40,
14.40,
15.91,
14.92,
9.84,
11.33,
12.26,
14.00,
12.88,
13.75,
13.33,
14.07,
13.25,
12.00,
12.42,
10.06,
11.80,
11.95,
14.67,
11.00,
13.69,
11.05,
17.50,
15.29,
9.50,
10.28,
11.22,
10.89,
13.00,
4.67,
11.33,
11.74,
13.06,
8.05,
8.68,
10.17,
8.40,
13.46,
13.74,
12.67,
11.26,
10.78,
15.23,
16.33,
15.71,
13.90,
10.32,
12.26,
10.79,
11.89,
10.90,
11.72,
12.11,
10.41,
11.00,
16.57,
12.06,
13.93,
11.11,
14.21,
12.78,
12.94,
12.94,
14.82,
13.11,
14.06,
13.11,
14.38,
9.84,
12.06,
16.14,
9.72,
11.94,
12.42,
12.53,
11.63,
7.35,
7.39,
11.78,
11.63,
16.83,
13.06,
13.00,
13.50,
13.00,
13.80,
4.05,
6.33,
15.25,
9.42,
11.06,
13.18,
14.07,
9.05,
10.05,
15.00,
12.28,
11.00,
11.11,
13.88,
11.00,
15.83,
12.72,
15.00,
10.23,
13.70,
13.21,
8.86,
8.56,
13.25,
16.07,
14.75,
11.64,
15.57,
15.29,
14.00,
15.07,
14.24,
17.50,
8.35,
8.26,
15.60,
14.00,
13.39,
13.88,
8.79,
14.11,
14.83,
16.00,
16.07,
8.79,
13.91,
17.50,
9.40,
9.68,
17.90,
15.33,
14.71,
10.50,
15.18,
11.94,
13.29,
14.00,
14.75,
13.36,
15.40,
14.20,
17.00,
13.58,
14.06,
14.06,
18.17,
14.69,
12.21,
12.85,
14.50,
15.54,
11.63,
14.06,
12.26,
13.82,
13.59,
13.21,
15.38,
17.12,
14.08,
11.27,
13.60,
14.57,
15.38,
9.83,
11.82,
4.11,
9.94,
10.94,
8.85,
8.79,
11.55,
9.18,
11.58,
7.58,
7.78,
18.17,
13.40,
14.80,
14.67,
14.11,
12.06,
14.93,
11.15,
13.74,
5.16,
14.00,
10.22,
11.00,
14.85,
12.87,
14.69,
11.61,
6.68,
7.74,
8.50,
7.84,
13.56,
12.63,
13.35,
15.92,
12.61,
16.00,
14.77,
13.58,
12.71,
10.67,
11.44,
14.62,
15.90,
8.44,
11.88,
12.43,
16.29,
11.62,
16.50,
16.80,
13.23,
11.61,
8.16,
13.81,
8.68,
12.90,
11.68,
16.17,
15.19,
12.33,
15.32,
13.44,
17.40,
11.89,
11.94,
10.57,
7.94,
5.50,
12.38,
15.00,
13.06,
16.18,
17.11,
15.57,
15.75,
16.10,
14.00,
5.26,
10.80,
9.79,
8.05,
10.79,
6.05,
9.30,
11.69,
9.64,
9.37,
7.05,
5.74,
5.53,
8.42,
15.33,
11.75,
12.88,
12.71,
12.89,
4.05,
5.11,
13.06,
8.33,
11.20,
9.57,
3.67,
4.89,
12.30,
11.86,
9.56,
8.08,
11.88,
12.60,
13.38,
3.84,
9.50,
6.21,
11.22,
11.27,
4.67,
5.00,
5.51,
8.95,
5.68,
11.06,
12.39,
14.53,
5.95,
8.39,
8.16,
12.50,
5.89,
6.29,
12.06,
12.33,
13.90,
12.25,
15.00,
5.79,
8.84,
11.18,
14.76,
15.00,
6.26,
10.50,
6.06,
11.41,
8.26,
9.30,
15.00,
15.78,
3.47,
5.15,
6.32,
11.33,
11.13,
10.93,
12.78,
9.57,
9.65,
11.36,
4.53,
13.86,
6.95,
10.85,
5.50,
11.40,
8.56,
5.53,
15.89,
6.25,
4.61,
11.73,
12.00,
8.76,
10.37,
10.68,
8.71,
14.67,
3.80,
8.71,
3.21,
8.12,
12.67,
16.33,
12.46,
15.62,
9.68,
8.89,
11.80,
9.89,
8.37,
9.00,
7.61,
10.94,
7.63,
7.56,
12.81,
10.69,
8.17,
10.06,
7.91,
13.72,
9.95,
16.60,
6.88,
13.17,
15.00,
12.59,
16.60,
10.63,
8.74,
6.50,
12.27,
10.85,
15.25,
17.20,
16.43,
7.56,
11.80,
8.74,
10.31,
14.33,
7.28,
10.37,
6.14,
12.00,
6.42,
16.67,
6.42,
6.89,
11.67,
13.00,
12.61,
12.94,
17.62,
13.14,
13.17,
8.80,
11.50,
10.97,
13.00,
12.00,
11.21,
13.06,
8.72,
10.78,
12.00,
12.50,
13.72,
7.72,
4.67,
12.33,
9.47,
14.20,
12.27,
13.60,
4.95,
11.89,
10.56,
10.79,
9.32,
12.06,
15.29,
10.60,
13.11,
13.20,
11.28,
13.06,
12.24,
9.26,
14.00,
8.56,
7.37,
9.76,
13.06,
9.78,
12.00,
12.33,
13.50,
13.00,
6.78,
4.95,
9.88,
9.14,
6.67,
9.63,
10.33,
13.38,
7.84,
11.21,
9.05,
3.95,
12.80,
14.27,
9.72,
7.68,
7.39,
13.25,
13.05,
14.60,
9.16,
11.22,
5.11,
12.05,
16.83,
14.50,
3.84,
9.74,
8.06,
12.53,
17.00,
7.83,
11.71,
12.28,
12.94,
13.12,
12.33,
8.68,
7.71,
11.33,
12.79,
4.10,
14.23,
7.35,
10.82,
8.50,
10.60,
12.21,
6.89,
6.22,
7.75,
13.16,
4.28,
5.17,
14.44,
11.68,
5.21,
9.00,
4.41,
6.11,
8.64,
8.17,
4.24,
10.44,
10.68,
9.83,
8.42,
10.22,
11.12,
7.67,
8.44,
11.89,
3.48,
8.26,
9.33,
7.28,
10.56,
8.21,
12.00,
9.69,
15.00,
13.69,
16.88,
15.57,
7.17,
15.62,
16.17,
12.92,
15.38,
8.40,
5.76,
17.60,
10.10,
12.86,
13.86,
10.71,
14.23,
16.88,
12.06,
10.94,
7.00,
11.97,
9.36,
10.20,
9.89,
7.15,
13.42,
8.65,
10.95,
7.11,
12.87,
12.50,
9.78,
11.14,
6.05,
5.50,
12.94,
9.81,
8.56,
8.26,
11.24,
12.79,
8.21,
8.89,
12.12,
12.47,
8.61,
9.63,
9.06,
15.17,
7.45,
13.76,
15.94,
14.56,
14.30,
10.53,
6.37,
9.58,
9.35,
14.95,
12.50,
16.12,
16.14,
12.67,
13.68,
16.33,
12.00,
9.38,
14.46,
14.93,
9.65,
12.67,
10.06,
10.11,
12.00,
4.74,
9.35,
7.37,
5.94,
12.88,
10.13,
7.95,
15.89,
13.58,
14.18,
15.50,
9.35,
6.89,
12.15,
8.61,
13.29,
9.94,
10.95,
8.11,
7.76,
14.33,
12.56,
13.07,
13.00,
8.89,
4.55,
7.70,
10.24,
15.09,
7.95,
7.39,
11.33,
5.58,
9.47,
8.14,
4.89,
4.95,
9.10,
7.31,
8.16,
13.37,
15.56,
12.37,
5.63,
10.57,
13.85,
12.86,
5.05,
4.44,
6.37,
10.32,
14.05,
11.67,
8.06,
10.89,
15.12,
4.63,
6.89,
10.76,
9.83,
9.11,
14.79,
13.71,
13.50,
9.37,
7.56,
11.58,
7.86,
9.68,
9.82,
10.19,
7.80,
13.86,
10.76,
8.56,
8.24,
8.56,
11.07,
9.95,
11.40,
13.50,
8.94,
14.00,
12.81,
15.56,
15.07,
14.62,
15.36,
18.09,
10.16,
16.15,
14.26,
14.35,
12.95,
14.15,
16.91,
12.33,
13.33,
14.38,
15.50,
14.87,
15.00,
15.60,
15.00,
13.00,
16.05,
15.81,
15.64,
14.44,
15.47,
12.36,
14.50,
17.27,
14.56,
13.63,
14.81,
14.50,
11.17,
12.38,
8.50,
8.94,
15.30,
12.71,
8.84,
5.16,
11.44,
12.80,
14.18,
8.68,
14.00,
13.07,
14.92,
11.56,
5.39,
5.79,
7.67,
6.53,
5.67,
5.15,
9.21,
8.26,
7.65,
4.00,
6.11,
10.61,
8.08,
4.48,
4.17,
8.83,
13.44,
7.27,
8.75,
11.67,
10.27,
12.67,
8.44,
9.61,
12.29,
10.37,
10.56,
3.78,
12.35,
6.61,
9.11,
10.89,
9.78,
8.11,
14.14,
12.62,
9.12,
5.40,
15.10,
6.89,
9.89,
5.63,
10.76,
11.40,
10.53,
11.56,
12.90,
14.83,
12.25,
12.68,
11.75,
10.53,
14.60,
8.94,
6.35,
17.20,
9.32,
12.20,
7.95,
12.79,
11.00,
11.80,
14.00,
14.67,
13.43,
15.70,
10.08,
14.57,
13.84,
10.12,
13.00,
17.67,
8.39,
11.12,
17.78,
7.61,
13.12,
12.56,
10.95,
10.47,
11.67,
11.89,
14.50,
7.89,
13.22,
14.92,
11.88,
14.78,
8.72,
10.41,
10.06,
8.11,
11.61,
15.25,
10.56,
11.95,
9.95,
9.61,
11.33,
12.68,
12.33,
8.47,
7.32,
6.61,
8.11,
8.33,
8.17,
6.65,
6.74,
14.12,
14.94,
8.12,
14.95,
13.53,
14.56,
12.93,
9.29,
14.87,
15.08,
13.56,
5.22,
11.45,
11.32,
13.15,
13.17,
14.37,
14.71,
17.22,
12.22,
14.79,
12.79,
14.07,
14.20,
15.86,
10.42,
11.72,
17.00,
12.89,
16.26,
14.30,
13.85,
13.62,
8.89,
11.26,
8.56,
5.95,
14.71,
13.44,
14.56,
11.89,
4.84,
10.56,
12.92,
12.89,
11.44,
14.50,
9.61,
13.00,
13.47,
12.16,
0.00,
8.42,
12.70,
13.45,
7.58,
11.86,
7.80,
8.11,
10.15,
12.70,
13.88,
8.37,
8.45,
7.83,
2.28,
6.56,
13.86,
8.39,
9.06,
6.79,
10.53,
10.76,
9.12,
5.00,
7.58,
7.00,
11.80,
7.28,
9.68,
5.06,
7.11,
10.56,
8.44,
7.48,
8.47,
6.42,
9.05,
9.74,
10.33,
12.01,
9.11,
8.61,
10.00,
10.89,
6.24,
13.61,
16.62,
15.14,
15.75,
15.59,
15.88,
6.50,
7.95,
9.68,
10.83,
9.90,
8.95,
9.56,
8.39,
8.58,
17.00,
14.40,
14.83,
13.50,
12.25,
13.83,
13.06,
5.79,
5.25,
7.06,
7.33,
6.11,
13.88,
10.63,
13.25,
12.44,
11.94,
13.21,
10.50,
13.10,
11.65,
10.62,
14.70,
14.53,
11.05,
11.33,
12.12,
10.40,
12.53,
10.53,
13.67,
12.06,
11.94,
15.55,
7.57,
10.53,
11.94,
9.58,
11.10,
9.50,
14.47,
13.50,
9.68,
11.17,
13.12,
12.41,
16.36,
13.06,
15.35,
12.89,
13.50,
13.40,
15.47,
12.42,
13.83,
13.41,
13.12,
8.45,
11.56,
9.83,
14.50,
13.83,
13.53,
13.18,
11.11,
10.53,
10.26,
13.00,
12.32,
8.00,
12.06,
10.33,
9.68,
10.89,
10.50,
12.81,
15.40,
13.95,
12.79,
17.17,
13.31,
14.56,
11.38,
11.69,
13.13,
12.67,
14.20,
14.44,
13.58,
14.75,
13.23,
10.11,
16.47,
13.06,
9.67,
11.93,
14.45,
12.50,
6.95,
9.00,
9.17,
12.59,
16.67,
9.56,
13.16,
14.08,
12.72,
13.50,
9.16,
6.76,
8.89,
17.14,
8.05,
11.06,
11.89,
12.33,
10.81,
9.67,
10.30,
12.72,
13.17,
9.76,
13.21,
14.00,
12.28,
11.06,
12.78,
11.53,
13.05,
11.82,
12.45,
14.11,
11.95,
12.58,
11.32,
8.47,
12.47,
12.78,
9.95,
12.26,
10.47,
16.43,
13.89,
15.67,
11.45,
9.84,
12.00,
12.33,
12.64,
9.24,
9.72,
13.05,
9.33,
11.62,
7.72,
8.63,
9.84,
12.20,
12.45,
16.33,
11.50,
12.69,
11.78,
11.27,
14.87,
12.71,
14.25,
13.67,
15.80,
11.73,
12.67,
10.22,
12.78,
13.33,
15.92,
4.05,
12.92,
12.90,
9.20,
9.22,
10.06,
9.17,
4.83,
9.68,
9.50,
8.90,
14.50,
13.20,
10.95,
10.74,
9.63,
12.28,
8.24,
10.53,
9.81,
12.22,
8.44,
6.89,
8.89,
11.00,
9.89,
6.40,
11.43,
11.29,
8.95,
14.78,
11.67,
14.33,
14.92,
11.59,
12.22,
8.29,
12.68,
14.28,
12.89,
14.00,
14.32,
14.59,
15.31,
15.50,
9.32,
4.56,
8.50,
11.82,
11.06,
12.95,
13.06,
13.90,
13.06,
14.94,
8.39,
4.09,
5.00,
10.33,
12.26,
10.72,
12.86,
16.20,
8.60,
9.72,
10.71,
10.29,
8.47,
10.50,
13.56,
7.42,
8.44,
11.22,
10.00,
9.42,
7.35,
9.76,
8.44,
8.79,
6.29,
8.95,
8.30,
7.10,
12.35,
10.21,
11.85,
17.11,
9.58,
13.27,
12.32,
9.93,
8.89,
9.32,
15.40,
8.83,
11.22,
14.22,
9.28,
14.94,
11.67,
12.53,
12.13,
5.26,
10.11,
9.39,
3.95,
5.58,
9.68,
13.67,
9.95,
7.67,
7.61,
8.17,
11.56,
10.63,
13.17,
10.95,
9.78,
13.38,
7.50,
8.00,
9.06,
12.33,
15.30,
7.84,
6.16,
12.06,
14.20,
13.62,
13.56,
14.33,
9.24,
10.67,
12.07,
5.11,
8.37,
10.25,
11.39,
7.78,
8.39,
13.21,
14.70,
11.00,
11.61,
11.72,
11.58,
13.38,
14.13,
9.90,
10.58,
14.17,
6.06,
11.63,
11.95,
11.33,
13.00,
11.12,
9.67,
8.33,
10.91,
10.37,
10.70,
10.17,
11.79,
11.18,
12.58,
11.05,
11.07,
15.25,
15.50,
10.94,
11.63,
11.05,
11.14,
13.00,
11.53,
14.95,
13.89,
15.44,
16.82,
14.06,
13.67,
13.35,
15.93,
14.89,
11.22,
11.72,
12.59,
11.63,
7.75,
10.89,
8.05,
9.21,
9.44,
11.79,
17.50,
14.11,
12.84,
13.23,
10.58,
10.68,
9.58,
9.06,
12.28,
10.89,
11.44,
12.85,
16.00,
12.20,
12.11,
9.00,
12.24,
8.56,
11.72,
11.22,
12.11,
15.50,
13.37,
15.67,
12.06,
14.22,
13.17,
12.07,
15.27,
15.12,
16.67,
14.10,
14.75,
13.54,
16.20,
8.20,
16.79,
13.28,
10.58,
9.00,
11.00,
11.11,
10.67,
12.05,
12.68,
9.00,
10.21,
11.18,
10.00,
12.63,
7.16,
14.29,
13.00,
15.00,
15.64,
15.33,
14.50,
12.44,
14.00,
14.62,
12.55,
11.89,
11.40,
10.42,
11.44,
16.18,
13.36,
12.61,
10.17,
13.65,
13.30,
5.67,
7.84,
14.42,
12.78,
14.18,
13.55,
10.32,
12.41,
11.32,
10.90,
10.24,
14.11,
15.56,
9.89,
10.89,
16.82,
11.83,
12.58,
11.60,
10.78,
8.63,
8.42,
10.47,
9.59,
8.38,
8.06,
8.75,
9.83,
10.83,
12.10,
14.29,
15.39,
13.87,
13.00,
12.53,
10.06,
12.94,
7.00,
15.56,
7.63,
11.76,
8.16,
14.64,
12.58,
10.53,
9.58,
12.33,
14.84,
15.20,
15.36,
15.57,
12.40,
12.40,
11.79,
10.95,
13.47,
11.21,
11.05,
9.00,
11.79,
12.20,
14.22,
12.21,
13.24,
15.16,
12.81,
15.12,
14.59,
14.36,
16.22,
15.92,
14.77,
12.06,
9.50,
11.83,
11.47,
13.69,
13.60,
16.92,
11.53,
10.26,
10.42,
11.78,
18.22,
10.06,
10.40,
13.00,
15.00,
15.27,
11.69,
13.22,
11.44,
11.78,
12.84,
15.50,
13.38,
13.50,
13.42,
11.83,
14.36,
6.79,
8.33,
12.57,
8.00,
10.26,
9.00,
13.69,
13.16,
12.40,
10.39,
9.11,
12.67,
12.89,
11.06,
13.83,
11.28,
15.28,
14.00,
12.65,
13.80,
11.35,
14.40,
9.75,
12.45,
12.37,
17.07,
5.44,
9.82,
7.84,
14.46,
14.75,
8.16,
13.78,
7.74,
15.92,
13.18,
9.63,
10.06,
12.70,
11.90,
14.29,
14.46,
14.88,
12.41,
12.21,
8.39,
10.21,
16.62,
12.56,
13.00,
9.50,
10.06,
14.00,
13.05,
12.26,
15.67,
14.88,
12.11,
11.87,
12.53,
16.20,
12.44,
7.85,
10.95,
13.60,
16.00,
8.06,
8.76,
11.14,
15.00,
13.75,
13.26,
15.40,
14.72,
14.86,
15.00,
17.60,
16.00,
10.11,
12.53,
12.32,
12.05,
12.61,
11.61,
11.47,
10.86,
8.22,
15.07,
14.35,
15.37,
15.47,
16.30,
15.56,
14.47,
14.86,
15.74,
17.69,
15.47,
14.53,
16.40,
11.65,
11.77,
11.72,
14.75,
17.00,
16.21,
13.22,
11.89,
14.95,
16.00,
14.77,
15.38,
19.19,
16.07,
15.44,
14.80,
15.75,
15.06,
14.39,
10.94,
15.76,
10.33,
14.20,
12.05,
16.00,
11.21,
11.00,
12.76,
14.00,
10.47,
11.46,
8.47,
13.46,
10.89,
13.61,
10.90,
11.44,
9.00,
11.15,
10.00,
15.14,
7.79,
7.61,
11.45,
4.79,
5.37,
8.55,
8.39,
11.94,
13.91,
17.29,
6.44,
7.87,
10.88,
11.73,
9.23,
7.78,
8.22,
7.42,
9.17,
17.44,
14.87,
14.89,
14.58,
15.19,
16.00,
6.94,
14.25,
16.00,
18.67,
4.79,
10.60,
11.15,
13.57,
9.90,
10.29,
8.05,
13.05,
9.63,
11.00,
10.18,
13.93,
13.88,
13.05,
10.21,
12.63,
9.00,
12.18,
12.39,
6.06,
8.77,
4.78,
11.21,
5.75,
8.44,
18.17,
7.56,
7.92,
13.18,
17.50,
14.50,
10.79,
10.53,
8.28,
8.61,
8.89,
14.36,
13.62,
13.29,
6.68,
9.17,
8.32,
5.47,
13.06,
10.00,
12.87,
8.18,
9.71,
8.00,
5.11,
11.18,
13.27,
8.06,
5.00,
8.21,
8.61,
3.28,
10.00,
8.11,
10.22,
7.95,
9.94,
11.42,
10.94,
10.58,
15.18,
13.92,
14.61,
11.61,
11.56,
10.89,
10.22,
11.00,
12.11,
13.26,
13.18,
9.89,
14.20,
17.00,
13.69,
3.60,
6.88,
16.44,
8.37,
10.21,
10.90,
11.55,
5.25,
5.53,
14.27,
10.00,
10.70,
10.44,
15.50,
13.36,
14.62,
7.78,
4.26,
8.50,
9.39,
9.97,
9.00,
8.50,
10.81,
14.12,
7.53,
7.70,
15.50,
15.20,
8.50,
9.58,
5.76,
12.29,
14.00,
3.72,
15.22,
9.50,
14.10,
12.50,
12.08,
14.93,
14.91,
13.14,
14.81,
15.75,
8.67,
9.33,
7.72,
5.11,
7.50,
11.37,
10.11,
12.77,
5.78,
13.47,
16.56,
14.88,
7.61,
13.50,
14.07,
16.00,
12.18,
13.12,
13.00,
14.67,
12.93,
14.62,
17.11,
7.89,
5.25,
10.91,
12.58,
13.15,
13.54,
12.76,
12.60,
13.42,
13.80,
11.36,
13.06,
16.67,
15.41,
13.50,
14.38,
12.37,
11.22,
11.21,
13.71,
14.04,
9.72,
12.58,
12.78,
8.15,
10.95,
10.76,
9.79,
12.89,
10.05,
15.28,
8.78,
12.67,
12.79,
14.67,
14.00,
14.00,
11.11,
14.26,
12.81,
13.00,
8.32,
9.63,
10.56,
7.50,
5.79,
7.63,
13.80,
12.06,
11.24,
13.29,
6.32,
12.20,
9.00,
10.88,
13.38,
9.28,
14.25,
12.10,
13.27,
16.00,
13.88,
14.70,
10.89,
11.37,
4.42,
11.10,
13.58,
10.44,
11.15,
16.11,
13.58,
11.20,
10.40,
13.53,
6.16,
8.16,
9.78,
10.84,
13.44,
12.17,
11.86,
13.13,
5.89,
8.63,
12.75,
14.92,
6.32,
8.28,
9.60,
12.06,
12.06,
11.27,
4.15,
7.53,
15.07,
14.33,
9.04,
6.55,
10.26,
10.58,
12.49,
14.00,
12.40,
17.20,
14.00,
12.50,
14.17,
16.15,
15.62,
10.38,
15.40,
10.65,
13.29,
12.75,
14.90,
11.39,
11.88,
9.94,
12.67,
5.26,
15.67,
7.44,
7.16,
11.05,
10.74,
10.80,
13.57,
7.05,
9.05,
11.65,
13.20,
14.85,
12.64,
11.19,
9.38,
8.44,
14.18,
13.80,
10.61,
12.60,
8.88,
12.00,
13.18,
3.94,
11.59,
9.22,
8.22,
6.79,
6.00,
7.84,
6.67,
18.00,
4.84,
9.00,
8.06,
10.89,
13.79,
13.73,
9.56,
9.44,
6.26,
8.20,
14.35,
15.94,
17.78,
6.14,
11.00,
11.33,
9.53,
11.31,
9.56,
9.68,
12.50,
11.44,
8.53,
11.90,
14.41,
15.00,
17.67,
5.17,
12.11,
12.06,
16.20,
15.75,
15.33,
16.23,
11.41,
13.24,
12.83,
12.61,
9.71,
13.79,
16.70,
15.32,
5.25,
14.10,
9.94,
14.00,
15.67,
14.00,
14.50,
9.22,
7.35,
10.74,
8.10,
5.22,
15.00,
8.11,
8.79,
8.50,
6.11,
12.41,
10.63,
10.86,
9.50,
11.94,
11.12,
13.83,
9.53,
9.47,
7.89,
12.20,
7.78,
12.18,
6.56,
13.71,
6.06,
7.05,
6.65,
14.57,
3.60,
4.26,
5.50,
6.11,
5.44,
7.45,
9.47,
4.44,
6.21,
4.00,
6.72,
9.31,
5.05,
5.32,
14.64,
10.75,
8.32,
10.60,
11.44,
10.75,
9.70,
17.12,
11.00,
18.00,
7.28,
10.14,
12.40,
10.00,
10.50,
12.00,
7.35,
9.72,
13.06,
8.83,
9.94,
14.25,
14.54,
9.33,
12.94,
9.67,
7.11,
12.88,
8.67,
9.42,
9.28,
12.19,
11.32,
9.83,
9.22,
11.00,
16.50,
9.14,
15.36,
16.67,
10.74,
14.36,
8.62,
12.82,
14.12,
15.88,
12.05,
13.36,
10.74,
13.18,
16.27,
16.25,
14.69,
11.24,
11.60,
13.81,
7.45,
12.00,
14.19,
9.46,
12.67,
10.94,
6.89,
12.31,
5.78,
12.33,
12.65,
5.33,
10.89,
5.11,
16.06,
10.21,
8.84,
11.00,
6.94,
13.11,
12.45,
9.47,
10.58,
14.40,
14.50,
10.40,
10.06,
12.17,
14.24,
11.65,
12.22,
11.63,
11.83,
9.61,
13.57,
4.50,
11.59,
7.06,
6.78,
7.47,
9.44,
14.40,
11.42,
12.53,
13.67,
12.33,
8.56,
12.44,
10.61,
6.58,
12.57,
14.10,
12.22,
7.17,
9.59,
8.68,
6.73,
13.06,
11.88,
7.11,
12.44,
7.59,
11.42,
11.28,
4.89,
9.16,
13.17,
10.24,
13.37,
14.06,
6.67,
13.56,
8.30,
13.24,
11.36,
13.00,
10.37,
12.63,
4.11,
8.09,
10.78,
5.37,
10.60,
9.67,
10.50,
12.67,
11.54,
11.07,
5.53,
8.89,
11.89,
13.30,
14.00,
4.95,
9.53,
12.35,
13.47,
13.48,
8.55,
10.05,
11.33,
8.23,
16.24,
5.94,
10.78,
6.71,
11.71,
11.40,
12.71,
13.50,
12.11,
12.65,
9.83,
11.37,
8.65,
10.11,
13.67,
14.10,
12.76,
6.89,
13.17,
12.53,
14.50,
8.11,
9.39,
11.22,
7.84,
8.32,
11.59,
10.14,
9.05,
11.68,
12.79,
13.50,
11.00,
10.44,
11.44,
10.68,
9.33,
10.10,
10.11,
9.16,
11.11,
12.00,
12.21,
9.95,
10.00,
10.26,
11.37,
10.00,
12.45,
13.56,
10.39,
9.39,
12.85,
10.21,
13.00,
8.95,
13.00,
14.00,
9.42,
12.33,
14.47,
14.44,
15.91,
15.00,
11.53,
14.11,
14.89,
11.45,
13.73,
9.83,
13.56,
16.67,
14.33,
10.42,
11.95,
11.65,
8.53,
10.28,
8.11,
10.17,
14.93,
14.33,
8.13,
10.24,
9.17,
9.95,
12.26,
12.18,
12.30,
13.67,
6.33,
13.74,
15.31,
13.06,
8.61,
10.83,
9.85,
12.24,
15.80,
16.60,
6.95,
7.89,
11.80,
13.00,
12.65,
14.83,
12.17,
6.95,
13.56,
8.16,
9.06,
10.68,
8.84,
12.89,
10.61,
13.37,
8.53,
6.11,
8.79,
11.28,
13.06,
9.50,
8.58,
13.73,
8.89,
10.74,
13.33,
9.67,
10.61,
16.06,
12.50,
13.12,
13.58,
9.11,
10.86,
12.61,
13.35,
12.69,
13.89,
10.47,
12.25,
10.82,
14.94,
11.74,
13.74,
12.78,
12.28,
12.78,
8.89,
9.83,
11.67,
9.68,
12.00,
14.10,
13.17,
10.63,
11.95,
10.70,
13.00,
13.17,
12.82,
6.30,
10.53,
8.37,
7.00,
6.37,
8.84,
12.74,
13.35,
7.56,
12.21,
8.05,
12.91,
9.21,
8.83,
8.89,
14.89,
14.47,
10.68,
10.50,
13.06,
5.00,
5.42,
14.80,
15.89,
13.00,
13.17,
14.31,
9.84,
13.21,
13.93,
11.74,
11.53,
14.28,
11.17,
11.79,
10.84,
9.89,
13.00,
9.11,
10.61,
8.32,
10.53,
3.68,
7.94,
12.89,
8.69,
14.00,
9.78,
9.21,
6.17,
9.74,
10.06,
11.86,
15.36,
9.28,
10.39,
11.22,
10.16,
12.39,
10.89,
13.25,
15.38,
9.56,
12.11,
13.11,
10.38,
5.79,
6.47,
9.42,
10.95,
11.25,
9.72,
11.00,
12.47,
14.31,
11.57,
8.61,
6.17,
7.94,
10.12,
12.60,
15.62,
12.54,
10.89,
12.35,
10.32,
8.44,
12.72,
11.56,
11.26,
14.07,
13.38,
14.53,
12.72,
8.78,
11.86,
13.62,
9.72,
13.76,
8.71,
9.12,
10.61,
11.25,
11.74,
11.39,
13.83,
10.50,
12.35,
11.50,
13.61,
9.84,
11.95,
10.33,
10.68,
12.17,
12.56,
11.00,
7.50,
11.62,
9.18,
12.31,
7.61,
7.06,
8.67,
14.44,
12.64,
15.24,
10.84,
8.86,
7.37,
15.95,
11.84,
9.75,
11.11,
13.00,
11.11,
10.18,
12.00,
9.89,
13.53,
5.50,
11.00,
13.33,
8.60,
8.17,
11.37,
12.68,
15.12,
15.56,
11.16,
12.74,
13.32,
12.17,
13.07,
14.26,
13.61,
13.67,
12.13,
11.82,
12.07,
13.81,
13.75,
10.50,
10.26,
9.94,
10.83,
9.95,
7.50,
7.24,
8.65,
13.60,
5.72,
7.39,
4.11,
10.22,
11.05,
10.56,
9.47,
11.17,
12.67,
13.06,
11.68,
8.37,
9.33,
14.29,
14.11,
10.63,
9.58,
12.57,
14.08,
10.20,
9.57,
13.43,
12.45,
10.44,
11.68,
14.20,
13.39,
15.95,
11.56,
13.00,
10.84,
12.26,
12.93,
12.94,
13.37,
9.14,
10.95,
13.45,
8.63,
15.06,
10.62,
13.00,
13.22,
13.95,
14.78,
9.61,
9.89,
10.37,
9.63,
8.56,
11.56,
14.55,
11.94,
8.38,
11.78,
11.06,
12.38,
12.05,
10.79,
10.30,
13.58,
12.89,
13.53,
12.24,
7.79,
8.72,
7.82,
12.37,
13.63,
10.00,
9.42,
10.67,
10.11,
12.67,
11.80,
9.61,
12.37,
11.06,
3.58,
10.20,
10.35,
14.39,
13.94,
8.59,
10.32,
12.94,
12.50,
12.62,
12.42,
14.21,
13.78,
12.90,
14.84,
11.75,
11.65,
11.32,
11.17,
11.63,
13.33,
12.38,
14.70,
12.17,
12.16,
12.76,
12.64,
9.53,
8.70,
8.61,
10.61,
14.67,
11.74,
12.22,
12.35,
12.79,
12.53,
12.89,
12.87,
10.33,
8.11,
11.18,
9.11,
6.47,
14.94,
15.67,
12.06,
7.11,
13.06,
9.56,
7.63,
11.00,
8.00,
9.15,
9.40,
11.06,
8.53,
12.00,
11.81,
9.89,
13.44,
11.56,
11.53,
10.37,
10.06,
9.32,
10.53,
11.75,
10.63,
14.62,
7.79,
7.95,
10.21,
8.22,
7.06,
14.00,
13.71,
7.84,
12.44,
13.05,
14.00,
7.83,
10.05,
8.95,
9.79,
9.95,
8.89,
11.00,
11.11,
9.32,
8.17,
8.00,
7.28,
7.87,
8.11,
12.65,
8.37,
9.72,
11.32,
8.65,
9.05,
12.82,
13.00,
8.83,
5.63,
11.30,
8.15,
7.50,
12.50,
11.63,
13.21,
11.95,
12.07,
13.58,
15.88,
13.67,
9.73,
11.71,
10.00,
11.11,
13.43,
15.12,
14.00,
11.11,
11.29,
11.24,
15.38,
7.05,
10.42,
15.07,
9.63,
6.63,
5.67,
7.21,
15.79,
17.80,
11.72,
17.13,
9.06,
13.40,
12.92,
12.44,
12.80,
11.79,
11.95,
10.21,
13.33,
13.26,
11.19,
10.06,
10.67,
10.74,
8.70,
14.17,
15.20,
14.71,
12.72,
11.40,
11.12,
11.00,
11.40,
8.89,
10.00,
10.00,
14.94,
10.65,
11.44,
8.50,
8.78,
8.00,
14.29,
9.59,
14.43,
12.83,
9.50,
9.47,
9.68,
9.76,
16.40,
8.33,
7.81,
9.83,
9.00,
7.25,
12.37,
12.19,
11.88,
13.30,
15.42,
14.00,
9.75,
11.94,
12.00,
5.28,
10.17,
10.65,
10.40,
9.56,
10.32,
8.53,
11.00,
10.68,
13.44,
14.20,
14.41,
7.56,
10.17,
9.11,
9.25,
14.17,
7.20,
7.89,
9.74,
10.38,
7.83,
7.00,
9.33,
10.89,
14.14,
14.54,
10.47,
11.38,
11.26,
12.61,
7.74,
12.00,
12.11,
6.60,
10.61,
14.00,
8.11,
13.17,
12.94,
11.28,
14.83,
13.00,
11.89,
8.44,
12.07,
12.65,
12.95,
10.33,
9.17,
10.95,
11.00,
12.11,
12.78,
12.50,
12.65,
13.00,
15.88,
13.38,
9.50,
9.12,
13.89,
13.78,
14.12,
13.76,
12.26,
14.29,
13.05,
9.89,
12.17,
10.63,
11.20,
12.42,
12.57,
12.89,
6.05,
10.37,
10.83,
11.28,
11.65,
17.71,
14.86,
13.22,
12.59,
10.58,
12.11,
10.32,
12.00,
10.50,
12.58,
8.84,
12.38,
13.00,
8.74,
11.53,
13.47,
8.67,
8.44,
15.58,
13.42,
15.64,
14.55,
8.42,
10.35,
10.58,
11.05,
7.63,
11.00,
14.17,
8.55,
14.44,
9.81,
13.88,
15.06,
10.53,
7.17,
8.25,
10.00,
8.33,
10.22,
8.75,
14.40,
10.53,
10.00,
9.52,
10.05,
10.05,
11.22,
11.89,
11.53,
11.17,
10.22,
14.33,
10.11,
9.53,
11.53,
9.22,
9.79,
13.18,
10.72,
9.00,
10.74,
9.11,
11.32,
8.47,
9.72,
11.94,
13.58,
10.82,
11.61,
11.61,
12.53,
12.22,
12.88,
12.07,
9.28,
10.00,
8.95,
12.53,
12.89,
9.33,
10.93,
11.67,
11.21,
11.00,
12.53,
13.31,
14.47,
9.28,
12.62,
11.67,
10.00,
10.65,
10.90,
8.50,
9.84,
8.33,
11.15,
6.26,
10.78,
12.21,
10.11,
11.00,
14.31,
14.64,
8.84,
12.56,
11.05,
7.22,
6.89,
10.15,
10.58,
10.62,
4.22,
8.47,
12.00,
7.95,
11.19,
12.95,
5.95,
11.58,
8.84,
9.47,
13.12,
9.74,
11.60,
12.59,
8.95,
11.17,
12.94,
10.78,
10.89,
9.06,
11.76,
10.37,
9.50,
9.47,
8.11,
9.72,
11.00,
9.84,
6.63,
11.00,
12.53,
10.53,
8.22,
13.67,
13.47,
13.06,
10.50,
15.35,
10.00,
8.42,
14.00,
11.74,
11.79,
13.24,
13.70,
10.50,
10.40,
12.94,
11.32,
11.50,
8.79,
10.00,
13.00,
12.41,
7.84,
13.94,
10.11,
8.00,
11.06,
8.53,
12.61,
15.37,
14.36,
9.47,
8.95,
11.18,
15.00,
15.31,
11.30,
15.78,
14.29,
7.41,
11.56,
10.76,
15.62,
17.31,
17.00,
15.27,
10.50,
11.63,
9.11,
12.17,
11.50,
7.60,
12.17,
13.20,
12.75,
13.11,
10.33,
11.72,
12.29,
13.53,
10.53,
15.00,
13.77,
13.06,
13.17,
14.06,
9.89,
10.33,
7.61,
8.74,
11.90,
13.11,
14.00,
12.88,
13.42,
15.31,
13.86,
15.86,
13.44,
14.63,
13.56,
15.00,
8.00,
10.71,
5.61,
10.06,
10.75,
9.05,
9.56,
14.42,
13.94,
13.12,
10.05,
9.44,
13.94,
6.89,
7.17,
10.67,
12.22,
13.06,
9.47,
10.82,
13.40,
12.00,
9.95,
12.67,
8.00,
10.26,
11.74,
13.93,
13.62,
12.78,
13.56,
14.82,
10.47,
13.06,
12.11,
11.50,
13.78,
8.11,
6.89,
8.84,
14.19,
13.11,
8.84,
9.68,
12.59,
11.67,
10.31,
10.33,
16.53,
10.00,
12.42,
10.17,
10.47,
11.28,
13.21,
14.69,
12.10,
10.67,
9.74,
10.00,
10.28,
11.85,
8.28,
9.26,
11.00,
13.50,
13.71,
12.41,
6.89,
8.11,
6.05,
9.89,
7.79,
9.56,
10.72,
9.80,
9.16,
9.78,
12.47,
12.95,
15.75,
13.69,
14.25,
17.38,
14.41,
13.57,
14.30,
15.11,
11.06,
18.07,
5.89,
6.00,
13.47,
15.45,
14.70,
11.53,
12.67,
10.88,
10.53,
5.47,
14.25,
7.16,
10.65,
12.06,
6.30,
11.38,
15.00,
13.00,
11.44,
5.25,
8.56,
14.37,
12.71,
3.72,
6.32,
8.33,
9.28,
7.80,
11.69,
10.67,
11.94,
10.50,
12.67,
6.79,
12.72,
7.17,
4.67,
6.37,
8.84,
14.00,
11.85,
7.00,
9.15,
15.67,
12.83,
9.84,
9.15,
8.26,
7.85,
9.67,
12.00,
8.95,
8.93,
12.71,
4.35,
10.21,
9.94,
9.32,
14.43,
10.00,
11.41,
7.75,
9.16,
11.47,
15.20,
11.11,
11.60,
14.15,
10.95,
15.75,
8.05,
11.36,
10.25,
8.95,
4.53,
8.80,
9.74,
7.53,
9.88,
10.69,
13.78,
10.28,
8.69,
4.95,
9.95,
10.16,
12.53,
6.11,
10.84,
7.11,
7.22,
12.11,
15.60,
8.29,
8.47,
6.58,
13.08,
10.39,
6.72,
10.56,
7.58,
8.16,
16.00,
14.86,
10.42,
11.74,
12.89,
13.17,
11.44,
10.28,
10.00,
10.86,
10.71,
14.25,
4.90,
6.44,
9.59,
7.90,
9.47,
12.83,
11.21,
13.32,
12.00,
17.25,
7.15,
4.55,
11.62,
9.28,
14.12,
12.88,
7.32,
8.26,
13.80,
12.07,
9.83,
6.89,
7.56,
8.58,
13.00,
4.79,
8.50,
7.11,
9.48,
7.28,
5.74,
7.43,
6.38,
6.69,
5.35,
10.61,
10.30,
13.16,
3.22,
7.06,
5.63,
13.93,
5.78,
9.72,
15.00,
7.42,
6.95,
8.33,
15.40,
14.33,
0.00,
11.48,
14.92,
12.07,
13.00,
13.62,
6.65,
4.47,
11.63,
11.37,
6.65,
10.80,
6.07,
6.32,
15.33,
12.07,
7.60,
12.13,
8.10,
11.62,
9.52,
9.28,
13.62,
8.11,
13.29,
13.17,
13.00,
10.30,
13.28,
12.33,
5.00,
7.84,
8.37,
11.93,
5.89,
12.31,
11.59,
13.64,
9.39,
9.73,
4.22,
12.73,
13.88,
8.72,
9.21,
4.11,
7.58,
10.50,
6.41,
5.94,
10.57,
8.94,
5.44,
5.21,
8.21,
6.11,
8.12,
4.89,
10.69,
13.44,
8.78,
18.00,
7.57,
10.32,
7.89,
8.11,
10.18,
13.50,
11.85,
11.15,
17.00,
12.67,
11.26,
12.71,
6.95,
8.71,
13.42,
11.44,
17.29,
12.33,
6.21,
12.40,
10.38,
8.33,
7.69,
11.17,
8.39,
12.82,
4.75,
12.74,
16.00,
10.24,
8.75,
7.16,
9.84,
9.84,
12.84,
13.71,
12.19,
11.58,
13.50,
11.59,
11.40,
12.68,
9.26,
15.00,
12.35,
11.44,
7.00,
6.05,
7.75,
15.00,
8.71,
7.68,
7.17,
12.65,
8.00,
6.00,
7.62,
10.84,
7.53,
10.63,
10.31,
9.59,
10.89,
14.00,
12.67,
12.31,
13.25,
6.83,
14.30,
9.26,
10.39,
12.27,
13.06,
13.31,
7.11,
8.89,
13.33,
13.12,
11.98,
7.29,
13.25,
7.58,
4.61,
11.42,
10.58,
7.16,
12.38,
12.00,
4.72,
5.94,
9.89,
11.56,
11.00,
8.33,
12.57,
14.47,
14.50,
12.08,
9.00,
11.17,
9.56,
15.92,
8.95,
10.93,
15.25,
4.47,
12.55,
8.28,
8.37,
13.86,
7.78,
8.27,
15.36,
13.29,
5.00,
5.68,
10.28,
8.39,
10.07,
8.35,
11.60,
14.00,
11.05,
7.95,
12.17,
6.70,
10.35,
12.38,
12.46,
7.75,
7.39,
10.37,
15.77,
14.57,
10.32,
11.00,
11.44,
9.17,
10.44,
12.26,
12.75,
13.65,
9.26,
15.39,
7.29,
11.85,
10.42,
13.56,
9.00,
11.92,
13.64,
11.62,
5.83,
11.46,
10.82,
14.14,
11.22,
12.00,
8.53,
10.18,
9.61,
11.94,
11.07,
11.28,
14.20,
12.36,
14.00,
15.60,
15.50,
3.24,
8.83,
6.42,
11.11,
11.37,
11.71,
15.16,
13.72,
13.30,
5.00,
17.74,
19.07,
15.44,
8.44,
5.11,
9.67,
11.00,
8.19,
8.06,
5.84,
6.53,
13.83,
14.89,
6.95,
10.42,
15.82,
19.00,
11.39,
10.88,
12.50,
8.52,
18.20,
6.47,
4.65,
10.69,
8.31,
6.44,
8.65,
7.49,
10.18,
10.53,
10.06,
8.74,
14.83,
13.64,
13.92,
5.61,
6.89,
7.18,
13.44,
10.11,
6.76,
7.89,
8.83,
8.29,
12.11,
8.11,
8.39,
7.80,
16.07,
15.62,
11.71,
12.50,
8.42,
10.76,
10.56,
11.29,
8.00,
14.89,
9.50,
9.00,
10.31,
9.78,
5.05,
7.89,
7.22,
12.27,
15.67,
5.42,
9.39,
5.33,
15.00,
13.27,
11.28,
14.06,
7.42,
10.11,
12.10,
9.05,
9.12,
12.60,
14.33,
12.50,
13.13,
5.62,
10.11,
14.44,
16.57,
7.21,
10.35,
10.47,
12.73,
12.89,
15.64,
15.33,
12.68,
13.88,
9.72,
13.22,
4.63,
5.21,
13.00,
7.83,
8.79,
10.41,
12.24,
4.14,
5.42,
9.75,
14.25,
8.74,
7.05,
6.33,
11.21,
10.05,
9.44,
4.79,
8.50,
16.33,
15.11,
16.62,
10.88,
10.50,
11.10,
10.89,
10.89,
10.29,
7.53,
12.36,
15.33,
11.67,
9.20,
11.90,
9.22,
9.44,
13.19,
11.30,
9.06,
11.05,
17.00,
11.93,
7.74,
14.93,
13.44,
12.92,
11.36,
15.47,
7.67,
11.35,
16.53,
10.53,
8.22,
9.11,
12.35,
12.00,
11.00,
11.68,
16.43,
16.75,
13.21,
10.37,
14.12,
11.75,
13.07,
14.22,
12.39,
14.12,
14.47,
9.53,
8.84,
9.68,
10.00,
9.95,
12.84,
10.63,
12.05,
5.48,
13.36,
16.33,
5.37,
12.64,
7.89,
8.44,
12.06,
10.53,
16.90,
9.26,
10.28,
12.28,
16.25,
6.05,
18.40,
16.00,
10.05,
13.42,
12.00,
13.92,
5.44,
7.84,
8.79,
6.68,
10.14,
13.78,
12.35,
5.36,
12.56,
7.16,
9.58,
9.60,
8.75,
9.10,
8.89,
3.42,
4.95,
9.56,
6.65,
14.09,
9.06,
12.00,
15.40,
12.11,
11.22,
14.13,
12.12,
10.26,
8.85,
11.53,
11.92,
6.48,
11.26,
11.94,
6.68,
10.73,
10.75,
7.83,
10.63,
10.43,
7.78,
14.50,
9.60,
10.83,
11.06,
17.83,
11.89,
15.78,
11.37,
15.20,
12.16,
13.67,
11.75,
11.67,
14.88,
5.68,
10.88,
10.53,
10.38,
11.65,
4.22,
5.56,
3.79,
16.40,
5.68,
13.00,
6.89,
11.36,
14.17,
4.26,
11.76,
13.57,
13.33,
18.12,
14.25,
7.95,
10.16,
9.95,
13.50,
15.23,
10.63,
9.32,
10.95,
14.00,
8.17,
9.70,
15.18,
9.11,
5.94,
7.70,
10.15,
7.47,
10.61,
14.45,
9.65,
11.67,
15.40,
14.08,
12.71,
13.62,
15.08,
13.44,
12.33,
15.10,
13.00,
16.17,
12.40,
14.80,
15.30,
15.33,
10.64,
12.64,
15.62,
11.00,
12.06,
12.91,
15.00,
12.50,
10.37,
10.25,
11.11,
9.00,
3.89,
5.76,
6.10,
6.63,
6.33,
5.84,
6.68,
6.76,
6.37,
12.50,
8.00,
6.56,
14.00,
5.53,
4.06,
5.21,
5.50,
12.33,
20.25,
15.50,
16.09,
15.55,
7.32,
8.74,
6.89,
14.82,
15.30,
14.54,
14.33,
16.08,
15.50,
14.53,
16.85,
4.56,
4.50,
15.00,
15.50,
14.22,
12.48,
8.53,
13.00,
14.07,
10.26,
5.14,
7.21,
6.00,
6.68,
9.83,
19.40,
9.58,
9.19,
5.83,
7.37,
7.53,
7.50,
9.00,
8.32,
8.82,
10.32,
10.67,
7.84,
9.36,
11.83,
12.82,
10.94,
14.06,
6.94,
9.00,
6.41,
12.82,
9.56,
9.32,
12.69,
9.24,
8.83,
5.61,
11.59,
7.68,
7.56,
6.21,
8.18,
9.50,
10.72,
11.50,
9.11,
5.61,
7.62,
5.16,
11.73,
9.11,
4.20,
7.65,
8.17,
9.11,
5.84,
13.06,
10.16,
16.83,
12.22,
12.81,
11.76,
6.65,
8.94,
6.00,
10.95,
5.67,
9.53,
10.10,
9.89,
10.25,
13.00,
9.79,
8.82,
7.28,
10.74,
14.88,
13.64,
9.89,
14.53,
13.87,
14.44,
10.35,
12.06,
8.70,
8.21,
11.50,
11.07,
10.32,
8.24,
11.44,
10.32,
6.67,
11.84,
16.92,
14.21,
14.59,
13.00,
7.60,
8.56,
9.53,
10.31,
13.93,
15.00,
9.00,
9.84,
12.79,
11.22,
7.47,
9.83,
11.00,
10.28,
15.50,
15.12,
16.33,
12.22,
15.44,
9.44,
10.64,
10.58,
11.46,
11.87,
4.74,
10.89,
10.68,
7.82,
10.67,
12.94,
11.50,
9.11,
6.86,
9.65,
12.40,
5.42,
5.83,
5.42,
12.92,
10.12,
10.36,
7.60,
9.45,
9.20,
10.95,
13.05,
9.71,
15.00,
11.47,
10.74,
12.15,
11.78,
8.72,
8.10,
8.15,
11.68,
10.88,
7.06,
6.44,
8.89,
8.74,
7.24,
6.05,
10.12,
8.17,
8.52,
7.45,
4.58,
6.47,
6.43,
12.81,
10.15,
6.56,
11.83,
6.74,
10.05,
13.23,
13.10,
9.61,
12.11,
13.20,
9.95,
11.10,
10.39,
4.68,
9.11,
9.16,
8.89,
5.39,
11.00,
13.08,
7.75,
12.11,
13.00,
12.05,
9.63,
8.58,
12.94,
13.39,
13.64,
6.78,
10.68,
13.00,
13.67,
15.00,
8.42,
8.06,
8.00,
11.84,
13.20,
11.85,
13.53,
12.75,
11.28,
11.47,
12.80,
14.20,
11.95,
12.72,
13.44,
13.83,
11.89,
11.50,
12.28,
16.67,
3.06,
9.56,
4.72,
11.56,
7.11,
6.89,
9.12,
9.68,
6.94,
7.68,
6.74,
9.79,
10.06,
13.94,
10.56,
14.46,
4.81,
13.28,
11.11,
11.79,
11.17,
11.28,
13.67,
12.39,
10.65,
12.71,
13.68,
13.00,
13.24,
12.62,
10.15,
9.74,
7.95,
8.00,
14.75,
7.21,
9.50,
12.00,
14.00,
11.65,
14.67,
5.00,
5.26,
6.61,
9.28,
11.15,
12.05,
7.05,
10.30,
6.84,
10.95,
11.11,
13.40,
15.59,
13.53,
14.33,
13.06,
12.37,
12.25,
8.85,
11.80,
14.93,
13.61,
13.11,
13.71,
7.95,
8.39,
14.79,
13.12,
10.67,
13.00,
15.12,
12.67,
11.80,
13.14,
12.39,
16.67,
13.38,
13.35,
12.69,
14.80,
14.81,
11.11,
10.17,
14.00,
10.79,
9.58,
13.19,
5.68,
7.28,
11.00,
17.75,
17.50,
11.26,
11.39,
8.26,
12.75,
12.41,
9.90,
11.05,
9.17,
8.94,
13.37,
12.83,
14.06,
13.61,
7.32,
9.95,
10.06,
10.65,
8.78,
8.19,
9.11,
11.80,
14.11,
10.84,
12.10,
13.61,
11.53,
14.12,
5.84,
11.61,
15.57,
13.61,
11.77,
11.89,
12.05,
13.21,
12.17,
11.56,
13.11,
11.12,
11.33,
6.63,
9.78,
8.89,
11.94,
9.33,
14.40,
12.33,
16.33,
15.00,
11.13,
17.00,
14.94,
14.18,
15.15,
17.00,
10.22,
10.28,
13.08,
12.84,
13.67,
10.50,
15.07,
16.50,
8.76,
9.20,
17.22,
13.57,
17.17,
16.73,
11.22,
13.79,
12.61,
11.38,
11.05,
10.33,
13.08,
9.00,
10.42,
13.80,
13.84,
9.05,
13.25,
6.58,
8.10,
6.60,
15.12,
16.80,
8.00,
12.89,
11.29,
12.29,
10.22,
7.89,
7.17,
9.94,
8.00,
13.68,
8.79,
15.27,
17.17,
11.71,
12.92,
16.71,
7.41,
15.67,
14.67,
9.89,
6.00,
10.40,
14.36,
9.64,
8.26,
8.74,
9.72,
8.89,
5.36,
6.96,
9.05,
6.77,
14.39,
9.05,
11.06,
6.05,
8.47,
7.05,
10.85,
6.11,
5.71,
9.79,
15.00,
11.68,
13.63,
11.32,
14.72,
15.79,
17.60,
13.83,
13.79,
12.40,
13.45,
11.33,
11.83,
10.65,
10.56,
6.15,
12.16,
6.37,
5.89,
8.50,
5.00,
6.60,
10.65,
10.17,
10.53,
5.26,
12.32,
8.33,
8.84,
8.00,
11.94,
8.42,
14.79,
7.00,
10.33,
11.39,
11.60,
12.25,
16.42,
14.07,
13.86,
11.16,
6.55,
7.89,
9.79,
10.50,
5.39,
10.00,
11.89,
13.33,
13.69,
4.47,
10.09,
12.71,
9.00,
9.90,
4.84,
14.56,
14.25,
8.47,
5.47,
8.94,
6.11,
5.61,
6.44,
9.61,
8.58,
11.06,
13.10,
9.33,
8.00,
10.44,
5.89,
8.39,
7.89,
9.74,
3.57,
11.07,
8.67,
9.46,
15.22,
10.93,
14.13,
13.25,
9.92,
7.33,
9.87,
10.39,
4.25,
7.11,
13.80,
9.10,
11.56,
13.71,
10.21,
9.63,
5.33,
10.73,
12.89,
12.82,
10.67,
14.94,
5.50,
5.22,
12.87,
12.75,
9.10,
13.17,
9.21,
6.38,
10.50,
6.67,
9.42,
7.00,
6.00,
13.58,
16.92,
6.50,
9.17,
11.06,
12.12,
11.68,
12.88,
11.77,
17.00,
11.16,
11.92,
10.00,
10.05,
8.84,
10.00,
8.39,
9.12,
11.29,
5.79,
8.21,
8.56,
9.20,
7.00,
7.69,
10.50,
4.32,
5.33,
10.12,
7.50,
9.29,
8.68,
9.42,
9.74,
9.94,
9.15,
8.69,
10.35,
12.29,
10.47,
18.56,
10.92,
13.00,
14.36,
3.53,
10.41,
7.00,
6.47,
8.89,
6.53,
12.76,
11.84,
11.53,
10.06,
7.50,
10.87,
13.07,
12.67,
7.53,
11.58,
7.61,
12.26,
11.16,
11.61,
2.60,
7.89,
4.16,
7.58,
7.05,
6.21,
11.42,
11.40,
5.53,
8.00,
8.72,
5.78,
8.11,
11.07,
8.47,
8.74,
8.83,
6.58,
4.70,
13.06,
11.07,
6.93,
6.83,
4.32,
9.89,
8.78,
6.79,
11.82,
9.28,
7.22,
7.33,
13.28,
11.11,
14.14,
12.67,
8.11,
3.95,
11.26,
13.00,
8.24,
13.50,
5.84,
7.53,
4.50,
4.72,
7.20,
6.70,
6.28,
6.65,
6.21,
9.05,
11.72,
12.75,
9.94,
12.12,
8.39,
4.72,
8.05,
11.72,
11.79,
10.21,
11.50,
7.32,
12.67,
11.33,
11.22,
12.06,
10.26,
12.06,
9.28,
8.78,
11.82,
11.37,
11.82,
13.11,
8.94,
12.14,
11.44,
7.11,
12.50,
9.63,
7.06,
10.83,
9.61,
14.22,
11.65,
13.83,
8.06,
8.89,
7.94,
8.94,
14.58,
9.76,
10.16,
8.95,
10.63,
10.10,
8.20,
11.33,
13.53,
5.75,
4.89,
8.74,
8.25,
8.06,
9.30,
7.83,
11.62,
10.11,
8.37,
5.32,
7.63,
12.62,
15.50,
14.45,
7.58,
4.05,
10.76,
9.88,
7.74,
7.26,
11.35,
7.37,
5.53,
11.58,
8.76,
4.72,
11.78,
12.06,
12.61,
10.24,
9.53,
8.44,
9.78,
9.85,
8.32,
13.81,
10.38,
10.20,
15.43,
9.94,
10.32,
11.00,
9.84,
9.35,
12.50,
11.00,
4.28,
11.22,
7.00,
11.25,
9.18,
12.28,
11.44,
11.82,
12.36,
9.60,
11.00,
7.39,
5.44,
9.79,
9.05,
6.63,
11.00,
11.42,
15.25,
14.36,
12.75,
4.32,
12.26,
6.67,
8.50,
15.13,
8.83,
10.06,
7.53,
13.09,
10.11,
9.89,
12.53,
11.89,
13.33,
15.75,
7.89,
4.60,
11.84,
4.42,
8.90,
15.86,
13.75,
12.67,
13.55,
7.39,
11.11,
12.94,
7.95,
9.10,
7.33,
5.58,
4.17,
9.93,
11.75,
6.21,
11.73,
11.88,
11.16,
5.89,
8.50,
14.27,
7.33,
8.89,
11.68,
9.65,
10.26,
14.00,
17.27,
11.85,
14.00,
6.89,
12.45,
10.88,
9.89,
12.11,
8.53,
10.72,
7.92,
16.17,
11.50,
11.37,
10.53,
10.11,
11.68,
6.74,
6.85,
8.79,
9.60,
8.67,
13.69,
12.56,
11.77,
8.79,
9.28,
3.47,
11.57,
5.06,
6.68,
10.12,
7.67,
9.80,
14.55,
10.75,
4.47,
6.90,
11.50,
10.26,
7.35,
15.17,
10.05,
14.00,
4.26,
11.88,
8.00,
6.37,
10.12,
7.06,
11.33,
16.50,
12.00,
10.79,
7.40,
3.68,
9.53,
8.35,
3.47,
13.00,
10.05,
8.63,
5.68,
13.11,
9.26,
11.85,
5.25,
10.50,
4.80,
5.36,
5.74,
10.11,
4.84,
8.16,
5.37,
6.94,
9.84,
6.57,
7.84,
12.53,
11.20,
9.44,
6.68,
11.17,
10.74,
12.47,
9.00,
7.52,
9.53,
8.84,
11.59,
10.94,
8.94,
6.68,
8.11,
8.31,
8.88,
13.20,
7.89,
10.06,
6.80,
8.68,
8.20,
8.84,
10.82,
11.25,
10.67,
4.48,
9.39,
15.06,
12.56,
9.80,
5.16,
11.39,
4.68,
10.45,
16.60,
11.83,
5.67,
9.17,
10.83,
10.00,
7.07,
12.67,
11.40,
13.29,
6.33,
14.06,
13.56,
5.32,
4.17,
12.09,
8.78,
9.28,
13.27,
10.18,
8.60,
11.00,
8.80,
8.26,
12.18,
9.88,
8.53,
9.63,
12.18,
9.89,
10.29,
11.67,
16.40,
12.33,
13.75,
6.94,
10.10,
14.00,
13.78,
13.36,
10.68,
7.33,
4.67,
12.30,
11.60,
10.33,
12.29,
7.47,
11.56,
9.25,
10.58,
13.75,
9.56,
11.88,
10.80,
7.84,
12.83,
10.42,
8.32,
13.07,
8.28,
9.39,
11.50,
11.21,
12.48,
11.85,
4.89,
10.06,
9.52,
11.17,
2.79,
7.33,
6.00,
8.45,
10.06,
5.50,
7.00,
8.89,
7.44,
3.37,
5.06,
11.72,
9.58,
4.94,
6.75,
6.00,
13.29,
10.11,
10.82,
13.27,
9.80,
5.69,
8.79,
9.30,
9.33,
4.79,
10.06,
7.45,
10.33,
8.12,
12.19,
7.26,
6.78,
6.28,
8.67,
12.53,
6.53,
9.37,
7.16,
6.17,
10.37,
11.05,
11.50,
5.26,
6.72,
15.64,
12.64,
9.84,
10.15,
10.44,
8.05,
9.56,
7.11,
7.11,
7.67,
9.56,
12.64,
10.63,
13.00,
11.07,
7.95,
8.67,
10.58,
7.37,
5.89,
8.59,
6.85,
8.37,
12.53,
12.62,
11.81,
9.11,
8.37,
14.65,
13.44,
4.15,
6.68,
6.32,
8.16,
5.45,
8.80,
6.00,
14.67,
11.76,
8.38,
9.05,
14.36,
11.90,
8.83,
14.07,
14.53,
10.06,
10.58,
10.72,
6.76,
12.88,
6.95,
7.14,
12.67,
12.40,
8.55,
5.94,
9.50,
3.22,
8.16,
6.47,
10.71,
9.85,
13.11,
5.50,
8.32,
9.33,
7.26,
5.94,
4.65,
9.06,
9.19,
13.00,
8.84,
11.14,
4.22,
8.29,
4.32,
13.64,
14.38,
15.44,
9.17,
11.89,
0.00,
3.51,
5.71,
8.00,
11.50,
8.07,
6.26,
10.47,
10.56,
4.00,
10.89,
11.89,
6.19,
10.89,
8.94,
6.73,
6.58,
6.74,
6.63,
5.61,
14.43,
8.73,
7.61,
9.46,
6.63,
12.44,
6.11,
8.65,
9.33,
9.94,
9.95,
10.56,
12.74,
13.29,
2.78,
14.80,
12.07,
13.86,
9.75,
9.88,
5.79,
5.10,
6.72,
7.33,
11.75,
9.28,
8.53,
6.05,
10.94,
8.39,
12.86,
12.47,
9.05,
10.00,
10.68,
16.56,
8.75,
8.18,
9.88,
10.37,
5.83,
6.25,
8.28,
6.58,
9.20,
4.17,
6.63,
16.33,
8.84,
10.33,
6.16,
8.79,
7.22,
12.93,
12.71,
7.24,
11.50,
11.05,
13.20,
10.00,
10.58,
11.20,
10.56,
9.83,
12.33,
9.32,
10.88,
9.24,
9.72,
10.75,
11.22,
8.44,
9.39,
5.47,
11.75,
9.06,
6.84,
10.41,
5.94,
7.79,
9.11,
9.71,
7.42,
5.47,
7.22,
6.83,
4.11,
4.74,
10.33,
10.12,
11.14,
11.15,
11.18,
10.76,
9.90,
11.37,
7.47,
5.63,
5.32,
3.47,
8.78,
8.21,
7.68,
5.89,
6.39,
4.83,
12.10,
11.79,
12.47,
6.38,
5.39,
5.15,
6.53,
3.17,
8.00,
17.06,
14.71,
5.72,
9.58,
12.62,
9.00,
10.84,
12.89,
10.83,
13.26,
17.33,
13.00,
5.89,
13.63,
9.94,
10.43,
13.68,
13.61,
13.76,
12.56,
12.83,
10.17,
12.38,
8.70,
16.00,
14.33,
12.44,
12.83,
13.28,
14.67,
15.67,
2.94,
8.06,
6.79,
10.82,
4.42,
15.22,
15.15,
9.72,
13.33,
14.26,
14.95,
14.38,
4.50,
3.95,
6.42,
7.37,
9.44,
10.81,
9.05,
9.47,
4.83,
12.21,
12.12,
5.90,
6.48,
13.41,
12.69,
14.73,
12.73,
9.05,
11.86,
13.12,
6.94,
11.06,
12.19,
7.06,
12.50,
12.33,
14.38,
16.00,
11.71,
13.78,
12.53,
12.53,
16.00,
7.79,
13.30,
11.26,
10.53,
13.06,
15.56,
17.43,
8.95,
9.16,
12.00,
7.28,
10.05,
10.50,
11.94,
11.54,
9.00,
10.06,
6.58,
13.71,
12.65,
10.00,
14.86,
11.80,
9.06,
4.85,
5.31,
6.32,
8.02,
4.92,
6.41,
5.63,
5.05,
6.53,
6.37,
7.06,
6.26,
15.20,
14.56,
17.25,
16.75,
13.33,
3.78,
11.42,
14.93,
4.26,
7.47,
6.39,
12.21,
12.09,
7.72,
9.37,
11.00,
12.38,
11.47,
14.50,
4.83,
7.20,
9.44,
8.40,
8.40,
14.11,
14.29,
11.07,
12.71,
13.71,
14.05,
11.05,
10.16,
15.62,
10.50,
13.07,
15.22,
9.76,
9.88,
11.44,
12.00,
11.37,
9.82,
10.39,
13.62,
13.85,
5.84,
11.36,
7.60,
13.92,
14.22,
14.00,
8.42,
10.11,
12.44,
3.44,
6.81,
11.00,
13.38,
13.92,
16.00,
17.33,
11.80,
13.72,
15.00,
6.17,
11.37,
11.39,
11.32,
3.72,
11.20,
11.12,
11.90,
11.20,
8.56,
5.37,
15.40,
7.29,
4.89,
9.00,
9.21,
9.58,
8.83,
15.07,
15.62,
6.06,
9.72,
8.63,
12.63,
9.58,
14.00,
7.56,
9.68,
10.83,
11.47,
8.89,
10.17,
11.24,
7.79,
8.00,
13.00,
14.13,
10.20,
9.67,
14.70,
13.56,
15.29,
11.60,
11.06,
5.67,
6.85,
7.63,
13.12,
10.00,
4.67,
6.75,
8.78,
8.58,
10.11,
8.11,
7.99,
14.50,
4.33,
14.43,
13.58,
12.06,
9.89,
10.67,
9.11,
7.94,
4.22,
10.83,
3.86,
12.50,
10.94,
8.35,
10.07,
9.94,
7.95,
8.07,
5.95,
6.26,
7.56,
4.53,
9.58,
7.49,
15.43,
11.83,
8.89,
12.79,
11.65,
8.80,
13.19,
13.31,
9.68,
7.32,
11.25,
11.74,
3.56,
11.33,
6.11,
9.72,
7.22,
12.47,
11.67,
7.16,
12.47,
5.00,
10.05,
11.21,
11.33,
9.44,
9.05,
10.53,
8.32,
9.28,
12.39,
11.82,
9.21,
11.72,
15.92,
16.90,
8.48,
9.26,
12.94,
9.37,
8.95,
10.89,
13.67,
13.43,
13.29,
14.33,
16.67,
14.79,
16.15,
15.18,
16.14,
16.64,
12.20,
11.28,
12.57,
12.89,
11.78,
14.27,
6.22,
13.91,
8.68,
10.89,
10.47,
11.11,
5.11,
12.27,
6.50,
10.12,
9.35,
9.44,
9.00,
6.63,
5.06,
10.00,
11.56,
10.33,
11.42,
7.39,
5.86,
12.46,
6.44,
9.83,
8.53,
13.21,
11.14,
16.89,
16.71,
15.06,
8.67,
15.83,
8.26,
12.17,
13.38,
13.80,
10.22,
17.33,
11.88,
6.78,
6.69,
12.50,
5.06,
3.43,
4.90,
10.00,
12.82,
14.00,
9.26,
9.50,
7.58,
7.67,
3.84,
11.39,
13.72,
11.21,
5.00,
4.94,
9.73,
10.72,
12.75,
10.71,
7.35,
11.05,
11.89,
14.91,
13.30,
9.89,
12.27,
8.02,
10.61,
13.29,
12.72,
15.44,
10.22,
8.89,
11.69,
7.11,
9.17,
12.26,
8.56,
8.70,
11.47,
13.22,
12.94,
9.05,
11.44,
12.50,
5.06,
7.53,
11.70,
10.89,
11.22,
12.00,
9.73,
11.12,
3.67,
10.53,
6.05,
6.40,
8.05,
15.50,
11.35,
14.00,
7.22,
14.92,
10.79,
17.33,
16.20,
13.32,
14.94,
9.16,
4.72,
6.25,
9.50,
8.33,
10.38,
10.56,
12.87,
6.30,
10.30,
8.89,
8.06,
6.23,
9.05,
10.50,
12.25,
15.10,
5.00,
14.58,
14.86,
8.89,
10.72,
13.37,
12.95,
9.85,
9.72,
6.56,
6.00,
8.00,
2.50,
16.00,
5.39,
12.86,
12.30,
10.76,
16.50,
5.74,
8.79,
12.93,
9.16,
9.67,
10.78,
9.56,
9.28,
9.78,
9.48,
11.89,
7.05,
5.39,
7.47,
7.94,
11.57,
6.78,
12.56,
9.94,
8.00,
9.06,
9.60,
11.50,
5.80,
7.71,
5.72,
9.21,
14.60,
10.39,
10.31,
5.50,
11.25,
14.88,
5.65,
8.89,
5.50,
5.53,
8.61,
9.74,
8.46,
9.17,
17.43,
12.94,
9.11,
10.84,
12.27,
9.05,
14.33,
10.85,
8.38,
9.35,
14.55,
14.50,
11.88,
10.00,
10.05,
14.58,
8.00,
10.83,
13.94,
12.87,
10.44,
9.55,
13.53,
13.71,
11.59,
13.67,
11.05,
4.11,
6.94,
9.86,
8.21,
11.39,
11.00,
8.16,
9.26,
10.83,
5.84,
9.16,
4.63,
7.00,
9.79,
10.22,
9.94,
5.42,
11.38,
8.12,
11.75,
14.81,
14.67,
14.12,
7.32,
8.11,
6.65,
8.05,
10.75,
11.00,
9.18,
7.70,
4.44,
6.53,
5.65,
7.75,
5.68,
6.40,
9.28,
11.25,
10.89,
11.50,
11.26,
13.94,
8.90,
10.21,
10.29,
5.63,
7.00,
10.00,
12.94,
11.42,
12.35,
6.32,
9.00,
15.38,
11.00,
12.85,
9.56,
11.86,
12.12,
13.22,
12.20,
11.43,
5.24,
9.59,
10.72,
3.84,
6.50,
4.22,
4.15,
8.94,
8.47,
11.17,
11.60,
7.61,
10.67,
8.22,
11.16,
7.75,
9.42,
14.40,
11.43,
10.94,
9.89,
6.33,
9.55,
10.00,
13.64,
16.69,
4.39,
9.20,
12.89,
10.72,
10.42,
10.56,
13.67,
9.58,
11.82,
10.55,
14.00,
12.11,
10.00,
7.28,
11.47,
13.50,
14.64,
7.18,
5.83,
3.89,
13.72,
10.67,
10.67,
10.06,
9.16,
7.61,
5.76,
10.17,
11.47,
12.01,
8.67,
11.17,
7.37,
13.00,
9.59,
7.89,
9.00,
4.37,
7.35,
9.17,
10.33,
11.25,
8.53,
8.32,
14.25,
6.32,
10.00,
13.22,
12.58,
6.39,
14.83,
12.29,
11.87,
13.73,
11.68,
8.89,
11.94,
12.11,
12.94,
14.06,
10.56,
9.95,
9.28,
7.11,
7.22,
8.42,
9.67,
11.00,
15.11,
12.65,
11.55,
12.06,
7.55,
14.00,
13.62,
11.12,
11.63,
10.39,
14.58,
11.44,
12.69,
4.22,
15.14,
11.28,
13.89,
13.30,
8.89,
6.70,
10.72,
6.63,
10.53,
6.17,
8.24,
10.58,
10.68,
6.26,
7.67,
9.11,
9.72,
11.12,
7.67,
7.06,
8.80,
10.88,
7.68,
11.38,
10.10,
10.27,
12.68,
8.00,
7.21,
12.54,
13.00,
12.70,
6.00,
13.22,
13.75,
7.56,
5.41,
12.79,
8.95,
14.32,
13.50,
11.53,
7.26,
11.60,
9.17,
6.94,
13.69,
13.50,
14.38,
13.56,
13.31,
11.74,
4.11,
7.72,
7.89,
8.61,
8.42,
7.22,
6.33,
8.94,
12.33,
8.94,
12.16,
3.67,
8.06,
7.94,
7.95,
5.95,
14.67,
13.67,
14.82,
14.13,
14.80,
12.47,
16.50,
10.56,
13.26,
12.67,
11.39,
13.72,
11.72,
11.68,
10.85,
10.70,
9.47,
15.60,
10.42,
12.65,
13.67,
13.18,
8.50,
13.71,
6.95,
12.17,
9.35,
13.74,
9.24,
3.89,
8.60,
4.42,
7.17,
11.95,
6.88,
10.57,
8.71,
4.06,
8.16,
10.32,
10.89,
10.56,
10.26,
15.56,
15.13,
13.26,
10.95,
12.24,
5.26,
13.05,
8.10,
12.67,
10.25,
12.16,
9.95,
12.05,
8.63,
11.80,
13.22,
14.58,
6.21,
7.79,
8.84,
6.78,
7.78,
4.30,
9.06,
4.33,
9.94,
9.93,
4.53,
13.06,
17.20,
14.57,
11.72,
13.79,
5.58,
8.56,
9.68,
7.39,
8.89,
13.50,
9.22,
11.88,
11.05,
11.17,
12.78,
6.68,
5.44,
10.89,
10.53,
10.42,
13.20,
10.15,
11.14,
13.79,
12.33,
14.00,
15.58,
14.19,
11.92,
15.07,
6.05,
16.00,
16.82,
16.33,
5.26,
4.47,
14.33,
5.79,
8.31,
4.44,
10.44,
13.25,
10.35,
14.16,
10.22,
11.71,
12.47,
8.67,
8.56,
6.21,
12.67,
9.76,
2.89,
14.56,
5.89,
10.50,
10.95,
7.61,
8.33,
10.42,
7.56,
4.76,
8.89,
8.56,
7.60,
9.56,
8.11,
11.83,
12.25,
7.65,
4.94,
11.00,
6.30,
3.89,
11.60,
4.94,
10.17,
6.05,
5.21,
6.79,
11.00,
10.12,
12.00,
10.12,
4.32,
9.53,
9.56,
9.50,
16.00,
9.11,
14.00,
10.65,
10.56,
6.80,
11.37,
7.63,
9.59,
11.39,
10.26,
8.61,
8.63,
9.63,
11.11,
11.11,
11.72,
8.39,
6.42,
9.28,
4.53,
7.74,
11.00,
10.39,
5.61,
11.89,
9.32,
13.24,
11.05,
10.89,
13.00,
8.50,
13.92,
12.16,
14.94,
14.90,
13.38,
4.22,
4.21,
7.58,
7.79,
11.26,
9.39,
6.47,
7.11,
13.00,
13.71,
12.83,
13.88,
4.58,
9.95,
6.63,
6.06,
12.61,
12.35,
12.50,
6.21,
9.70,
12.18,
10.72,
14.78,
17.78,
19.44,
10.00,
9.62,
12.22,
5.47,
9.39,
6.26,
10.00,
16.67,
14.43,
14.36,
15.50,
10.65,
7.56,
8.58,
13.86,
9.21,
14.33,
12.83,
11.78,
7.75,
13.92,
6.63,
9.17,
4.94,
8.88,
14.86,
14.40,
11.38,
5.74,
7.56,
7.65,
4.05,
4.65,
9.08,
12.89,
11.11,
14.84,
10.17,
8.95,
10.76,
14.58,
15.78,
9.33,
9.24,
7.83,
7.84,
4.58,
12.22,
9.78,
9.50,
12.20,
16.30,
12.80,
16.22,
16.25,
11.32,
9.45,
10.59,
9.80,
9.17,
13.58,
9.00,
15.25,
9.47,
9.28,
12.26,
7.17,
8.42,
9.75,
11.67,
10.78,
5.94,
9.56,
11.28,
10.65,
6.58,
5.00,
10.68,
7.68,
6.84,
16.88,
7.94,
13.12,
7.58,
9.81,
9.56,
11.33,
9.74,
8.74,
10.67,
8.29,
12.22,
9.68,
4.40,
7.10,
13.11,
7.50,
10.50,
8.78,
10.06,
8.65,
15.33,
7.58,
8.42,
8.39,
8.60,
11.58,
12.83,
14.95,
12.11,
12.74,
16.64,
13.88,
11.05,
13.40,
6.55,
15.88,
16.25,
12.94,
12.36,
11.50,
11.95,
15.06,
11.71,
12.32,
14.79,
11.81,
14.82,
14.09,
12.74,
10.30,
14.00,
11.17,
16.87,
12.41,
10.53,
12.23,
12.19,
7.30,
12.56,
12.26,
14.67,
13.75,
15.86,
13.50,
15.00,
15.25,
13.94,
13.00,
13.80,
13.76,
11.89,
15.00,
15.47,
14.69,
11.93,
7.94,
11.00,
8.68,
15.00,
11.00,
11.50,
11.37,
11.33,
12.90,
8.58,
10.60,
15.33,
12.33,
15.41,
14.72,
12.75,
11.14,
12.88,
11.94,
11.00,
10.94,
14.27,
14.00,
9.84,
9.94,
10.37,
14.05,
13.05,
13.87,
14.07,
11.67,
10.44,
15.11,
12.44,
14.00,
14.84,
12.44,
12.54,
13.18,
11.12,
14.35,
13.14,
14.00,
9.68,
13.84,
10.67,
14.27,
13.11,
15.05,
12.81,
7.06,
8.85,
16.00,
14.42,
13.89,
15.30,
12.88,
13.21,
12.61,
13.50,
9.84,
11.63,
11.44,
12.53,
10.00,
6.53,
6.28,
13.18,
9.52,
11.56,
11.67,
15.67,
12.06,
14.31,
13.81,
13.21,
7.68,
11.72,
16.25,
8.16,
9.25,
8.56,
10.76,
11.89,
12.24,
14.24,
14.50,
10.20,
15.82,
12.32,
13.00,
6.42,
13.36,
5.58,
6.11,
11.24,
9.61,
14.00,
9.42,
6.67,
7.38,
9.40,
6.79,
8.33,
11.00,
10.50,
11.25,
8.89,
12.71,
9.26,
7.49,
12.26,
12.26,
11.00,
9.16,
10.37,
9.84,
18.00,
14.41,
13.62,
15.00,
3.95,
7.63,
10.75,
7.00,
8.79,
10.31,
7.94,
7.10,
8.67,
12.95,
11.16,
9.89,
9.95,
11.17,
10.16,
6.67,
10.65,
9.78,
8.22,
9.37,
11.94,
13.56,
14.08,
18.00,
14.24,
13.06,
14.88,
16.00,
13.60,
11.74,
7.84,
9.06,
11.59,
12.73,
10.59,
13.06,
13.20,
9.24,
15.64,
12.16,
7.10,
11.14,
11.67,
10.74,
8.00,
13.59,
4.33,
10.33,
5.22,
9.93,
10.11,
11.33,
12.20,
9.81,
13.92,
13.80,
14.07,
3.40,
9.60,
8.47,
9.05,
6.43,
11.89,
11.50,
7.26,
10.95,
5.11,
5.44,
9.40,
14.50,
8.68,
6.11,
15.00,
9.32,
12.60,
13.05,
9.44,
6.00,
5.28,
5.56,
8.50,
12.06,
7.42,
5.00,
8.00,
6.17,
7.61,
5.72,
11.15,
9.37,
5.33,
6.06,
4.58,
6.21,
12.21,
9.78,
6.44,
7.89,
8.07,
11.53,
5.32,
16.08,
10.00,
13.13,
10.19,
12.69,
14.89,
12.44,
14.06,
13.83,
13.79,
13.41,
15.41,
12.83,
16.14,
14.35,
7.21,
11.07,
10.15,
5.40,
11.11,
7.67,
12.53,
13.17,
9.88,
8.50,
9.84,
10.74,
12.89,
4.53,
10.37,
10.33,
11.11,
11.62,
10.83,
14.67,
13.31,
7.53,
13.47,
12.91,
12.94,
13.54,
14.62,
9.16,
10.12,
10.16,
10.35,
9.44,
10.50,
13.12,
12.12,
11.00,
8.90,
9.17,
11.44,
8.39,
16.43,
6.32,
3.58,
13.47,
11.71,
11.45,
12.63,
12.06,
11.12,
11.93,
16.11,
12.18,
10.05,
8.73,
8.53,
11.15,
8.75,
9.56,
8.21,
8.67,
12.23,
12.00,
12.94,
14.50,
12.33,
11.68,
13.30,
12.85,
16.60,
16.83,
13.89,
13.11,
8.47,
13.54,
12.53,
12.75,
4.85,
11.74,
12.45,
7.40,
8.86,
16.00,
13.22,
7.58,
9.82,
8.58,
7.68,
10.61,
14.08,
11.47,
12.72,
13.60,
13.19,
12.22,
11.00,
15.30,
11.33,
5.47,
6.63,
7.63,
13.72,
13.94,
14.00,
13.53,
7.61,
14.33,
14.94,
14.88,
8.05,
7.28,
15.57,
11.33,
11.26,
11.67,
11.70,
10.89,
8.47,
12.53,
7.11,
7.95,
13.47,
14.47,
9.94,
11.19,
8.58,
9.11,
7.39,
9.53,
11.11,
10.11,
12.14,
10.21,
7.56,
10.68,
10.39,
13.45,
12.74,
10.94,
13.18,
12.00,
14.61,
15.36,
8.94,
10.24,
10.89,
12.95,
12.00,
5.17,
10.25,
12.55,
11.14,
7.95,
8.17,
7.84,
6.32,
9.73,
11.78,
7.53,
11.29,
8.68,
15.00,
12.69,
11.57,
11.18,
11.88,
10.53,
7.25,
10.74,
13.38,
12.06,
11.43,
9.58,
13.58,
7.21,
7.26,
9.80,
11.94,
5.22,
12.28,
6.53,
5.00,
11.33,
5.94,
4.20,
11.64,
8.22,
10.67,
4.53,
7.32,
7.78,
9.65,
6.26,
6.55,
6.89,
7.82,
7.42,
10.33,
12.31,
11.74,
8.69,
7.79,
9.50,
9.89,
14.00,
4.17,
5.11,
4.58,
4.20,
8.15,
5.61,
5.16,
11.06,
11.67,
10.70,
4.16,
13.74,
12.00,
8.50,
11.11,
9.63,
6.67,
9.89,
9.11,
4.78,
12.38,
10.32,
8.56,
8.41,
10.63,
9.53,
10.42,
12.17,
12.82,
6.11,
7.17,
5.45,
9.33,
10.00,
9.78,
11.17,
9.62,
18.00,
11.12,
15.89,
17.17,
13.79,
8.10,
13.74,
15.00,
13.00,
14.29,
14.67,
14.41,
7.26,
10.37,
10.74,
9.76,
10.00,
10.83,
11.39,
9.39,
9.42,
13.11,
8.79,
13.47,
8.33,
14.82,
8.68,
11.26,
11.73,
14.88,
16.80,
13.22,
14.14,
14.47,
14.62,
12.74,
11.53,
13.83,
16.19,
13.00,
12.00,
15.80,
15.42,
14.65,
11.72,
15.09,
16.06,
14.76,
14.30,
8.32,
11.53,
12.67,
14.46,
16.00,
14.74,
11.79,
13.32,
10.89,
10.68,
14.67,
17.29,
10.63,
5.42,
7.00,
9.90,
12.11,
14.26,
13.67,
17.23,
9.33,
10.50,
4.39,
12.75,
6.26,
6.63,
7.58,
6.83,
4.79,
7.56,
10.95,
12.26,
13.92,
13.33,
14.50,
14.92,
14.36,
16.18,
17.89,
12.44,
14.85,
6.63,
9.68,
9.30,
11.58,
9.26,
7.72,
11.29,
10.42,
10.16,
11.00,
11.75,
14.85,
9.40,
15.60,
12.28,
9.32,
6.37,
14.38,
18.00,
7.44,
5.00,
9.06,
10.12,
13.78,
3.70,
12.42,
9.19,
12.33,
11.28,
12.85,
7.94,
9.33,
9.83,
8.95,
9.05,
13.71,
9.84,
17.40,
4.37,
9.06,
11.50,
7.35,
8.27,
10.16,
7.90,
10.25,
10.00,
6.32,
11.40,
7.00,
8.25,
9.68,
12.67,
3.68,
8.00,
8.47,
5.74,
10.07,
9.79,
4.95,
12.43,
9.06,
7.28,
10.56,
10.16,
10.53,
16.08,
10.56,
14.86,
15.14,
6.53,
7.58,
11.06,
15.62,
11.26,
13.69,
12.11,
7.05,
13.70,
12.29,
11.65,
9.11,
14.38,
12.16,
13.73,
8.15,
13.10,
11.61,
10.42,
6.32,
10.67,
8.65,
7.17,
12.92,
9.05,
13.40,
14.75,
12.43,
12.89,
13.56,
16.50,
15.21,
16.21,
6.65,
5.84,
4.42,
11.44,
8.34,
10.70,
8.50,
10.95,
11.11,
9.16,
11.23,
7.82,
15.33,
8.90,
16.38,
7.22,
10.58,
7.76,
8.72,
7.32,
7.58,
13.95,
17.62,
13.35,
13.20,
11.39,
14.20,
11.94,
8.58,
10.22,
14.11,
13.82,
11.33,
8.26,
12.31,
9.94,
15.00,
7.89,
11.35,
9.35,
4.26,
9.06,
9.53,
10.79,
7.28,
5.40,
11.67,
10.42,
10.47,
6.26,
5.40,
5.72,
7.79,
11.24,
12.42,
15.83,
8.06,
12.83,
11.47,
11.80,
15.83,
8.84,
14.75,
13.93,
11.94,
8.94,
11.30,
11.42,
7.58,
7.89,
11.71,
12.21,
12.71,
14.79,
10.35,
10.33,
5.47,
6.67,
4.67,
8.95,
4.55,
5.25,
5.39,
10.44,
15.00,
7.37,
13.00,
9.42,
6.00,
6.17,
12.56,
7.80,
6.22,
4.89,
10.38,
6.06,
8.20,
11.37,
11.06,
10.63,
6.17,
5.11,
13.50,
8.95,
9.70,
5.26,
7.45,
11.94,
6.74,
12.90,
16.15,
17.00,
10.50,
13.32,
8.82,
11.90,
13.94,
17.44,
15.54,
11.37,
9.06,
17.08,
11.46,
11.78,
11.74,
13.59,
8.21,
13.33,
10.39,
7.95,
6.94,
8.11,
7.22,
7.84,
7.12,
5.42,
10.68,
5.89,
7.25,
7.81,
8.39,
14.86,
13.57,
12.50,
14.44,
17.32,
15.47,
10.78,
9.50,
10.84,
11.56,
11.94,
14.41,
11.47,
12.20,
14.24,
14.11,
14.67,
11.33,
11.89,
11.39,
4.63,
12.12,
11.93,
12.79,
8.84,
10.06,
13.50,
6.95,
11.32,
11.67,
13.92,
14.43,
11.67,
10.29,
7.95,
4.11,
13.80,
4.26,
9.90,
11.63,
15.86,
11.00,
16.00,
14.10,
11.06,
6.22,
13.64,
10.89,
9.76,
13.74,
11.72,
6.17,
12.53,
15.33,
15.14,
11.44,
8.79,
9.67,
15.84,
8.08,
8.84,
14.18,
13.07,
8.60,
8.35,
11.89,
9.50,
12.20,
14.50,
17.22,
3.58,
16.73,
14.73,
14.06,
13.67,
14.41,
10.33,
13.11,
9.79,
12.57,
14.43,
7.11,
10.88,
9.00,
9.61,
11.32,
9.00,
8.79,
13.19,
9.58,
15.03,
13.50,
12.69,
13.94,
7.53,
7.27,
15.50,
12.56,
9.35,
11.05,
14.23,
9.72,
12.60,
5.16,
8.65,
12.00,
13.90,
5.41,
8.47,
13.33,
15.79,
5.65,
13.44,
13.67,
15.42,
16.00,
15.67,
13.80,
14.00,
11.17,
8.28,
17.25,
9.33,
11.21,
11.50,
11.18,
11.11,
15.04,
7.94,
14.50,
15.62,
9.89,
14.19,
10.95,
12.38,
12.25,
12.21,
5.56,
8.68,
6.05,
8.50,
8.05,
8.33,
13.29,
10.11,
12.71,
14.06,
8.94,
8.79,
12.83,
12.76,
15.47,
9.72,
12.07,
11.00,
14.18,
14.38,
6.26,
9.14,
14.67,
8.84,
11.60,
13.20,
10.00,
12.40,
10.32,
12.78,
12.18,
9.33,
11.00,
16.33,
10.67,
9.39,
14.73,
14.10,
14.42,
7.78,
7.58,
9.75,
13.88,
9.35,
10.42,
16.79,
18.67,
14.92,
5.45,
12.50,
3.79,
5.50,
8.00,
10.82,
3.53,
4.74,
5.53,
10.72,
11.29,
8.11,
3.98,
6.94,
12.65,
10.61,
9.44,
12.71,
10.85,
8.11,
5.26,
13.13,
4.90,
13.76,
8.50,
5.94,
6.74,
11.28,
13.05,
14.52,
12.76,
12.72,
12.47,
13.05,
13.44,
11.84,
13.50,
9.37,
16.00,
16.00,
12.39,
10.89,
12.00,
5.25,
9.94,
13.00,
9.94,
12.79,
11.05,
17.50,
15.00,
13.62,
14.95,
15.71,
6.63,
14.71,
15.57,
8.29,
13.62,
8.89,
13.27,
9.26,
6.42,
12.83,
13.44,
15.27,
17.50,
4.88,
13.85,
5.61,
8.62,
9.21,
7.89,
10.56,
6.42,
7.22,
9.29,
9.94,
9.28,
6.42,
9.00,
8.05,
9.62,
6.47,
4.58,
5.72,
7.24,
5.76,
4.75,
7.61,
7.78,
4.58,
6.06,
3.89,
5.94,
9.83,
4.93,
10.59,
14.89,
12.41,
15.33,
11.31,
16.57,
5.97,
7.56,
8.89,
11.26,
13.37,
10.15,
10.11,
15.50,
5.01,
7.32,
5.89,
9.00,
9.32,
9.56,
9.95,
6.60,
12.21,
13.12,
8.72,
10.82,
7.06,
11.77,
11.50,
7.14,
8.95,
3.69,
11.38,
13.19,
12.58,
14.00,
12.54,
12.15,
11.90,
12.29,
10.47,
11.90,
10.70,
8.37,
8.25,
8.47,
9.35,
9.95,
13.22,
5.09,
9.74,
10.05,
9.42,
14.00,
20.17,
17.75,
18.29,
15.23,
7.28,
12.93,
10.61,
5.82,
15.85,
12.75,
4.14,
6.38,
13.65,
8.65,
13.25,
15.75,
9.72,
10.88,
11.50,
8.06,
10.56,
12.95,
0.00,
4.42,
8.86,
8.56,
6.79,
12.12,
7.17,
7.63,
7.17,
4.89,
6.65,
9.44,
7.11,
11.60,
10.00,
8.28,
7.00,
12.17,
9.20,
12.25,
12.61,
12.42,
15.00,
15.40,
13.83,
6.77,
12.12,
8.06,
6.05,
9.94,
5.32,
4.35,
6.61,
4.67,
4.53,
11.75,
9.16,
9.59,
6.68,
10.94,
10.00,
9.74,
8.53,
4.74,
8.47,
12.87,
12.22,
14.62,
11.28,
17.50,
4.00,
11.62,
5.25,
6.95,
12.53,
12.47,
8.11,
7.67,
7.22,
10.21,
9.05,
6.80,
10.63,
6.90,
11.28,
8.83,
10.27,
6.47,
10.08,
16.00,
5.16,
13.52,
10.21,
10.44,
9.83,
10.84,
10.67,
5.50,
9.33,
6.28,
6.70,
9.86,
14.33,
13.79,
10.58,
9.85,
10.83,
8.84,
10.64,
13.39,
6.79,
15.08,
11.64,
12.20,
9.60,
7.84,
10.00,
10.28,
9.00,
11.50,
7.61,
10.87,
5.67,
8.47,
12.50,
14.86,
10.29,
10.88,
6.61,
11.43,
10.00,
9.60,
15.00,
17.30,
5.33,
5.42,
11.00,
10.17,
11.83,
14.33,
10.80,
14.50,
13.79,
12.67,
5.65,
8.05,
12.58,
6.16,
4.62,
7.22,
11.05,
10.12,
8.42,
7.55,
4.76,
10.32,
11.22,
13.58,
11.50,
11.89,
14.31,
14.36,
6.32,
13.29,
14.67,
12.75,
12.20,
14.33,
11.63,
3.95,
6.11,
9.12,
10.38,
4.67,
6.72,
7.94,
7.39,
14.62,
10.12,
15.73,
6.50,
14.83,
4.77,
11.53,
6.11,
15.33,
3.00,
8.38,
11.92,
8.56,
5.10,
6.22,
11.89,
12.88,
11.12,
5.94,
7.17,
12.56,
11.39,
12.50,
16.07,
3.54,
5.09,
10.16,
8.88,
14.33,
13.50,
13.30,
7.58,
10.00,
8.63,
10.78,
10.45,
8.37,
9.16,
10.56,
12.47,
15.50,
8.29,
4.61,
11.05,
6.35,
7.95,
8.24,
13.20,
10.12,
11.29,
13.36,
14.11,
5.19,
12.50,
7.42,
12.92,
13.47,
7.67,
11.94,
14.16,
9.62,
7.78,
4.47,
12.63,
4.73,
12.07,
9.50,
11.79,
9.56,
14.80,
13.00,
14.71,
5.10,
5.37,
6.67,
7.83,
5.53,
11.08,
3.61,
6.53,
4.33,
6.47,
3.33,
6.83,
9.14,
9.06,
5.67,
3.89,
11.19,
9.87,
11.64,
11.33,
11.67,
14.38,
7.84,
11.95,
13.00,
10.11,
9.94,
9.71,
13.06,
14.11,
13.41,
7.37,
10.86,
9.63,
9.61,
11.20,
13.00,
16.50,
7.84,
10.53,
8.53,
12.33,
8.61,
10.44,
6.21,
8.35,
12.77,
12.67,
16.25,
13.29,
13.35,
14.00,
12.94,
14.60,
9.33,
16.54,
13.65,
12.32,
8.28,
5.88,
10.18,
12.61,
10.70,
10.83,
12.11,
14.27,
4.11,
11.83,
11.32,
12.81,
7.00,
6.83,
14.18,
15.12,
12.00,
10.74,
8.19,
12.44,
8.67,
7.07,
8.69,
5.16,
7.89,
14.80,
11.21,
14.50,
9.15,
9.95,
5.47,
8.42,
9.11,
8.67,
9.63,
7.17,
9.53,
8.76,
11.59,
7.83,
15.18,
11.25,
12.72,
12.53,
8.63,
6.28,
6.28,
3.22,
6.33,
9.67,
12.00,
11.33,
11.60,
5.11,
12.61,
11.67,
9.11,
11.50,
9.32,
7.94,
9.79,
15.11,
16.71,
9.06,
13.74,
15.53,
14.82,
14.58,
9.33,
3.00,
5.94,
6.84,
10.26,
10.12,
11.62,
12.05,
15.04,
14.62,
18.00,
6.94,
9.61,
11.06,
11.24,
10.50,
13.67,
11.88,
11.05,
5.50,
6.42,
12.56,
10.37,
9.95,
8.79,
11.44,
6.42,
8.05,
10.00,
12.63,
12.50,
6.22,
13.50,
14.61,
15.56,
10.21,
8.37,
14.39,
5.61,
10.68,
10.67,
6.37,
4.00,
8.34,
11.79,
10.00,
8.50,
9.11,
6.92,
12.43,
10.65,
9.44,
10.94,
11.94,
10.53,
14.71,
8.42,
9.21,
6.79,
12.06,
9.95,
13.05,
13.42,
11.68,
11.32,
11.58,
11.74,
11.75,
11.20,
15.25,
11.59,
14.07,
13.50,
13.95,
14.79,
13.33,
13.65,
13.93,
16.85,
12.00,
13.21,
12.41,
13.53,
12.47,
14.13,
11.67,
15.36,
10.33,
10.06,
14.29,
12.61,
13.07,
8.17,
11.21,
14.06,
14.50,
10.11,
12.72,
12.00,
14.68,
16.50,
14.00,
15.33,
12.17,
12.61,
14.47,
14.30,
12.67,
11.20,
10.39,
12.21,
12.00,
14.87,
11.20,
10.00,
9.74,
9.06,
14.18,
11.95,
13.93,
11.90,
9.78,
12.47,
11.89,
15.71,
15.81,
14.15,
15.11,
12.79,
13.43,
16.25,
13.00,
11.00,
10.67,
13.06,
11.82,
10.35,
11.50,
15.07,
10.38,
10.37,
8.11,
10.72,
14.10,
14.00,
13.67,
14.55,
16.38,
14.56,
14.76,
13.94,
6.44,
9.16,
9.28,
16.00,
12.11,
13.74,
9.11,
13.64,
4.47,
4.50,
7.89,
9.72,
10.53,
13.40,
11.74,
14.91,
5.90,
6.89,
15.36,
14.67,
11.75,
12.11,
17.62,
13.64,
13.09,
6.05,
12.26,
12.40,
16.62,
8.75,
11.15,
11.37,
12.71,
13.05,
6.06,
8.47,
9.89,
11.25,
4.61,
9.68,
13.33,
8.11,
9.84,
9.94,
15.45,
3.57,
12.43,
9.18,
8.59,
5.65,
9.62,
11.67,
11.17,
8.89,
13.00,
10.63,
13.00,
10.68,
15.38,
10.21,
15.60,
10.84,
12.11,
12.70,
12.21,
10.83,
17.57,
16.25,
14.33,
14.88,
9.16,
7.89,
11.33,
10.75,
13.80,
12.19,
14.38,
9.16,
4.90,
7.80,
12.88,
14.81,
13.06,
8.63,
8.17,
10.11,
10.45,
14.95,
13.59,
12.39,
12.67,
10.44,
12.50,
11.33,
5.33,
18.86,
6.05,
9.58,
8.22,
9.00,
5.53,
12.50,
15.50,
4.05,
11.59,
12.17,
12.05,
12.94,
13.94,
8.33,
13.62,
15.83,
13.26,
16.00,
13.22,
9.43,
8.94,
16.92,
10.70,
6.95,
12.12,
14.11,
9.00,
11.60,
9.39,
13.87,
8.39,
14.57,
4.22,
12.62,
6.78,
8.11,
13.33,
14.38,
9.26,
12.70,
13.31,
11.67,
17.14,
13.00,
16.00,
10.16,
12.17,
8.89,
16.46,
16.57,
12.85,
8.37,
11.78,
13.13,
13.00,
14.60,
18.00,
5.72,
8.47,
14.83,
7.83,
11.67,
8.35,
11.32,
7.84,
11.56,
5.55,
10.37,
9.05,
10.47,
7.30,
14.25,
13.17,
4.56,
7.21,
8.22,
13.00,
8.06,
12.85,
8.41,
11.47,
13.25,
9.54,
7.22,
7.89,
15.11,
8.56,
14.45,
12.67,
12.18,
13.67,
12.00,
8.80,
3.79,
7.21,
14.00,
7.95,
14.36,
12.69,
4.64,
10.82,
13.62,
13.50,
11.23,
19.00,
6.28,
15.11,
11.33,
8.30,
13.40,
6.55,
8.06,
12.79,
16.29,
9.00,
10.05,
8.05,
6.24,
4.42,
6.28,
7.50,
3.44,
9.05,
16.67,
17.50,
12.14,
11.00,
13.00,
3.95,
7.00,
10.12,
5.50,
10.47,
12.60,
13.89,
14.12,
12.11,
8.25,
10.79,
11.53,
5.79,
9.87,
13.35,
5.17,
6.17,
10.84,
7.15,
12.40,
7.89,
10.45,
7.95,
12.40,
18.00,
13.30,
15.67,
3.72,
10.32,
12.39,
14.19,
16.20,
5.73,
12.06,
7.32,
13.00,
10.60,
8.74,
11.60,
14.83,
11.42,
5.89,
12.50,
10.24,
15.83,
11.06,
12.16,
13.24,
10.63,
16.22,
13.73,
11.47,
12.79,
8.37,
14.10,
10.95,
16.00,
3.95,
15.00,
12.84,
4.11,
13.89,
10.58,
12.00,
10.41,
9.57,
7.00,
14.29,
11.14,
10.95,
10.35,
11.06,
13.67,
10.39,
10.00,
13.43,
7.68,
12.00,
12.90,
4.17,
9.92,
8.78,
10.13,
6.79,
8.29,
7.67,
14.71,
10.82,
10.83,
10.50,
14.22,
14.00,
8.65,
9.95,
9.28,
7.00,
9.78,
8.26,
3.47,
11.00,
11.82,
11.89,
15.44,
11.06,
14.67,
12.11,
13.05,
16.56,
9.06,
9.89,
7.16,
7.11,
4.48,
10.70,
8.00,
5.89,
9.62,
6.28,
10.00,
12.75,
10.83,
6.06,
9.38,
11.82,
4.48,
6.76,
3.72,
7.83,
6.46,
7.53,
6.53,
12.00,
8.84,
9.44,
18.14,
4.24,
15.88,
11.79,
9.74,
8.73,
13.33,
6.58,
13.50,
13.28,
11.13,
12.36,
13.41,
11.50,
6.74,
8.50,
13.64,
9.78,
13.11,
8.62,
15.19,
8.56,
10.68,
11.65,
13.00,
6.42,
7.35,
13.00,
12.12,
11.25,
15.67,
16.43,
17.36,
7.29,
6.33,
4.17,
10.76,
12.68,
15.83,
15.50,
14.81,
14.46,
13.92,
14.20,
12.50,
10.37,
10.38,
11.47,
9.27,
13.45,
11.29,
13.62,
15.60,
14.74,
12.15,
10.67,
14.17,
11.61,
15.10,
13.90,
5.68,
15.64,
9.60,
7.56,
14.25,
13.94,
7.78,
14.06,
9.84,
9.94,
12.42,
11.42,
11.17,
11.37,
10.21,
11.00,
11.94,
13.33,
11.21,
12.74,
14.95,
12.00,
13.47,
10.95,
13.72,
11.16,
9.06,
10.59,
12.71,
9.58,
16.50,
11.60,
10.44,
15.11,
10.94,
12.23,
10.50,
8.90,
9.21,
7.94,
12.28,
10.32,
10.44,
14.06,
10.67,
9.39,
9.16,
11.79,
10.00,
15.00,
10.00,
9.79,
10.18,
14.62,
10.39,
8.89,
11.00,
10.55,
11.11,
9.80,
8.21,
13.07,
10.28,
9.72,
9.55,
13.39,
8.32,
9.92,
9.11,
8.11,
10.63,
13.31,
10.20,
7.56,
9.89,
9.06,
11.82,
13.84,
9.75,
8.30,
8.89,
10.94,
8.22,
10.94,
13.56,
13.50,
12.36,
11.59,
11.94,
12.10,
10.47,
9.28,
10.16,
9.05,
9.61,
9.82,
10.21,
10.53,
12.12,
9.11,
8.11,
11.88,
10.93,
7.94,
6.06,
5.63,
12.95,
8.50,
8.28,
9.65,
5.11,
5.17,
10.33,
8.59,
12.50,
7.50,
8.21,
11.06,
7.74,
9.44,
10.53,
12.33,
11.76,
14.74,
8.37,
12.25,
7.50,
8.37,
10.50,
10.79,
10.68,
8.47,
9.21,
11.16,
10.44,
10.95,
12.65,
13.80,
8.67,
10.65,
10.11,
13.05,
12.32,
10.78,
9.67,
10.00,
11.80,
4.47,
6.83,
7.68,
8.21,
10.94,
10.05,
9.74,
11.33,
8.30,
10.20,
9.39,
9.89,
9.89,
9.58,
6.56,
7.17,
12.22,
8.95,
12.16,
12.83,
8.79,
11.65,
12.48,
13.83,
12.61,
11.42,
12.59,
9.41,
9.56,
10.44,
13.37,
13.33,
10.95,
9.12,
9.39,
12.74,
9.72,
13.61,
9.45,
9.47,
12.13,
13.89,
12.05,
13.25,
14.75,
11.53,
11.83,
14.33,
11.11,
10.06,
9.74,
13.24,
11.68,
13.22,
10.85,
10.74,
8.00,
8.79,
9.35,
10.79,
8.00,
13.53,
11.00,
10.74,
9.22,
13.33,
12.53,
11.43,
7.17,
9.89,
11.27,
8.00,
12.05,
7.56,
9.94,
11.26,
11.41,
7.75,
14.75,
15.67,
12.30,
8.78,
8.33,
8.53,
9.33,
9.52,
13.33,
9.55,
11.85,
7.83,
12.89,
9.16,
10.00,
9.00,
11.41,
16.21,
11.22,
10.95,
10.25,
12.53,
12.76,
13.94,
10.89,
7.83,
5.89,
10.50,
9.95,
11.05,
10.28,
7.29,
11.00,
13.50,
10.11,
15.30,
11.28,
12.17,
11.38,
12.67,
7.67,
7.00,
12.84,
9.61,
9.68,
13.89,
11.32,
12.39,
10.88,
11.39,
10.44,
9.95,
10.14,
9.60,
12.00,
12.61,
11.53,
12.00,
10.21,
13.60,
10.89,
13.68,
12.37,
7.37,
11.33,
11.00,
16.25,
12.35,
10.79,
6.67,
12.30,
10.08,
12.10,
13.69,
12.47,
11.19,
11.61,
12.67,
3.35,
9.16,
11.32,
14.00,
10.82,
11.18,
10.11,
13.21,
11.78,
12.21,
6.25,
15.00,
13.94,
14.60,
14.56,
5.56,
13.06,
6.22,
11.33,
10.00,
12.89,
8.42,
9.11,
13.57,
12.68,
6.17,
8.35,
7.83,
12.26,
10.45,
12.12,
11.48,
10.32,
10.33,
11.61,
12.88,
12.77,
3.94,
7.74,
12.42,
9.45,
12.24,
15.16,
14.69,
9.95,
11.11,
10.58,
10.72,
11.00,
9.89,
11.72,
10.88,
10.16,
14.62,
12.47,
10.67,
13.47,
10.84,
11.68,
10.73,
14.35,
11.11,
10.51,
11.37,
8.47,
10.56,
11.33,
10.53,
13.00,
12.11,
10.22,
9.37,
11.53,
9.28,
7.78,
10.76,
9.74,
13.00,
8.08,
14.67,
10.68,
12.75,
9.62,
5.28,
3.95,
7.39,
10.89,
14.77,
15.14,
15.38,
10.44,
10.25,
11.11,
11.50,
8.42,
9.37,
9.16,
8.47,
7.76,
10.68,
16.93,
9.95,
14.47,
10.53,
11.72,
9.83,
12.22,
11.33,
10.95,
11.58,
14.47,
9.00,
12.78,
11.93,
12.39,
13.78,
12.72,
11.61,
13.47,
9.26,
11.21,
12.28,
14.88,
13.60,
12.58,
10.21,
14.30,
7.33,
10.42,
11.47,
13.15,
10.07,
10.60,
8.53,
9.06,
10.17,
10.06,
9.06,
8.11,
10.11,
14.24,
8.42,
12.32,
11.00,
12.11,
11.59,
5.61,
7.42,
10.38,
11.44,
9.00,
11.85,
9.63,
7.89,
7.95,
14.95,
12.59,
10.71,
14.76,
8.84,
9.94,
11.00,
11.63,
12.22,
13.93,
10.28,
7.74,
10.13,
10.62,
12.47,
10.43,
13.53,
13.33,
10.44,
11.33,
10.31,
8.00,
9.12,
11.63,
12.11,
8.94,
9.58,
9.53,
9.89,
9.11,
7.93,
12.26,
7.24,
9.00,
6.39,
7.00,
12.45,
8.18,
6.42,
11.39,
10.63,
8.75,
12.15,
10.58,
10.43,
12.00,
10.72,
9.82,
7.29,
9.35,
8.70,
11.79,
11.53,
8.67,
11.11,
10.74,
11.82,
9.15,
10.14,
10.58,
13.12,
10.83,
13.21,
11.63,
9.76,
6.84,
4.89,
9.21,
12.55,
10.32,
6.78,
8.47,
8.50,
12.28,
8.16,
12.18,
11.35,
12.35,
9.30,
10.58,
9.42,
7.22,
9.67,
7.22,
11.56,
12.36,
4.83,
7.26,
8.18,
10.58,
9.95,
13.77,
14.33,
11.44,
5.60,
7.94,
11.78,
10.39,
11.74,
14.12,
10.47,
10.32,
11.42,
12.68,
14.07,
9.94,
7.84,
9.50,
9.68,
9.84,
15.36,
10.11,
12.35,
11.89,
9.28,
13.16,
11.56,
8.84,
11.00,
11.00,
11.26,
13.32,
10.72,
14.10,
11.16,
9.26,
9.06,
10.10,
9.47,
9.00,
9.81,
10.95,
9.39,
15.00,
13.88,
15.63,
8.63,
10.68,
11.40,
11.12,
7.79,
14.00,
7.89,
9.48,
10.21,
13.62,
9.26,
10.72,
11.33,
11.44,
13.00,
7.21,
7.68,
9.80,
8.50,
8.11,
8.17,
10.37,
13.82,
8.53,
11.32,
11.65,
10.62,
8.37,
12.92,
7.22,
11.33,
8.74,
8.60,
8.00,
10.95,
8.79,
13.18,
9.11,
12.28,
11.21,
7.61,
9.70,
11.20,
7.86,
7.06,
13.07,
10.29,
11.64,
8.81,
8.68,
7.28,
10.06,
8.95,
7.05,
9.05,
11.28,
13.40,
11.06,
11.56,
10.72,
11.38,
9.94,
9.65,
10.83,
8.00,
12.32,
9.00,
9.32,
9.78,
11.58,
11.17,
13.15,
11.17,
10.11,
12.40,
11.65,
10.94,
10.21,
15.81,
7.89,
13.20,
13.47,
11.94,
13.33,
10.35,
8.06,
9.10,
11.06,
8.71,
9.44,
9.50,
10.63,
11.84,
10.42,
9.63,
9.18,
10.63,
10.67,
13.95,
13.81,
12.11,
8.80,
11.22,
7.06,
11.35,
10.11,
10.95,
11.47,
11.63,
12.19,
7.53,
8.85,
7.68,
13.69,
12.40,
13.53,
11.16,
11.06,
8.68,
6.53,
13.74,
12.76,
9.29,
8.44,
8.22,
9.32,
9.00,
9.30,
7.35,
5.42,
9.00,
9.21,
8.20,
10.56,
9.63,
11.59,
11.56,
13.16,
12.14,
7.83,
11.22,
13.62,
8.00,
11.16,
14.40,
11.00,
9.84,
14.05,
12.89,
12.00,
10.50,
11.47,
10.50,
10.83,
11.05,
13.06,
12.24,
10.48,
11.89,
9.26,
10.06,
12.28,
12.05,
12.76,
10.24,
8.05,
12.17,
11.21,
11.11,
10.61,
10.44,
8.57,
12.62,
10.56,
8.67,
10.16,
7.88,
10.16,
8.10,
8.76,
7.37,
9.58,
11.20,
10.70,
11.88,
9.17,
11.94,
10.83,
12.44,
11.67,
9.00,
15.59,
11.88,
10.75,
9.88,
12.28,
13.58,
10.00,
11.47,
9.70,
10.39,
11.76,
11.26,
11.24,
9.65,
10.74,
12.71,
10.28,
8.89,
11.45,
10.26,
11.06,
12.00,
9.33,
15.53,
11.30,
9.00,
9.00,
11.75,
10.89,
10.89,
10.50,
15.12,
10.05,
10.95,
10.50,
12.83,
11.62,
9.00,
10.79,
11.58,
9.72,
10.72,
12.85,
9.89,
12.67,
7.50,
12.85,
6.57,
11.44,
9.17,
9.68,
10.00,
4.74,
7.26,
13.72,
13.74,
14.11,
11.00,
9.00,
8.37,
14.22,
8.89,
13.63,
12.36,
11.47,
8.68,
9.89,
9.88,
11.75,
10.11,
11.17,
11.05,
6.76,
12.26,
14.19,
8.89,
11.30,
11.06,
12.44,
10.33,
11.44,
10.21,
12.29,
9.56,
12.00,
9.00,
9.94,
12.33,
9.12,
10.00,
9.74,
11.71,
12.62,
11.28,
9.95,
11.56,
9.79,
9.74,
10.00,
12.19,
9.75,
11.19,
12.67,
13.33,
10.79,
9.67,
9.61,
10.00,
9.78,
7.53,
8.83,
11.57,
11.62,
9.47,
8.68,
13.68,
10.39,
9.17,
12.56,
11.58,
11.71,
11.67,
12.94,
10.44,
12.11,
11.32,
10.35,
9.26,
9.30,
12.06,
12.72,
8.00,
7.89,
10.42,
8.76,
10.72,
11.16,
7.70,
11.95,
9.00,
8.53,
8.78,
9.00,
9.63,
12.95,
7.28,
11.06,
13.22,
12.53,
9.42,
11.94,
11.16,
13.47,
11.42,
12.61,
9.39,
12.45,
9.61,
8.32,
12.84,
12.11,
9.72,
13.72,
8.21,
11.80,
10.56,
10.94,
9.06,
13.19,
9.84,
6.84,
7.37,
12.67,
12.59,
12.50,
12.68,
10.42,
15.45,
11.38,
10.11,
6.28,
10.61,
10.11,
9.44,
11.05,
9.83,
12.11,
7.61,
8.72,
4.84,
5.89,
9.50,
10.00,
12.18,
9.84,
8.78,
9.39,
7.67,
12.93,
11.58,
10.40,
10.20,
11.26,
9.00,
12.00,
11.65,
11.17,
9.89,
11.82,
9.79,
6.53,
10.26,
11.11,
7.58,
6.74,
8.96,
9.89,
11.17,
11.47,
7.28,
8.21,
7.89,
8.56,
13.29,
12.64,
10.17,
13.53,
11.82,
11.00,
13.88,
12.11,
13.74,
11.11,
12.94,
6.39,
9.78,
15.00,
12.80,
12.65,
7.53,
10.22,
8.67,
12.68,
12.19,
11.11,
11.09,
12.11,
8.65,
8.84,
10.89,
7.50,
11.68,
13.71,
12.42,
8.61,
9.47,
8.42,
11.35,
8.94,
7.42,
8.89,
12.38,
10.00,
11.37,
7.35,
11.22,
10.84,
4.80,
6.65,
8.50,
10.68,
13.47,
6.06,
2.92,
11.61,
9.26,
9.25,
12.00,
9.00,
10.83,
11.19,
9.17,
12.76,
10.58,
12.65,
11.17,
11.27,
10.00,
10.22,
13.92,
13.33,
6.18,
7.56,
12.88,
12.05,
9.85,
9.83,
8.00,
12.05,
11.17,
9.55,
11.95,
10.05,
10.25,
11.00,
5.26,
13.47,
9.26,
15.71,
12.06,
10.58,
3.86,
10.94,
11.00,
9.71,
13.87,
12.88,
11.95,
15.14,
9.85,
9.00,
6.11,
7.44,
11.17,
11.78,
10.63,
9.84,
12.35,
15.94,
13.75,
10.88,
14.17,
16.86,
13.00,
15.11,
14.44,
12.68,
14.72,
9.11,
9.81,
8.68,
14.42,
8.47,
14.68,
12.56,
7.63,
9.61,
9.83,
9.89,
15.62,
18.27,
14.70,
16.24,
14.17,
18.67,
4.19,
7.68,
10.74,
4.50,
9.29,
6.39,
10.30,
7.05,
8.39,
9.57,
9.35,
13.10,
8.89,
13.40,
18.22,
14.81,
13.60,
13.94,
16.88,
7.67,
13.80,
12.84,
14.60,
15.50,
11.39,
11.83,
10.63,
10.53,
13.26,
14.12,
9.00,
13.47,
12.67,
11.73,
17.50,
8.05,
9.55,
10.33,
5.22,
8.94,
9.00,
10.72,
9.17,
8.16,
14.64,
15.27,
16.00,
15.62,
6.74,
5.68,
12.35,
14.75,
8.89,
11.94,
13.28,
13.21,
9.84,
14.24,
10.33,
10.74,
14.09,
15.71,
13.84,
11.69,
15.00,
13.05,
13.60,
13.78,
16.00,
13.67,
16.50,
11.17,
8.94,
12.90,
12.06,
13.50,
13.56,
13.67,
7.94,
13.44,
14.88,
12.83,
8.56,
7.80,
13.33,
12.71,
6.78,
11.00,
10.78,
17.33,
12.00,
15.06,
7.16,
10.89,
5.20,
15.40,
10.72,
12.00,
12.00,
10.47,
10.58,
13.07,
4.80,
8.58,
8.32,
11.39,
10.29,
10.69,
12.59,
12.29,
13.63,
12.10,
14.67,
15.00,
8.40,
11.78,
10.75,
10.67,
13.31,
13.25,
11.33,
12.58,
10.47,
14.00,
11.68,
10.11,
13.54,
12.17,
12.50,
14.64,
14.21,
7.89,
9.58,
13.62,
11.19,
10.26,
11.63,
8.94,
10.06,
12.71,
15.11,
7.89,
14.00,
17.45,
16.30,
17.58,
17.14,
17.40,
12.94,
9.63,
11.50,
15.00,
9.84,
17.00,
9.16,
13.36,
11.17,
11.12,
11.47,
14.16,
14.06,
4.17,
13.40,
9.11,
11.58,
10.58,
12.58,
6.63,
14.00,
6.58,
11.00,
9.69,
11.47,
8.53,
19.50,
11.48,
15.58,
14.75,
12.62,
12.93,
13.33,
16.42,
7.61,
8.87,
8.00,
14.40,
14.11,
14.45,
14.00,
10.29,
15.57,
13.24,
11.26,
6.98,
10.17,
11.59,
14.76,
17.00,
16.08,
15.33,
13.06,
10.63,
11.67,
11.65,
7.95,
8.63,
14.45,
10.11,
12.84,
11.84,
10.39,
11.44,
13.86,
13.50,
14.59,
11.53,
8.47,
11.21,
13.38,
12.16,
8.56,
15.14,
11.89,
8.00,
8.42,
10.75,
11.16,
15.20,
12.67,
14.50,
13.00,
11.05,
15.80,
11.41,
13.26,
11.28,
10.28,
16.00,
13.27,
15.13,
14.77,
19.75,
11.85,
10.84,
17.06,
14.27,
11.50,
12.71,
12.25,
13.61,
8.17,
12.29,
8.11,
8.50,
10.74,
12.39,
10.55,
13.06,
9.71,
13.55,
10.00,
4.90,
14.27,
13.31,
13.29,
9.94,
5.83,
15.36,
11.41,
13.27,
16.08,
14.15,
13.75,
15.88,
7.78,
9.90,
8.94,
7.84,
11.39,
14.40,
14.80,
14.09,
14.30,
14.11,
13.33,
10.06,
18.57,
11.00,
9.95,
11.08,
13.42,
10.16,
9.56,
12.50,
13.39,
14.42,
12.53,
13.62,
14.47,
12.50,
17.50,
16.20,
13.43,
10.28,
9.53,
12.26,
13.83,
9.39,
13.44,
12.00,
13.38,
12.84,
10.05,
7.53,
11.30,
20.00,
8.06,
7.11,
13.06,
9.37,
12.75,
5.63,
9.17,
12.78,
9.83,
7.28,
10.95,
12.93,
9.44,
11.16,
13.21,
12.44,
14.00,
10.63,
10.14,
11.74,
10.94,
15.53,
10.50,
7.84,
8.26,
8.28,
11.84,
11.53,
9.82,
18.00,
15.00,
12.54,
12.17,
11.78,
13.59,
13.53,
14.17,
14.22,
6.95,
7.11,
10.47,
11.05,
14.33,
12.00,
10.68,
15.44,
14.07,
10.53,
10.94,
10.92,
6.39,
7.61,
7.49,
7.45,
9.17,
12.60,
9.84,
15.17,
14.11,
14.60,
10.63,
12.17,
11.53,
16.55,
14.45,
16.06,
16.12,
15.86,
10.39,
11.79,
11.53,
13.50,
12.94,
12.10,
16.09,
9.50,
12.17,
13.00,
14.64,
15.45,
13.21,
13.47,
15.92,
13.28,
13.21,
10.84,
8.05,
6.58,
12.17,
5.79,
12.11,
10.47,
6.37,
7.83,
12.70,
9.11,
10.86,
9.88,
12.72,
14.90,
10.28,
11.47,
12.94,
11.72,
5.42,
6.22,
16.27,
16.50,
14.18,
18.00,
15.29,
14.80,
14.82,
13.83,
11.56,
14.75,
13.44,
13.37,
11.44,
11.83,
11.45,
15.67,
16.75,
16.00,
11.00,
16.00,
15.00,
6.74,
8.00,
14.22,
10.63,
11.00,
9.37,
12.61,
13.00,
14.27,
15.46,
11.11,
11.60,
14.75,
4.83,
11.06,
11.78,
14.22,
10.56,
14.38,
13.00,
15.31,
13.67,
8.33,
11.12,
6.74,
12.00,
13.31,
10.24,
7.05,
11.05,
11.78,
15.93,
13.31,
15.00,
14.12,
6.35,
9.58,
14.11,
9.17,
6.89,
10.83,
16.18,
12.71,
5.68,
10.53,
12.17,
13.20,
12.94,
7.79,
7.89,
11.67,
11.44,
10.30,
13.55,
9.32,
6.53,
7.76,
14.56,
14.38,
14.41,
14.75,
14.93,
14.27,
11.57,
13.62,
12.93,
11.94,
9.95,
12.32,
8.89,
13.11,
7.88,
8.95,
7.67,
8.78,
11.87,
7.79,
8.56,
10.62,
6.89,
5.32,
11.62,
4.53,
11.06,
10.06,
9.57,
15.79,
10.00,
5.22,
8.78,
12.77,
9.41,
10.81,
13.92,
13.67,
15.25,
14.50,
6.42,
9.50,
11.88,
8.68,
4.30,
8.28,
8.47,
7.22,
12.63,
11.17,
5.63,
9.47,
11.29,
8.20,
5.06,
3.45,
12.83,
7.73,
10.35,
11.00,
10.24,
7.95,
3.79,
12.36,
9.29,
5.89,
12.94,
12.73,
11.60,
10.06,
10.06,
9.26,
6.55,
6.16,
5.06,
10.37,
11.91,
6.39,
6.89,
9.56,
13.08,
13.53,
13.00,
11.29,
4.16,
13.69,
12.67,
7.67,
10.91,
11.62,
10.72,
11.00,
10.27,
7.84,
13.33,
10.74,
8.58,
10.41,
8.95,
10.50,
13.22,
11.88,
12.43,
8.17,
9.68,
12.00,
4.65,
9.44,
13.22,
17.09,
6.26,
5.74,
6.11,
8.89,
10.12,
9.40,
8.22,
11.37,
12.58,
13.63,
11.17,
10.44,
8.25,
10.44,
7.25,
7.82,
8.22,
9.33,
10.11,
13.95,
4.00,
7.67,
8.50,
8.65,
7.32,
4.17,
9.50,
6.32,
8.39,
10.88,
4.80,
10.73,
6.35,
6.00,
10.71,
7.10,
10.50,
9.17,
5.58,
14.14,
14.00,
11.58,
6.50,
5.68,
7.11,
9.42,
12.37,
8.33,
7.58,
11.53,
4.33,
9.05,
7.24,
9.39,
6.32,
8.16,
9.74,
8.41,
10.50,
9.00,
12.50,
2.37,
7.17,
10.20,
7.68,
10.83,
13.12,
5.47,
11.67,
8.22,
7.56,
7.22,
10.40,
11.53,
12.30,
14.39,
4.22,
8.68,
10.75,
11.71,
7.63,
12.85,
9.39,
9.89,
7.95,
6.65,
12.34,
9.31,
11.36,
4.26,
14.43,
12.03,
11.26,
5.89,
6.00,
8.61,
10.62,
15.17,
11.89,
8.05,
5.39,
14.33,
15.69,
13.88,
13.00,
11.41,
10.53,
5.04,
5.58,
7.95,
9.58,
8.44,
14.80,
14.90,
8.76,
7.89,
9.72,
6.95,
10.65,
11.30,
4.63,
9.72,
7.22,
11.78,
9.56,
7.25,
5.94,
11.67,
7.11,
10.53,
12.33,
11.33,
9.89,
5.37,
8.61,
10.39,
10.08,
15.57,
16.88,
15.71,
16.17,
12.00,
12.66,
6.95,
9.76,
6.00,
9.42,
7.94,
10.89,
6.39,
6.79,
5.85,
10.78,
5.11,
6.78,
4.79,
11.94,
7.79,
7.45,
6.53,
6.10,
9.31,
7.92,
8.50,
10.63,
6.68,
8.16,
16.25,
6.62,
6.53,
9.32,
8.63,
9.41,
8.33,
9.53,
10.61,
5.61,
8.61,
8.22,
9.46,
4.06,
11.61,
9.37,
10.16,
11.11,
18.50,
6.16,
11.63,
11.13,
16.50,
8.21,
10.05,
13.59,
12.93,
9.22,
12.36,
11.36,
10.72,
12.82,
7.20,
14.88,
5.89,
10.32,
11.60,
6.67,
9.17,
13.84,
9.56,
2.47,
13.44,
10.80,
7.60,
8.79,
6.16,
11.11,
5.47,
9.47,
9.19,
11.50,
10.26,
7.44,
9.11,
8.81,
10.88,
3.86,
6.14,
9.61,
6.53,
11.67,
12.67,
10.83,
4.40,
6.78,
14.07,
6.89,
12.57,
6.32,
12.79,
11.58,
8.61,
10.00,
12.86,
10.41,
4.24,
14.12,
6.37,
4.09,
9.40,
10.74,
10.85,
12.59,
7.01,
12.08,
12.33,
12.38,
7.11,
12.80,
10.22,
6.58,
8.34,
11.15,
9.67,
13.83,
10.81,
7.06,
11.31,
10.74,
8.24,
11.11,
12.69,
10.26,
5.53,
7.25,
7.58,
7.35,
7.18,
7.00,
11.68,
9.53,
13.07,
12.27,
10.38,
7.37,
11.69,
8.95,
10.50,
8.50,
12.36,
8.50,
8.72,
11.15,
5.05,
8.46,
5.11,
9.38,
4.26,
8.11,
10.71,
15.33,
5.42,
6.32,
10.67,
4.06,
11.56,
10.44,
7.11,
7.94,
9.00,
10.06,
10.86,
11.00,
11.06,
15.33,
12.77,
8.69,
11.06,
8.94,
8.26,
10.55,
3.81,
11.65,
6.48,
6.89,
13.19,
12.10,
10.21,
11.70,
12.56,
9.53,
9.63,
8.35,
10.10,
7.76,
9.00,
7.83,
8.06,
12.22,
11.94,
16.38,
13.41,
15.83,
13.44,
13.36,
14.71,
3.97,
7.83,
8.33,
8.79,
9.50,
13.62,
13.53,
0.00,
5.84,
6.78,
8.72,
13.85,
9.39,
14.35,
9.00,
10.17,
9.42,
10.40,
8.78,
11.33,
12.88,
7.40,
7.17,
5.67,
13.33,
10.50,
11.41,
5.63,
3.95,
6.68,
11.27,
10.11,
12.25,
8.92,
18.52,
6.11,
9.66,
7.44,
16.40,
11.31,
8.95,
9.56,
6.44,
6.22,
8.47,
11.80,
13.27,
7.53,
8.90,
8.58,
8.79,
0.00,
6.83,
11.03,
10.60,
7.76,
10.88,
7.26,
14.57,
8.85,
4.22,
8.89,
12.22,
3.89,
12.50,
10.62,
9.26,
9.00,
6.79,
9.65,
16.17,
8.23,
12.65,
15.29,
9.47,
5.32,
4.74,
8.61,
8.58,
5.17,
9.28,
9.21,
10.88,
7.85,
12.00,
8.16,
11.42,
10.68,
11.33,
11.05,
8.22,
5.39,
7.90,
8.78,
11.29,
11.11,
10.47,
10.78,
4.79,
11.69,
8.63,
13.27,
7.83,
12.76,
9.61,
9.39,
12.35,
7.65,
8.42,
11.62,
10.44,
5.55,
7.11,
11.22,
11.93,
15.38,
4.38,
12.44,
8.44,
10.25,
5.61,
8.58,
8.65,
4.83,
7.47,
8.37,
4.89,
13.57,
12.33,
14.05,
13.00,
13.05,
9.21,
9.80,
9.61,
6.79,
11.10,
9.21,
12.36,
3.79,
7.16,
7.05,
8.50,
9.71,
10.12,
11.53,
13.06,
11.05,
4.78,
9.67,
10.05,
12.13,
8.32,
4.44,
9.89,
9.78,
9.33,
10.18,
12.30,
13.35,
12.80,
7.65,
6.53,
10.05,
13.62,
11.26,
8.74,
10.25,
14.38,
13.89,
10.32,
8.94,
6.26,
8.79,
14.62,
15.00,
6.56,
6.00,
10.75,
12.83,
9.58,
12.27,
15.25,
4.50,
11.71,
12.24,
13.33,
10.22,
4.95,
10.65,
9.94,
13.63,
13.06,
11.62,
9.75,
10.95,
9.48,
11.31,
9.78,
7.63,
6.47,
9.16,
12.44,
11.00,
11.92,
12.27,
7.65,
13.25,
10.88,
4.58,
8.56,
8.86,
8.15,
8.30,
8.26,
13.60,
8.95,
7.89,
8.00,
8.68,
5.89,
13.60,
7.21,
6.50,
6.85,
4.93,
7.72,
9.52,
9.75,
9.00,
9.37,
10.42,
9.23,
8.89,
12.27,
12.78,
3.53,
13.59,
8.06,
7.83,
12.07,
7.84,
11.67,
4.42,
12.79,
10.22,
10.21,
10.95,
11.13,
10.89,
5.86,
11.11,
9.94,
10.38,
10.11,
6.78,
6.65,
9.27,
9.06,
6.67,
14.38,
11.17,
10.50,
11.73,
10.56,
8.20,
10.40,
6.06,
12.39,
8.68,
10.06,
9.74,
8.00,
7.78,
8.84,
11.28,
12.10,
10.05,
5.32,
12.11,
10.61,
12.28,
9.42,
3.89,
12.35,
9.93,
14.93,
6.78,
6.65,
7.05,
15.58,
9.00,
6.61,
6.52,
9.39,
8.33,
10.84,
7.00,
9.84,
7.79,
13.29,
10.87,
7.08,
11.26,
9.33,
9.78,
10.56,
10.28,
5.28,
7.52,
12.93,
13.06,
10.28,
10.33,
7.00,
12.00,
6.11,
10.17,
10.78,
8.95,
10.46,
7.06,
8.99,
7.59,
8.47,
10.71,
9.35,
7.84,
12.64,
5.00,
7.48,
6.89,
10.25,
10.22,
12.71,
6.47,
8.18,
8.69,
7.21,
7.26,
7.58,
5.94,
8.00,
12.88,
7.32,
15.19,
4.89,
9.12,
7.32,
11.00,
4.77,
4.22,
8.79,
9.75,
8.74,
8.59,
12.92,
13.40,
13.36,
13.90,
14.62,
16.50,
15.62,
13.86,
13.56,
7.11,
12.11,
10.06,
11.53,
11.06,
12.11,
7.78,
20.00,
8.34,
13.15,
14.00,
7.33,
7.83,
9.29,
3.94,
11.17,
15.00,
11.19,
13.48,
6.80,
13.33,
14.00,
6.61,
12.54,
11.44,
5.30,
8.33,
12.80,
6.32,
5.24,
10.39,
11.32,
10.06,
11.65,
9.53,
3.83,
7.88,
3.20,
10.05,
6.56,
11.33,
8.61,
7.67,
12.68,
18.50,
13.29,
19.00,
2.31,
14.25,
12.60,
4.58,
11.06,
6.96,
10.94,
10.53,
8.00,
8.16,
13.17,
15.10,
3.72,
7.94,
12.15,
5.89,
12.62,
8.86,
19.00,
11.50,
4.35,
4.61,
13.78,
9.06,
6.11,
17.80,
4.10,
4.51,
4.95,
6.89,
8.79,
12.88,
5.62,
13.33,
4.17,
9.97,
10.31,
3.42,
13.33,
18.60,
16.25,
10.00,
18.33,
10.56,
6.56,
7.83,
13.12,
12.29,
12.58,
12.76,
13.76,
4.79,
14.00,
13.30,
12.93,
13.69,
5.31,
10.56,
9.33,
14.33,
14.25,
6.06,
9.35,
8.67,
9.39,
12.47,
17.17,
9.82,
10.00,
5.30,
5.00,
6.80,
7.33,
12.00,
15.06,
10.89,
17.50,
12.07,
15.67,
9.84,
15.28,
7.68,
8.79,
11.84,
3.75,
12.45,
11.06,
10.25,
8.57,
12.43,
6.79,
16.10,
15.38
    ]
}